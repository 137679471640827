import React, {Component} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import * as Cookies from 'js-cookie';

import './panel.css';
import Header from "./Header";
import Sidebar from "./Sidebar";
import MainMap from "./MainMap";
import TrackersList from "./TrackersList";
import Services from "./Services";
import GeoAreasList from "./GeoAreasList";
import AddArea from "./AddArea";
import StopReports from "./StopReports";
import MovingReports from "./MovingReports";
import FuelReports from "./FuelReports";
import SpeedReports from "./SpeedReports";
import Admin from "../admin/Admin";
import UsersList from "../admin/UsersList";
import RolesList from "../admin/RolesList";
import TracerManager from "../admin/TracerManager";
import AboutUs from "./AboutUs";

import ServicesGroup from "../admin/ServicesGroup";
import Loading from "../Loading";
import UpdateArea from "./UpdateArea";
import Transactions from "./Transactions";
import AdminTransactions from "../admin/AdminTransactions";

const title = require('./title.json');


class Dashboard extends Component {

    constructor(){
        super();

        this.state={
            headerTitle: 'خانه',
            isSidebarOpen: true,
        };

        this.changeHeaderTitle = this.changeHeaderTitle.bind(this);
        this.handelSidebar = this.handelSidebar.bind(this);
        this.closeSidebar = this.closeSidebar.bind(this);

    }

    changeHeaderTitle(title){
        this.setState({
            headerTitle: title
        });
    }
    handelSidebar(){
        this.setState({isSidebarOpen: !this.state.isSidebarOpen});
    }

    closeSidebar(){
        this.setState({isSidebarOpen: true});
    }


    componentWillMount(){
        this.setState({headerTitle:title[this.props.location.pathname.substr(1)]});
        let url = window.location.href;
        let access_token;
        if(url.match(/\#(?:token)\=([\S\s]*?)$/) != null){
            access_token = url.match(/\#(?:token)\=([\S\s]*?)$/)[1];
            window.sessionStorage.setItem("token",access_token);
            window.sessionStorage.setItem("role",'client');
            window.location='/';
        }

    }

    render() {
        return (
            <div className='Panel col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <Loading
                    loading={false}
                />
                {window.localStorage.getItem('isLogedin')=== null || window.localStorage.getItem('isLogedin') === 'false'  ? <Redirect to='/login'/> : ''}
                <div className={this.state.isSidebarOpen ? 'head col-lg-10 col-md-10 col-sm-12 col-xs-12' : 'head col-lg-12 col-md-12 col-sm-12 col-xs-12'}>
                    <Header path={this.state.headerTitle} handelSidebar={this.handelSidebar}/>
                        <Switch>
                            <Route exact path={"/"} name={'map'} component={(window.sessionStorage.getItem("role")  || window.localStorage.getItem('role')) === 'client' ? MainMap : Admin}  />
                            <Route exact path={'/trackerslist'} name={'trackerslist'} component={TrackersList} />
                            <Route exact path={'/services'} name={'services'} component={Services} />
                            <Route exact path={'/areaslist'} name={'areaslist'} component={GeoAreasList} />
                            <Route exact path={'/addarea'} name={'addarea'} component={AddArea} />
                            <Route exact path={'/updatearea/:tracerId/:geoId'} name={'updatearea'} component={UpdateArea} />
                            <Route exact path={'/stopreports'} name={'stopreports'} component={StopReports} />
                            <Route exact path={'/movingreports'} name={'movingreports'} component={MovingReports} />
                            <Route exact path={'/fuelreports'} name={'fuelreports'} component={FuelReports} />
                            <Route exact path={'/speedreports'} name={'speedreports'} component={SpeedReports} />
                            <Route exact path={'/transactions'} name={'transactions'} component={Transactions} />
                            <Route exact path={'/admintransactions'} name={'admintransactions'} component={AdminTransactions} />
                            <Route exact path={'/userslist'} name={'userslist'} component={UsersList} />
                            <Route exact path={'/roleslist'} name={'roleslist'} component={RolesList} />
                            <Route exact path={'/tracermanager'} name={'tracermanager'} component={TracerManager} />
                            <Route exact path={'/servicegroups'} name={'servicegroups'} component={ServicesGroup} />
                            <Route exact path={'/aboutus'} name={'aboutus'} component={AboutUs} />
                        </Switch>
                </div>
                <div className={this.state.isSidebarOpen ? 'side col-lg-2 col-md-2 hidden-sm hidden-xs ' : 'side hidden col-sm-4 col-xs-8'}>
                    <Sidebar path={this.state.headerTitle}  changeTitle={this.changeHeaderTitle} closeSidebar={this.closeSidebar} />
                </div>
            </div>
        )
    }
}

export default Dashboard;