import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import { withAlert } from "react-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import * as base64 from "base-64";
import moment from "moment-jalaali";
import Avatar from "react-avatar-edit";
import { config } from "../../config";
import { messaging } from "../../init-fcm";
import { DatetimePickerTrigger } from "imrc-datetime-picker";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import "rc-time-picker/assets/index.css";

import "./panel.css";
import Loading from "../Loading";

Modal.setAppElement(document.body);

moment.loadPersian({
  dialect: "persian-modern",
  usePersianDigits: true,
});

const dateFormat = "YYYY-MM-DD";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAvatar: false,
      showNoticDiv: false,
      signout: false,
      isProfileOpen: false,
      isEditProfileOpen: false,
      isChangePasswordOpen: false,
      name: "",
      mCode: "",
      phone: "",
      photo: null,
      preview: null,
      src: "",
      pathName: "",
      notificCurrPage: 1,
      notificData: [],
      loadItem: false,
      loading: false,
      loading1: false,
      notOpenCount: 0,
      from: moment(),
      to: moment(),
      data: [],
      isFiltered: false,
      isFromChanged: false,
      isToChanged: false,
    };

    this.handleSignout = this.handleSignout.bind(this);
    this.handleAvatar = this.handleAvatar.bind(this);
    this.closeProfile = this.closeProfile.bind(this);
    this.openProfile = this.openProfile.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.closeEditProfile = this.closeEditProfile.bind(this);
    this.changePass = this.changePass.bind(this);
    this.closeChangePass = this.closeChangePass.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getProfileInfo = this.getProfileInfo.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeProfileInfo = this.changeProfileInfo.bind(this);
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.getNotificationsPerPage = this.getNotificationsPerPage.bind(this);
    this.loadMoreNotifics = this.loadMoreNotifics.bind(this);
    this.readOneMessage = this.readOneMessage.bind(this);
    this.readAllMessages = this.readAllMessages.bind(this);
    this.saveNewPass = this.saveNewPass.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
  }

  handleFromDate(moment) {
    this.setState({ from: moment, isFromChanged: true });
  }
  handleToDate(moment) {
    this.setState({ to: moment, isToChanged: true });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleAvatar() {
    this.setState({ showAvatar: !this.state.showAvatar, showNoticDiv: false });

    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/profile",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            name: resp.data.data.name,
            mCode: resp.data.data.mcode,
            phone: resp.data.data.mobile,
            photo: resp.data.data.photo,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSignout() {
    localStorage.setItem("isLogedin", false);
    localStorage.setItem("role", "");
    localStorage.setItem("SelectedTrackers", "");
    this.setState({ signout: true, showAvatar: false, photo: null });
  }
  closeProfile() {
    this.setState({ isProfileOpen: false });
  }
  openProfile() {
    this.setState({ isProfileOpen: true, showAvatar: false });
  }
  editProfile() {
    this.setState({ isProfileOpen: false, isEditProfileOpen: true });
  }
  closeEditProfile() {
    this.setState({ isEditProfileOpen: false });
  }
  changePass() {
    this.setState({ isProfileOpen: false, isChangePasswordOpen: true });
  }
  closeChangePass() {
    this.setState({ isChangePasswordOpen: false });
  }
  goBack() {
    this.setState({
      isProfileOpen: true,
      isEditProfileOpen: false,
      isChangePasswordOpen: false,
    });
  }
  getProfileInfo() {}
  onClose() {
    this.setState({ preview: null });
  }
  onCrop(preview) {
    this.setState({ photo: preview.match(/\;(?:base64)\,([\S\s]*?)$/)[1] });
  }
  changeProfileInfo() {
    this.setState({ loading1: true });

    let data = {
      name: this.state.name,
      mCode: this.state.mCode,
      mobile: this.state.phone,
      email: "",
    };

    let req = {
      method: "put",
      url: config.baseUrl + "api/v1/profile",
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            isProfileOpen: true,
            isEditProfileOpen: false,
            isChangePasswordOpen: false,
            loading1: false,
          });
          this.props.alert.success("تغییرات با موفقیت ثبت شد.");
        }
      })
      .catch((err) => {
        this.props.alert.error("مشکلی پیش آمده.");
        this.setState({ loading1: false });
      });

    let pic = this.state.photo;
    // if(this.state.src === ''){
    //     pic = this.state.photo
    // }else {
    //     pic = this.state.src
    // };

    let updateProfilePic = {
      method: "put",
      url: config.baseUrl + "api/v1/profile/photo",
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: pic,
    };

    axios(updateProfilePic)
      .then((resp) => {
        if (resp.data.status === 1) {
        }
      })
      .catch((err) => {});
  }

  addFilter() {
    console.log("cvbnm");
    this.setState({ isFiltered: true, loading: true });
    this.getNotificationsPerPage(1, true);
  }

  removeFilter() {
    this.setState({ isFiltered: false, loading: true });
    this.getNotificationsPerPage(1, false);
  }

  getNotificationsPerPage(page, filter) {
    this.setState({ notificCurrPage: page });

    let type, tracer, from, to;

    if (filter) {
      type = document.getElementById("noticType").value;
      tracer = document.getElementById("noticTracer").value;
      if (this.state.isFromChanged) {
        from =
          this.state.from.utc().locale("en").format(dateFormat) + " 00:00:00";
      } else {
        from = "";
      }
      if (this.state.isToChanged) {
        to = this.state.to.utc().locale("en").format(dateFormat) + " 23:59:59";
      } else {
        to = "";
      }
    } else {
      type = "";
      tracer = "";
      from = "";
      to = "";
    }

    let array;
    let req = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/notifications?page=" +
        page +
        "&type=" +
        type +
        "&tracer_id=" +
        tracer +
        "&from_date=" +
        from +
        "&to_date=" +
        to,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          if (page > 1) {
            array = this.state.notificData.concat(resp.data.data);
            this.setState({
              notificData: array,
              loading: false,
              notOpenCount: parseInt(resp.data.notOpenedCount),
            });
          } else {
            this.setState({
              notificData: resp.data.data,
              loading: false,
              notOpenCount: parseInt(resp.data.notOpenedCount),
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  loadMoreNotifics(e) {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      this.getNotificationsPerPage(
        this.state.notificCurrPage + 1,
        this.state.isFiltered
      );
      this.setState({ loading: true });
    }
  }
  readOneMessage(id) {
    let notifics = this.state.notificData;
    for (let item of notifics) {
      if (item.id === id) {
        item.is_opened = 1;
      }
    }
    this.setState({ notificData: notifics });
    let req = {
      method: "put",
      url: config.baseUrl + "api/v1/clients/notifications/" + id + "/open",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.props.alert.success("این پیام خوانده شد.");
          this.setState({ notOpenCount: this.state.notOpenCount - 1 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  readAllMessages() {
    let notifics = this.state.notificData;
    for (let item of notifics) {
      item.is_opened = 1;
    }
    this.setState({ notificData: notifics });
    let req = {
      method: "put",
      url: config.baseUrl + "api/v1/clients/notifications/open",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.props.alert.success("تمامی پیام ها خوانده شد.");
          this.setState({ notOpenCount: 0 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  saveNewPass() {
    let currPass = document.getElementById("currentPass").value;
    let newPass = document.getElementById("newPass").value;

    if (
      document.getElementById("newPass").value !==
      document.getElementById("repeatNewPass").value
    ) {
      this.props.alert.error("عدم تطابق رمز عبور با تکرار رمز عبور!");
    } else if (currPass === "" || newPass === "") {
      this.props.alert.error("فیلد ها را به دقت پر کنید!");
    } else {
      this.setState({ loading1: true });

      let data = {
        current_password: currPass,
        password: newPass,
      };

      console.log(data);
      let req = {
        method: "post",
        url: config.baseUrl + "api/v1/profile/password",
        headers: {
          Authorization: "Bearer " + config.token,
        },
        data: data,
      };

      axios(req)
        .then((resp) => {
          if (resp.data.status === 1) {
            this.setState({ loading1: false });
            this.props.alert.success("رمز عبور با موفقیت تغییر کرد.");
            this.goBack();
          } else {
            this.setState({ loading1: false });
            this.props.alert.error(resp.data.errors[0].message);
          }
        })
        .catch((err) => {
          this.setState({ loading1: false });
          this.props.alert.error(
            "مشکلی در ارتباط بوجود آمده، دو باره امتحان کنید."
          );
        });
    }
  }

  getTrackers() {
    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/clients/tracers",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            data: resp.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    if (localStorage.getItem("isLogedin") === "false") {
      this.setState({ signout: true });
    }
    this.setState({ showAvatar: false });

    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/profile",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            name: resp.data.data.name,
            mCode: resp.data.data.mcode,
            phone: resp.data.data.mobile,
            photo: resp.data.data.photo,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.getNotificationsPerPage(1, false);

    this.getTrackers();
  }

  render() {
    const shortcuts = {
      امروز: moment(),
      دیروز: moment().subtract(1, "days"),
    };

    return (
      <div className="header col-lg-12 col-md-12">
        <Loading loading={this.state.loading} />
        {this.state.signout ? <Redirect to="/login" /> : ""}
        <div className="left col-lg-4 col-md-4 col-sm-6 col-xs-6">
          <button
            style={{
              display:
                (window.sessionStorage.getItem("role") ||
                  window.localStorage.getItem("role")) === "client"
                  ? "inline-block"
                  : "none",
            }}
            className="notic-btn"
            onClick={() => {
              this.setState({
                showNoticDiv: !this.state.showNoticDiv,
                showAvatar: false,
              });
            }}
          >
            <i className="icon-notification" />
            {this.state.notOpenCount !== 0 ? (
              <span>{this.state.notOpenCount}</span>
            ) : (
              ""
            )}
          </button>
          <div
            className="notic-div"
            style={{ display: this.state.showNoticDiv ? "block" : "none" }}
          >
            <button
              className="closePolyline"
              style={{ top: "7px" }}
              onClick={() => {
                this.setState({ showNoticDiv: false });
              }}
            >
              بستن
            </button>
            <div className="notic-header">
              <p>پیام های دریافت شده</p>
              <button onClick={this.readAllMessages}>خواندن همه</button>
            </div>

            <div className="notic-content" onScroll={this.loadMoreNotifics}>
              <div className="notic-filter">
                <p>فیلتر پیام های دریافت شده</p>
                <div className="time-div">
                  <div className="right">
                    <label>نوع پیام</label>

                    <select className="date-input" id="noticType">
                      <option selected value="">
                        همه
                      </option>
                      <option value="2">هشدار</option>
                      <option value="1">محدوده</option>
                    </select>
                  </div>
                  <div className="left">
                    <label>ردیاب</label>
                    <select className="date-input" id="noticTracer">
                      <option selected value="">
                        همه
                      </option>
                      {this.state.data.map((key) => (
                        <option value={key.id}>{key.title}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="time-div">
                  <div className="right">
                    <label>از تاریخ</label>
                    <DatetimePickerTrigger
                      shortcuts={shortcuts}
                      moment={this.state.from}
                      onChange={this.handleFromDate}
                      showTimePicker={false}
                      position="bottom"
                      lang="fa"
                      isSolar={true}
                    >
                      <input
                        className="date-input"
                        type="text"
                        value={
                          this.state.isFromChanged
                            ? this.state.from.format("jYYYY/jMM/jDD")
                            : ""
                        }
                        readOnly
                      />
                    </DatetimePickerTrigger>
                  </div>
                  <div className="left">
                    <label>تا تاریخ</label>
                    <DatetimePickerTrigger
                      shortcuts={shortcuts}
                      moment={this.state.to}
                      onChange={this.handleToDate}
                      showTimePicker={false}
                      position="bottom"
                      lang="fa"
                      isSolar={true}
                    >
                      <input
                        className="date-input"
                        type="text"
                        value={
                          this.state.isToChanged
                            ? this.state.to.format("jYYYY/jMM/jDD")
                            : ""
                        }
                        readOnly
                      />
                    </DatetimePickerTrigger>
                  </div>
                </div>

                <div className="time-div filter-btns">
                  <div className="right">
                    <button
                      className="btn blueBtn"
                      onClick={() => this.addFilter()}
                    >
                      اعمال فیلتر
                    </button>
                  </div>
                  <div className="right">
                    <button
                      className="btn pinkBtn"
                      onClick={() => this.removeFilter()}
                    >
                      حذف فیلتر
                    </button>
                  </div>
                </div>
              </div>

              {this.state.notificData.map((key) => (
                <div className="message-div">
                  <label className={key.is_opened === 0 ? "notSeen" : "seen"}>
                    خوانده {key.is_opened === 0 ? "نشده" : "شده"}
                  </label>
                  <div>
                    <label>نوع پیام: </label>
                    {key.type === 1 ? (
                      <p>ورود به محدوده جغرافیایی</p>
                    ) : (
                      <p>هشدار</p>
                    )}
                  </div>
                  <div>
                    <label>عنوان ردیاب: </label>
                    <p>{key.tracer.title}</p>
                  </div>
                  <div>
                    <label>تاریخ و ساعت دریافت پیام: </label>
                    <p>
                      {key.created_at !== null
                        ? moment
                            .utc(key.created_at)
                            .local()
                            .format("jYYYY/jMM/jDD | HH:mm:ss")
                        : ""}
                    </p>
                  </div>
                  <div>
                    <label>متن پیام: </label>
                    <p>{key.description}</p>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <button
                      onClick={() => {
                        this.readOneMessage(key.id);
                      }}
                    >
                      خواندن پیام
                    </button>
                    <a
                      href={`https://maps.google.com/?q=${key.lat},${key.lng}`}
                      target="blank"
                    >
                      <button style={{ float: "left" }}>نمایش روی نقشه</button>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button onClick={this.handleAvatar}>
            <img
              src={
                this.state.photo === null || this.state.photo === "<base64>"
                  ? require("../../image/Avatar.jpg")
                  : "data:image/;base64," + this.state.photo
              }
            />
          </button>
          <div
            className="avatar"
            style={{ display: this.state.showAvatar ? "block" : "none" }}
          >
            <button onClick={this.openProfile}>
              <i className="icon-avatar" />
              <p>پروفایل</p>
            </button>
            <Link to="/login">
              <button
                onClick={() => {
                  this.handleSignout();
                }}
              >
                <i className="icon-signout" />
                <p style={{ color: "#FF006F" }}>خروج</p>
              </button>
            </Link>
          </div>
        </div>
        <div
          className="right col-lg-6 col-md-6 col-sm-6 col-xs-6"
          style={{ float: "right" }}
        >
          <button onClick={this.props.handelSidebar}>
            <img src={require("../../image/menu.png")} />
          </button>
          <p>{this.props.path}</p>
        </div>
        <Modal
          isOpen={this.state.isProfileOpen}
          onRequestClose={this.closeProfile}
          onAfterOpen={this.getProfileInfo}
          style={{
            content: { top: "calc(100vh / 10)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>پروفایل کاربری</p>
          </div>
          <div className="profilePic">
            <img
              src={
                this.state.photo === null || this.state.photo === "<base64>"
                  ? require("../../image/Avatar.jpg")
                  : "data:image/;base64," + this.state.photo
              }
            />
          </div>
          <div className="profile input-div" style={{ marginBottom: "10px" }}>
            <div className="input-group">
              <p>نام و نام خانوادگی</p>
              <input
                className="input"
                type="text"
                readOnly
                value={this.state.name}
              />
            </div>
          </div>
          <div className="profile input-div" style={{ marginBottom: "10px" }}>
            <div className="input-group">
              <p>کد ملی</p>
              <input
                className="num input"
                type="text"
                readOnly
                value={this.state.mCode}
              />
            </div>
          </div>
          <div className="profile input-div" style={{ marginBottom: "10px" }}>
            <div className="input-group">
              <p>شماره موبایل</p>
              <input
                className="num input"
                type="text"
                readOnly
                value={this.state.phone}
              />
            </div>
          </div>
          <div className="button-div blueBtn">
            <button onClick={this.editProfile}>
              <h5>ویرایش پروفایل</h5>
            </button>
          </div>
          <div className="button-div">
            <button onClick={this.changePass}>
              <h5>تغییر رمز عبور</h5>
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isEditProfileOpen}
          onRequestClose={this.closeEditProfile}
          style={{
            content: { top: "calc(100vh / 7)", left: "40%", right: "40%" },
          }}
        >
          <Loading loading={this.state.loading1} />
          <div className="title">
            <p>تغییر پروفایل کاربری</p>
          </div>
          <div className="profilePic">
            <Avatar
              width={150}
              height={150}
              onCrop={this.onCrop}
              onClose={this.onClose}
              src={
                this.state.photo === null || this.state.photo === "<base64>"
                  ? require("../../image/Avatar.jpg")
                  : "data:image/;base64," + this.state.photo
              }
              label={"آپلود تصویر"}
              labelStyle={{
                fontSize: "10px",
                display: "inline-block",
                width: "100%",
                borderRadius: "50%",
                cursor: "pointer",
                color: "#FFF",
              }}
              borderStyle={{
                display: "inline-block",
                borderRadius: "50%",
                backgroundColor: "#0000009c",
              }}
              cropRadius={50}
            />
          </div>
          <div className="input-div ">
            <h5>نام و نام خانوادگی</h5>
            <div className="input-group">
              <input
                name="name"
                className="input "
                onChange={this.handleChange}
                value={this.state.name}
              />
            </div>
          </div>
          <div className="input-div ">
            <h5>شماره موبایل</h5>
            <div className="input-group">
              <input
                name="phone"
                className="input num"
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </div>
          </div>
          <button className="blueBtn btn" onClick={this.changeProfileInfo}>
            ‌ذخیره تغییرات
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "10px", padding: "15px 25px" }}
            onClick={this.goBack}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isChangePasswordOpen}
          onRequestClose={this.closeChangePass}
          style={{
            content: { top: "calc(100vh / 7)", left: "40%", right: "40%" },
          }}
        >
          <Loading loading={this.state.loading1} />
          <div className="title">
            <p>تغییر رمز عبور</p>
          </div>
          <p
            style={{
              color: "#B5B5B5",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            رمز عبور خود را وارد کنید
          </p>
          <div className="input-div ">
            <h5>رمز عبور فعلی</h5>
            <div className="input-group">
              <input
                id="currentPass"
                className="input "
                type="password"
                placeholder="*****"
              />
            </div>
          </div>
          <div className="input-div ">
            <h5>رمز عبور جدید</h5>
            <div className="input-group">
              <input
                id="newPass"
                className="input "
                type="password"
                placeholder="*****"
              />
            </div>
          </div>
          <div className="input-div ">
            <h5>تکرار رمز عبور جدید</h5>
            <div className="input-group">
              <input
                id="repeatNewPass"
                className="input "
                type="password"
                placeholder="*****"
              />
            </div>
          </div>

          <button className="blueBtn btn" onClick={this.saveNewPass}>
            ‌ذخیره تغییرات
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "10px", padding: "15px 25px" }}
            onClick={this.goBack}
          >
            انصراف
          </button>
        </Modal>
      </div>
    );
  }
}
export default withAlert(Header);
