import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';
import Login from "./components/login/Login";
import Register from "./components/Register";
import Forgetpass from "./components/Forgetpass";
import Dashboard from "./components/panel/Dashboard";
import Resetpass from "./components/Resetpass";

class App extends Component {

    render() {
        return (
                <Router>
                    <div className="App">
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/Register/" component={Register}/>
                        <Route exact path="/ForgetPass/" component={Forgetpass}/>
                        <Route exact path="/Resetpass/" component={Resetpass}/>
                        <Route path="/" component={Dashboard}/>
                    </div>
                </Router>

        );
    }
}

export default App;
