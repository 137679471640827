import React, {Component} from 'react';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import {config} from '../../config';
class Admin extends Component{

    constructor(props){
        super(props);

        this.state={
            data:{
                users: {
                    total: "",
                    have_tracer: "",
                    doesnt_have_tracer: ""
                },
                tracers: {
                    total: "",
                    activated: "",
                    not_activated: ""
                }
            }
        }
    }

    componentWillMount(){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/dashboard',
            headers: {
                Authorization: "Bearer "+config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({ data: resp.data.data});
            }
        }).catch((err) => {
            if({err}.err.response.status === 401 ||{err}.err.response.status === 403){
                this.props.history.push('/login');
                localStorage.setItem('isLogedin' , false);
                localStorage.setItem('role' , '');
            }

        });

    }

    render(){
        return(
            <div>
                <div className='content col-lg-12 col-md-12'>
                    <div className='content-body'>
                        <div className='admin-info-div b-user col-lg-3 col-md-3'>
                            <h1>{this.state.data.users.total}</h1>
                            <p>تعداد کل کاربران</p>
                        </div>
                        <div className='admin-info-div g-user col-lg-3 col-md-3'>
                            <h1>{this.state.data.users.have_tracer}</h1>
                            <p>کاربران دارای ردیاب</p>
                        </div>
                        <div className='admin-info-div p-user col-lg-3 col-md-3'>
                            <h1>{this.state.data.users.doesnt_have_tracer}</h1>
                            <p>کاربران بدون ردیاب</p>
                        </div>
                        <div className='admin-info-div b-radar col-lg-3 col-md-3'>
                            <h1>{this.state.data.tracers.total}</h1>
                            <p>تعداد کل ردیاب ها</p>
                        </div>
                        <div className='admin-info-div g-radar col-lg-3 col-md-3'>
                            <h1>{this.state.data.tracers.activated}</h1>
                            <p>ردیاب های فعال</p>
                        </div>
                        <div className='admin-info-div p-radar pre-scrollable col-lg-3 col-md-3'>
                            <h1>{this.state.data.tracers.not_activated}</h1>
                            <p>ردیاب های غیر فعال</p>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default Admin;