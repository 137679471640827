import React, { Component } from "react";
import Modal from "react-modal";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { withAlert } from "react-alert";
import moment from "moment-jalaali";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import { DatetimePickerTrigger } from "imrc-datetime-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { config } from "../../config";
import Loading from "../Loading";
import * as io from "socket.io-client";
import { messaging } from "../../init-fcm";

Modal.setAppElement(document.body);

moment.loadPersian({
  dialect: "persian-modern",
  usePersianDigits: true,
});

let socket = false;
let map;
let markers = [];
let marker;
let infowindow = new window.google.maps.InfoWindow({
  maxWidth: 200,
  content: "",
});
let stopInfowindow = new window.google.maps.InfoWindow({
  maxWidth: 200,
  content: "",
});
let locInfowindow = new window.google.maps.InfoWindow({
  maxWidth: 200,
  content: "",
});
let contentString;
let array = [];
let arr = [];
let bounds = new window.google.maps.LatLngBounds();
let loc;

let TimeOut;

const dateFormat = "YYYY-MM-DD HH:mm:00";

let locReportPolyline;
let locReportMarker;
let locItem = 0;
let timeOutAnimation;
let moveTimeOut;
let stopPoint;
let startPoint;
let destinationPoint;
let stopPoints = [];
let StopPointContentString;
let locPointContentString;
let startPointContentString;
let destinationPointContentString;
let arrayNewLinePath = [];
let newLine;
let locPoint;
let locPoints = [];

class MainMap extends Component {
  constructor(props) {
    super(props);

    const defaultMoment = moment();

    this.state = {
      data: [],
      socket: false,
      signout: false,
      trackerListIsOpen: false,
      trackerList: [],
      trackerChecked: [],
      mapTypesListIsOpen: false,
      mapType: "roadmap",
      isInfoBoxOpen: 0,
      isTimeSelectOpen: false,
      isAnimationSpeedOpen: false,
      from: defaultMoment,
      to: defaultMoment,
      value: 3,
      trackerData: {},
      locReportToken: "",
      showPlayIcon: false,
      locReport: [],
      showStopDiv: false,
      currentLocReport: [],
      isPause: false,
      loading: true,
      isExpiredTracersOpen: false,
      selectedTime: 0,
      socketFlag: 2,
      expiredTracers: [],
      isStopPointOpen: false,
      isStartPointOpen: false,
      isDestinationPointOpen: false,
      locReportOnMap: false,
      showPoints: false,
      isLocPointOpen: false,
      timeOut: 0,
    };

    this.socket = io("https://socket.irgogps.com");

    this.onScriptLoad = this.onScriptLoad.bind(this);
    this.closeTrackerList = this.closeTrackerList.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.closeMapTypsList = this.closeMapTypsList.bind(this);
    this.openInfo = this.openInfo.bind(this);
    this.closeTimeSelect = this.closeTimeSelect.bind(this);
    this.closeAnimationSpeed = this.closeAnimationSpeed.bind(this);
    this.handleRangeSpeed = this.handleRangeSpeed.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
    this.reloadMap = this.reloadMap.bind(this);
    this.openSelectSpeed = this.openSelectSpeed.bind(this);
    this.getLocationReport = this.getLocationReport.bind(this);
    this.checkLocationReport = this.checkLocationReport.bind(this);
    this.startAnimate = this.startAnimate.bind(this);
    this.stopAnimate = this.stopAnimate.bind(this);
    this.removepolyline = this.removepolyline.bind(this);
    this.generateMarkerIconUrl = this.generateMarkerIconUrl.bind(this);
    this.roundRotationNumber = this.roundRotationNumber.bind(this);
    this.moveMarker = this.moveMarker.bind(this);
    this.socketUpdateData = this.socketUpdateData.bind(this);
    this.authSocket = this.authSocket.bind(this);
    this.openStopPointInfo = this.openStopPointInfo.bind(this);
    this.openStartPointInfo = this.openStartPointInfo.bind(this);
    this.openDestinationPointInfo = this.openDestinationPointInfo.bind(this);
    this.getTrackers = this.getTrackers.bind(this);
  }

  getTrackers() {
    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/clients/tracers",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          arr = [];
          array = [];
          markers = [];

          let data = resp.data.data;
          let expireData = [];
          for (let item of data) {
            if (!item.last_lat || !item.last_lng) {
              item.last_lat = 0;
              item.last_lng = 0;
            }
          }
          this.setState({
            data: data,
          });
          for (let i in data) {
            loc = new window.google.maps.LatLng(
              data[i].last_lat,
              data[i].last_lng
            );
            bounds.extend(loc);

            marker = new window.google.maps.Marker({
              position: {
                lat: parseFloat(data[i].last_lat),
                lng: parseFloat(data[i].last_lng),
              },
              map: map,
              title: data[i].title,
              markerId: data[i].id,
              isExpired: data[i].is_expired,
              icon: {
                url: this.generateMarkerIconUrl(
                  data[i].usage.code,
                  data[i].last_course,
                  data[i].data_color,
                  data[i].is_expired
                ),
                scaledSize: new window.google.maps.Size(40, 40),
                anchor: new window.google.maps.Point(20, 20),
              },
            });

            if (data[i].last_lat === 0 || data[i].last_lng === 0) {
              marker.setVisible(false);
            }
            if (data[i].is_expired === 0) {
              window.google.maps.event.addListener(marker, "click", () => {
                this.openInfo(data[i], i);
              });
            }
            if (moment(data[i].expires_at).diff(moment(), "days") < 5) {
              expireData.push(data[i]);
            }
            map.fitBounds(bounds, 100);
            map.panToBounds(bounds);

            arr.push(data[i].id);
            array.push([data[i].id, data[i].title]);
            markers.push(marker);
          }

          this.setState({
            trackerList: array,
            trackerChecked: arr,
            expiredTracers: expireData,
            loading: false,
          });
          if (expireData.length > 0) {
            this.setState({ isExpiredTracersOpen: true });
          }

          let selectetTrackers =
            window.localStorage.getItem("SelectedTrackers");

          if (selectetTrackers != null && selectetTrackers !== "") {
            selectetTrackers = selectetTrackers
              .split(",")
              .map((x) => Number(x));

            for (let item of arr) {
              if (!selectetTrackers.includes(item)) {
                setTimeout(() => {
                  this.handleCheckbox(item);
                }, 500);
              }
            }
          }
        }
      })
      .catch((err) => {
        if (
          { err }.err.response.status === 401 ||
          err.response.status === 403
        ) {
          localStorage.setItem("isLogedin", false);
          localStorage.setItem("role", "");
          this.setState({ signout: true });
          this.socket.disconnect();
        }
      });
  }

  onScriptLoad(mapType) {
    this.setState({ data: [], socketFlag: 2 });

    if (socket === true) {
      this.socket.disconnect();
      this.authSocket();
      this.socketUpdateData();
    }

    array = [];

    let access_token =
      "pk.eyJ1Ijoic29iaW1vciIsImEiOiJjajZuYmlicXowNmtmMzNqbnVmaHBkamFvIn0.nxr--YcWiqkbXzMQePit3w";

    map = new window.google.maps.Map(document.getElementById("myMap"), {
      center: { lat: 38.0843038, lng: 46.270212 },
      zoom: 5,
      disableDefaultUI: true,
      zoomControl: false,
      mapTypeControl: false,
      mapTypeId: mapType,
      clickableIcons: false,
      maxZoom: 17,
    });

    map.mapTypes.set(
      "OSM",
      new window.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return (
            "https://tile.openstreetmap.org/" +
            zoom +
            "/" +
            coord.x +
            "/" +
            coord.y +
            ".png"
          );
        },
        tileSize: new window.google.maps.Size(256, 256),
        name: "OpenStreetMap",
        maxZoom: 18,
      })
    );

    map.mapTypes.set(
      "mpx-street",
      new window.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return (
            "https://api.tiles.mapbox.com/v4/mapbox.streets/" +
            zoom +
            "/" +
            coord.x +
            "/" +
            coord.y +
            ".png?access_token=" +
            access_token
          );
        },
        tileSize: new window.google.maps.Size(256, 256),
        name: "mpx-street",
        maxZoom: 18,
      })
    );

    map.mapTypes.set(
      "mpx-satellite",
      new window.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return (
            "https://api.tiles.mapbox.com/v4/mapbox.streets-satellite/" +
            zoom +
            "/" +
            coord.x +
            "/" +
            coord.y +
            ".png?access_token=" +
            access_token
          );
        },
        tileSize: new window.google.maps.Size(256, 256),
        name: "mpx-satellite",
        maxZoom: 18,
      })
    );

    map.mapTypes.set(
      "mpx-dark",
      new window.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return (
            "https://api.tiles.mapbox.com/v4/mapbox.dark/" +
            zoom +
            "/" +
            coord.x +
            "/" +
            coord.y +
            ".png?access_token=" +
            access_token
          );
        },
        tileSize: new window.google.maps.Size(256, 256),
        name: "mpx-dark",
        maxZoom: 18,
      })
    );

    map.mapTypes.set(
      "ir-map",
      new window.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          return (
            "https://map.ir/shiveh/xyz/1.0.0/Shiveh:Shiveh@EPSG:3857@png/" +
            zoom +
            "/" +
            coord.x +
            "/" +
            coord.y +
            ".png?x-api-key=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImUzYjRkYTE5ZWNmMGQ5NjM1NDVmNmUyYTI3MWI3YTExOWU3MTg4MGVmMjQ4MDhhMjEzMThiMjE4Zjg1ZTAzZmRhYWQ4MDIxYzkyYTg4YTBlIn0.eyJhdWQiOiI4MDIzIiwianRpIjoiZTNiNGRhMTllY2YwZDk2MzU0NWY2ZTJhMjcxYjdhMTE5ZTcxODgwZWYyNDgwOGEyMTMxOGIyMThmODVlMDNmZGFhZDgwMjFjOTJhODhhMGUiLCJpYXQiOjE1ODIyOTExNDgsIm5iZiI6MTU4MjI5MTE0OCwiZXhwIjoxNTg0NzEwMzQ4LCJzdWIiOiIiLCJzY29wZXMiOlsiYmFzaWMiXX0.IMjW-pWvr1pVqe1Y6pBMmbBA2M8RZIKh_6d_Ya9LkHsSQwLWy99AbU021gct0pSA-RxEKXihWuHmKkz8aKVwvmxkFks7mNrXw7-gZDCU78Q2-T0WDTqKd-nHKEKJFxhgnLmAigVEwu5EDdHYQzRQYzH7uOcMtskS69pIWpLuI7M_EJvVsiyXIVPgThpTSvatF6MiPFrzRRxTx0OAi8vRqQVWYTW02OTgIcfxvID1DvBzVlqQa0G9kOdqiBEQ03bFTR-hIe1nG1yLs9M80cwZeyJMIjK3PlHTbzzMTIHCSJCStdF-Agak2xpQ6au8MBJb-i_PIs35zVaZZqjyFYcvNw"
          );
        },
        tileSize: new window.google.maps.Size(256, 256),
        name: "ir-map",
        maxZoom: 18,
      })
    );

    this.getTrackers();
  }
  authSocket() {
    this.socket.emit("authenticate", {
      token:
        window.sessionStorage.getItem("token") ||
        window.localStorage.getItem("token"),
    });
  }
  socketUpdateData() {
    bounds = new window.google.maps.LatLngBounds();
    this.authSocket();

    this.socket.on("authenticate", (data) => {
      if (!data.status) {
        this.authSocket();
        this.setState({ socketFlag: 0 });
      } else {
        if (this.state.socketFlag === 0 || this.state.socketFlag === 2) {
          this.props.alert.success("ارتباط با سرور بر قرار شد.");
        }
        socket = true;
        this.setState({ socketFlag: 1 });
      }
    });
    this.socket.on("connect_error", (error) => {
      if (this.state.socketFlag === 1 || this.state.socketFlag === 2) {
        this.props.alert.error(
          "عدم برقراری ارتباط با سرور، در حال اتصال مجدد..."
        );
      }
      this.authSocket();
      this.setState({ socketFlag: 0 });
    });

    this.socket.on("connect_timeout", (timeout) => {
      if (this.state.socketFlag === 1 || this.state.socketFlag === 2) {
        this.props.alert.error(
          "عدم برقراری ارتباط با سرور، در حال اتصال مجدد..."
        );
      }
      this.authSocket();
      this.setState({ socketFlag: 0 });
    });

    this.socket.on("lastLocations", (data) => {
      let tracers = this.state.data;
      let tracer = this.state.trackerData;
      let usageCode = "";
      for (let item of data) {
        for (let i of tracers) {
          if (item.id === i.id) {
            usageCode = i.usage.code;
            if (item.lat !== i.last_lat) {
              i.last_lat = item.lat;
            }
            if (item.lng !== i.last_lng) {
              i.last_lng = item.lng;
            }
            if (item.course !== i.last_course) {
              i.last_course = item.course;
            }
            if (item.data_color !== i.data_color) {
              i.data_color = item.data_color;
            }
            if (item.sat_count !== i.sat_count) {
              i.sat_count = item.sat_count;
            }
            if (item.sent_at !== i.last_sent) {
              i.last_sent = item.sent_at;
            }
            if (item.acc !== i.last_acc) {
              i.last_acc = item.acc;
            }
            if (item.speed !== i.last_speed) {
              i.last_speed = item.speed;
            }
          }
          if (!this.state.locReportOnMap) {
            loc = new window.google.maps.LatLng(item.last_lat, item.last_lng);
            bounds.extend(loc);
          }
        }
        for (let m of markers) {
          if (item.id === m.markerId) {
            if (item.lat && item.lng) {
              if (
                parseFloat(item.lat) !== m.getPosition().lat() ||
                parseFloat(item.lng) !== m.getPosition().lng()
              ) {
                let latlng = new window.google.maps.LatLng(item.lat, item.lng);
                let image = {
                  url: this.generateMarkerIconUrl(
                    usageCode,
                    item.course,
                    item.data_color,
                    m.isExpired
                  ),
                  scaledSize: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(20, 20),
                };
                m.setPosition(latlng);
                m.setIcon(image);
                m.setVisible(true);
              }
            }
          }
        }
        if (item.id === this.state.isInfoBoxOpen) {
          if (item.lat && item.lng) {
            if (item.lat !== tracer.last_lat) {
              tracer.last_lat = item.lat;
            }
            if (item.lng !== tracer.last_lng) {
              tracer.last_lng = item.lng;
            }
            if (item.course !== tracer.last_course) {
              tracer.last_course = item.course;
            }
            if (item.data_color !== tracer.data_color) {
              tracer.data_color = item.data_color;
            }
            if (item.sat_count !== tracer.sat_count) {
              tracer.sat_count = item.sat_count;
            }
            if (item.sent_at !== tracer.last_sent) {
              tracer.last_sent = item.sent_at;
            }
            if (item.acc !== tracer.last_acc) {
              tracer.last_acc = item.acc;
            }
            if (item.speed !== tracer.last_speed) {
              tracer.last_speed = item.speed;
            }
            this.setState({ trackerData: tracer });
          }
        }
      }
      this.setState({ data: tracers, socket: true });
    });

    this.socket.on("heartbeat", (data) => {
      this.socket.emit("heartbeat", data);
    });
  }
  generateMarkerIconUrl(markerType, rotation, color, isExpired) {
    const round = this.roundRotationNumber(rotation);
    return require(`../../image/markers/${!markerType ? "car" : markerType}/${
      isExpired === 1 ? "gray" : color
    }/${round}.png`);
  }
  roundRotationNumber(rotation) {
    const remained = rotation / 10;
    let round = Math.round(remained);

    if (round > 36) {
      round = 36;
    } else if (round === 0) {
      round = 1;
    }

    return round;
  }
  handleSelectTime(period) {
    let newDate = moment();
    if (period === 1) {
      newDate.subtract(1, "hours");
    } else if (period === 6) {
      newDate.subtract(6, "hours");
    } else if (period === 12) {
      newDate.subtract(12, "hours");
    } else if (period === 24) {
      newDate.subtract(24, "hours");
    }
    this.setState({ selectedTime: period, from: newDate, to: moment() });
  }
  closeTrackerList() {
    this.setState({ trackerListIsOpen: false });
  }
  handleCheckbox(id) {
    let array = this.state.trackerChecked;

    if (array.includes(id)) {
      for (let i in markers) {
        if (markers[i].markerId === id) {
          markers[i].setMap(null);
        }
      }
      let index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
      for (let i in markers) {
        if (markers[i].markerId === id) {
          markers[i].setMap(map);
        }
      }
    }
    this.setState({ trackerChecked: array, isInfoBoxOpen: 0 });

    clearTimeout(TimeOut);
    TimeOut = setTimeout(() => {
      localStorage.setItem("SelectedTrackers", array);
    }, 1000);

    let bnds = new window.google.maps.LatLngBounds();
    for (let i in markers) {
      if (array.includes(markers[i].markerId)) {
        bnds.extend(markers[i].position);
      }
    }
    map.fitBounds(bnds, 100);
    map.panToBounds(bnds);
  }
  chanegMapType(type) {
    this.onScriptLoad(type);
    this.setState({
      mapType: type,
      isInfoBoxOpen: 0,
      showPlayIcon: false,
      showStopDiv: false,
    });
    this.removepolyline();
  }
  closeMapTypsList() {
    this.setState({ mapTypesListIsOpen: false });
  }
  openInfo(data, item) {
    contentString =
      '<div id="content">' + "<p>" + array[item][1] + "</p>" + "</div>";

    infowindow.close();
    infowindow.setContent(contentString);
    infowindow.open(map, markers[item]);
    if (data.last_lat !== null || data.last_lng !== null) {
      let location = new window.google.maps.LatLng(
        parseFloat(data.last_lat),
        parseFloat(data.last_lng)
      );
      map.panTo(location);
    }
    if (!this.state.isReportOnMap) {
      if (this.state.isInfoBoxOpen === data.id) {
        this.setState({ isInfoBoxOpen: 0, trackerData: data });
        infowindow.close();
      } else {
        this.setState({ isInfoBoxOpen: data.id, trackerData: data });
      }
    }
  }
  closeTimeSelect() {
    this.setState({ isTimeSelectOpen: false });
  }
  closeAnimationSpeed() {
    this.setState({ isAnimationSpeedOpen: false });
    this.removepolyline();
  }
  handleRangeSpeed(e) {
    this.setState({ value: e.target.value });
  }
  handleFromDate(moment) {
    this.setState({ from: moment });
  }
  handleToDate(moment) {
    this.setState({ to: moment });
  }

  reloadMap() {
    this.setState({ isInfoBoxOpen: 0, showPlayIcon: false });

    this.onScriptLoad(this.state.mapType);

    if (locReportPolyline != null) {
      this.removepolyline();
    }
  }

  openSelectSpeed() {
    this.setState({ isAnimationSpeedOpen: true, isTimeSelectOpen: false });
  }
  getLocationReport() {
    this.setState({ loading: true });

    let start = this.state.from.utc().locale("en").format(dateFormat);
    let end = this.state.to.utc().locale("en").format(dateFormat);

    let req = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/reports/location/request?id=" +
        this.state.isInfoBoxOpen +
        "&start_date=" +
        start +
        "&end_date=" +
        end,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({ locReportToken: resp.data.data.token });
          setTimeout(this.checkLocationReport, 2000);
        }
      })
      .catch((err) => {
        this.setState({ loading: true });
      });

    this.state.from.local().locale("fa");
    this.state.to.local().locale("fa");
  }
  checkLocationReport() {
    let check = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/reports/location/check/" +
        this.state.locReportToken,
    };

    axios(check)
      .then((resp) => {
        if (resp.data.requestStatus === 3) {
          this.setState({ locReportOnMap: true });
          if (resp.data.data.traces.length > 0) {
            let lineSymbol = {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            };
            locReportPolyline = new window.google.maps.Polyline({
              path: resp.data.data.traces,
              geodesic: true,
              strokeColor: "#3D8DF9",
              strokeOpacity: 0,
              strokeWeight: 2,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            });
            locReportPolyline.setMap(map);

            newLine = new window.google.maps.Polyline({
              path: arrayNewLinePath,
              geodesic: true,
              strokeColor: "#3D8DF9",
              strokeOpacity: 1,
              strokeWeight: 3,
            });
            newLine.setMap(map);

            let data = resp.data.data.traces;
            let stopData = [];
            for (let i in data) {
              if (this.state.showPoints) {
                locPoint = new window.google.maps.Marker({
                  position: {
                    lat: parseFloat(data[i].lat),
                    lng: parseFloat(data[i].lng),
                  },
                  map: map,
                  icon: {
                    path: "m24.291,14.276l-9.586,-9.586c-0.878,-0.878 -2.317,-0.878 -3.195,0l-0.8,0.8c-0.878,0.877 -0.878,2.316 0,3.194l7.314,7.316l-7.315,7.315c-0.878,0.878 -0.878,2.317 0,3.194l0.8,0.8c0.878,0.879 2.317,0.879 3.195,0l9.586,-9.587c0.472,-0.471 0.682,-1.103 0.647,-1.723c0.036,-0.619 -0.174,-1.251 -0.646,-1.723z",
                    fillColor: "#3d8df9",
                    scale: 0.5,
                    fillOpacity: 1,
                    strokeColor: "#3d8df9",
                    strokeWeight: 0,
                    anchor: new window.google.maps.Point(16, 16),
                    rotation: data[i].course - 90,
                  },
                });
                window.google.maps.event.addListener(locPoint, "click", () => {
                  this.openLocPointInfo(data[i], i);
                });
                locPoints.push(locPoint);
              }
              if (
                data[i].is_stopped === 1 &&
                data[i].is_start === 0 &&
                data[i].is_end === 0
              ) {
                stopData.push(data[i]);
              }
              if (data[i].is_start === 1) {
                startPoint = new window.google.maps.Marker({
                  position: {
                    lat: parseFloat(data[i].lat),
                    lng: parseFloat(data[i].lng),
                  },
                  map: map,
                  icon: {
                    url: require("../../image/beginning.png"),
                    scaledSize: new window.google.maps.Size(20, 20),
                    anchor: new window.google.maps.Point(10, 10),
                  },
                });
                window.google.maps.event.addListener(
                  startPoint,
                  "click",
                  () => {
                    this.openStartPointInfo(data[i]);
                  }
                );
              }
              if (data[i].is_end === 1) {
                destinationPoint = new window.google.maps.Marker({
                  position: {
                    lat: parseFloat(data[i].lat),
                    lng: parseFloat(data[i].lng),
                  },
                  map: map,
                  icon: {
                    url: require("../../image/destination.png"),
                    scaledSize: new window.google.maps.Size(30, 30),
                    anchor: new window.google.maps.Point(15, 15),
                  },
                });
                window.google.maps.event.addListener(
                  destinationPoint,
                  "click",
                  () => {
                    this.openDestinationPointInfo(data[i]);
                  }
                );
              }
            }
            for (let i in stopData) {
              stopPoint = new window.google.maps.Marker({
                position: {
                  lat: parseFloat(stopData[i].lat),
                  lng: parseFloat(stopData[i].lng),
                },
                map: map,
                icon: {
                  url: require("../../image/stop.png"),
                  scaledSize: new window.google.maps.Size(30, 30),
                  anchor: new window.google.maps.Point(15, 15),
                },
              });
              window.google.maps.event.addListener(stopPoint, "click", () => {
                this.openStopPointInfo(stopData[i], i);
              });
              stopPoints.push(stopPoint);
            }

            this.setState({
              isInfoBoxOpen: 0,
              showPlayIcon: true,
              isTimeSelectOpen: false,
              locReport: resp.data.data.traces,
              loading: false,
              isReportOnMap: true,
            });

            locReportMarker = new window.google.maps.Marker({
              position: {
                lat: parseFloat(data[0].lat),
                lng: parseFloat(data[0].lng),
              },
              title: "test",
              map: map,
              icon: {
                url: this.generateMarkerIconUrl(
                  this.state.trackerData.usage.code,
                  resp.data.data.traces[0].course,
                  this.state.trackerData.data_color
                ),
                scaledSize: new window.google.maps.Size(40, 40),
                anchor: new window.google.maps.Point(20, 20),
              },
            });

            let latlng = new window.google.maps.LatLng(
              resp.data.data.traces[0].lat,
              resp.data.data.traces[0].lng
            );
            map.panTo(latlng);
            map.setZoom(16);
          } else {
            this.setState({
              isInfoBoxOpen: 0,
              isTimeSelectOpen: false,
              loading: false,
            });
            this.props.alert.error(
              "در بازه زمانی انخاب شده سابقه ای ثبت نشده است."
            );
          }
        } else {
          if (this.state.timeOut <= 120000) {
            setTimeout(this.checkLocationReport, 2000);
            this.setState({ timeOut: this.state.timeOut + 2000 });
          } else {
            this.props.alert.error(
              "مشکلی در پاسخ دهی سرور آمد، لطفا دوباره امتحان کنید."
            );
            this.setState({ timeOut: 0, loading: false });
          }
        }
      })
      .catch((err) => {});
  }
  openStopPointInfo(data, item) {
    if (!this.state.isStopPointOpen) {
      this.setState({ isStopPointOpen: true });
    } else {
      this.setState({ isStopPointOpen: false });
      stopInfowindow.close();
    }
    StopPointContentString =
      '<div id="stopPointContent">' +
      "<p>توقف</p>" +
      "<p>" +
      moment(data.sent_at).format("jYYYY/jMM/jDD | HH:mm") +
      "</p>" +
      "<p>توقف در اینجا: " +
      data.stop_duration +
      " دقیقه</p>" +
      "</div>";

    stopInfowindow.close();
    stopInfowindow.setContent(StopPointContentString);
    stopInfowindow.open(map, stopPoints[item]);
  }

  openLocPointInfo(data, item) {
    if (!this.state.isLocPointOpen) {
      this.setState({ isLocPointOpen: true });
    } else {
      this.setState({ isLocPointOpen: false });
      locInfowindow.close();
    }
    locPointContentString =
      '<div id="locPointContent">' +
      "<p>سرعت: " +
      data.speed +
      " کیلو متر بر ساعت</p>" +
      "<p>" +
      moment(data.sent_at).format("jYYYY/jMM/jDD | HH:mm") +
      "</p>" +
      "</div>";

    locInfowindow.close();
    locInfowindow.setContent(locPointContentString);
    locInfowindow.open(map, locPoints[item]);
  }

  openStartPointInfo(data) {
    if (!this.state.isStartPointOpen) {
      this.setState({ isStartPointOpen: true });
      startPointContentString =
        '<div id="stopPointContent">' +
        "<p>نقطه شروع</p>" +
        "<p>" +
        moment(data.sent_at).format("jYYYY/jMM/jDD | HH:mm") +
        "</p>" +
        "</div>";

      infowindow.close();
      infowindow.setContent(startPointContentString);
      infowindow.open(map, startPoint);
    } else {
      this.setState({ isStartPointOpen: false });
      infowindow.close();
    }
  }
  openDestinationPointInfo(data) {
    if (!this.state.isDestinationPointOpen) {
      this.setState({ isDestinationPointOpen: true });
    } else {
      this.setState({ isDestinationPointOpen: false });
      infowindow.close();
    }
    destinationPointContentString =
      '<div id="stopPointContent">' +
      "<p>نقطه پایان</p>" +
      "<p>" +
      moment(data.sent_at).format("jYYYY/jMM/jDD | HH:mm") +
      "</p>" +
      "<p>مسافت طی شده: " +
      data.total_distance / 1000 +
      " کیلومتر</p>" +
      "</div>";

    infowindow.close();
    infowindow.setContent(destinationPointContentString);
    infowindow.open(map, destinationPoint);
  }
  startAnimate() {
    if (locItem < this.state.locReport.length - 1) {
      locItem = locItem + 1;
    } else if (locItem === this.state.locReport.length - 1) {
      locItem = 0;
      newLine.setPath([]);
    }
    this.setState({
      isAnimationSpeedOpen: false,
      showStopDiv: true,
      isPause: false,
    });
    let moveToLatLng = new window.google.maps.LatLng(
      this.state.locReport[locItem].lat,
      this.state.locReport[locItem].lng
    );

    let speed = 1000 - (this.state.value - 1) * 100;
    if (locItem < this.state.locReport.length) {
      timeOutAnimation = setTimeout(() => {
        this.moveMarker(
          locReportMarker,
          speed / 100,
          locReportMarker.position,
          moveToLatLng,
          locItem
        );
      }, speed);
      this.setState({ currentLocReport: this.state.locReport[locItem] });
    }
  }
  moveMarker(marker, t, current, moveto, item) {
    let image = {
      url: this.generateMarkerIconUrl(
        this.state.trackerData.usage.code,
        this.state.locReport[item].course,
        "green"
      ),
      scaledSize: new window.google.maps.Size(40, 40),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(20, 20),
    };
    marker.setIcon(image);
    map.panTo(moveto);

    let deltalat = (moveto.lat() - current.lat()) / 100;
    let deltalng = (moveto.lng() - current.lng()) / 100;

    for (let i = 0; i < 100; i++) {
      (function (ind) {
        moveTimeOut = setTimeout(function () {
          let lat = marker.position.lat();
          let lng = marker.position.lng();
          lat += deltalat;
          lng += deltalng;
          let latlng = new window.google.maps.LatLng(lat, lng);
          marker.setPosition(latlng);
        }, t * ind);
      })(i);
    }
    newLine.getPath().push(moveto);
    if (locItem < this.state.locReport.length - 1) {
      this.startAnimate();
    }
    if (locItem === this.state.locReport.length - 1) {
      this.setState({ isPause: true });
    }
  }
  stopAnimate() {
    locItem = locItem - 1;
    this.setState({ isPause: true });
    clearTimeout(moveTimeOut);
    clearTimeout(timeOutAnimation);
  }
  removepolyline() {
    clearTimeout(timeOutAnimation);
    this.setState({
      showStopDiv: false,
      isPause: false,
      trackerData: [],
      isReportOnMap: false,
    });
    if (this.state.isReportOnMap) {
      locReportPolyline.setMap(null);
      locReportMarker.setMap(null);
      for (let i in stopPoints) {
        stopPoints[i].setMap(null);
      }
      for (let i in locPoints) {
        locPoints[i].setMap(null);
      }
      startPoint.setMap(null);
      destinationPoint.setMap(null);
      newLine.setMap(null);
      locItem = 0;
      stopPoints = [];
      locPoints = [];
    }
  }
  componentDidMount() {
    const appendChild = Element.prototype.appendChild;

    const urlCatchers = [
      "/AuthenticationService.Authenticate?",
      "/QuotaService.RecordEvent?",
    ];

    Element.prototype.appendChild = function (element) {
      const isGMapScript =
        element.tagName === "SCRIPT" &&
        /maps\.googleapis\.com/i.test(element.src);
      const isGMapAccessScript =
        isGMapScript && urlCatchers.some((url) => element.src.includes(url));

      if (!isGMapAccessScript) {
        return appendChild.call(this, element);
      }
      return element;
    };

    this.onScriptLoad("OSM");

    if (socket === false) {
      this.socketUpdateData();
    } else {
      this.socket.disconnect();
      this.socketUpdateData();
    }

    if (messaging !== null) {
      messaging
        .requestPermission()
        .then(async function () {
          const token = await messaging.getToken();

          let data = {
            key: token,
          };
          let req = {
            method: "post",
            url: config.baseUrl + "api/v1/clients/notifications/subscribe",
            headers: {
              Authorization: "Bearer " + config.token,
            },
            data: data,
          };
          axios(req)
            .then((resp) => {
              if (resp.data.status === 1) {
              }
            })
            .catch((err) => {});
        })
        .catch(function (err) {
          console.log("Unable to get permission to notify.", err);
        });
      navigator.serviceWorker.addEventListener("message", (message) =>
        console.log(message)
      );
    }
  }

  render() {
    const shortcuts = {
      امروز: moment(),
      دیروز: moment().subtract(1, "days"),
    };

    return (
      <div>
        <Loading loading={this.state.loading} />
        {this.state.signout ? <Redirect to="/login" /> : ""}
        <div id={"myMap"} className="Map col-lg-12 col-md-12" />
        <button
          className={`map-btn ${
            this.state.isInfoBoxOpen !== 0
              ? "map-btn1"
              : this.state.showStopDiv
              ? "map-btn4"
              : ""
          }`}
          onClick={() => this.setState({ trackerListIsOpen: true })}
        >
          <i className="icon-signal" />
        </button>
        <button
          className={`map-btn ${
            this.state.isInfoBoxOpen !== 0
              ? "map-btn2"
              : this.state.showStopDiv
              ? "map-btn5"
              : ""
          }`}
          style={{ bottom: "70px" }}
          onClick={() => this.setState({ mapTypesListIsOpen: true })}
        >
          <i className="icon-world" />
        </button>
        <button
          className={`map-btn ${
            this.state.isInfoBoxOpen !== 0
              ? "map-btn3"
              : this.state.showStopDiv
              ? "map-btn6"
              : ""
          }`}
          style={{ bottom: "120px" }}
          onClick={this.reloadMap}
        >
          <i className="icon-mapRefresh" />
        </button>
        <Modal
          isOpen={this.state.trackerListIsOpen}
          onRequestClose={this.closeTrackerList}
          style={{
            content: { top: "calc(100vh / 5)", left: "40%", right: "40%" },
          }}
        >
          <p>ردیاب ها</p>
          <ul>
            {this.state.trackerList.length > 0 ? (
              this.state.trackerList.map((key) => (
                <li key={key[0]}>
                  <button onClick={() => this.handleCheckbox(key[0])}>
                    <img
                      src={require(`../../image/Ellipse-${
                        this.state.trackerChecked.includes(key[0]) ? "1" : "0"
                      }@2x.png`)}
                    />
                    <p>{key[1]}</p>
                    <span className="checkbox">
                      <img
                        src={require(`../../image/${
                          this.state.trackerChecked.includes(key[0])
                            ? "checked"
                            : "uncheck"
                        }.svg`)}
                      />
                    </span>
                  </button>
                </li>
              ))
            ) : (
              <p>ردیابی موجود نیست!</p>
            )}
          </ul>
        </Modal>
        <Modal
          isOpen={this.state.mapTypesListIsOpen}
          onRequestClose={this.closeMapTypsList}
          style={{
            content: { top: "calc(100vh / 5)", left: "40%", right: "40%" },
          }}
        >
          <p>نقشه ها</p>
          <ul>
            <li>
              <button onClick={() => this.chanegMapType("roadmap")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "roadmap" ? "1" : "0"
                  }@2x.png`)}
                />
                <p>نقشه پیش فرض Google</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "roadmap" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => this.chanegMapType("satellite")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "satellite" ? "1" : "0"
                  }@2x.png`)}
                />
                <p>نقشه ماهواره ای Google</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "satellite" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => this.chanegMapType("OSM")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "OSM" ? "1" : "0"
                  }@2x.png`)}
                />
                <p>نقشه openstrest</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "OSM" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => this.chanegMapType("mpx-street")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "mpx-street" ? "1" : "0"
                  }@2x.png`)}
                />
                <p>نقشه mapbox street</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "mpx-street" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => this.chanegMapType("mpx-satellite")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "mpx-satellite" ? "1" : "0"
                  }@2x.png`)}
                />
                <p>نقشه ماهواره ای mapbox</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "mpx-satellite" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => this.chanegMapType("mpx-dark")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "mpx-dark" ? "1" : "0"
                  }@2x.png`)}
                />
                <p>حالت تاریک نقشه mapbox</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "mpx-dark" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button onClick={() => this.chanegMapType("ir-map")}>
                <img
                  src={require(`../../image/Ellipse-${
                    this.state.mapType === "ir-map" ? "1" : "0"
                  }@2x.png`)}
                />
                <p> نقشه ایرانی</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.mapType === "ir-map" ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
          </ul>
        </Modal>
        <Modal
          isOpen={this.state.isTimeSelectOpen}
          onRequestClose={this.closeTimeSelect}
          style={{
            content: { top: "calc(100vh / 7)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>انتخاب بازه زمانی</p>
          </div>
          <ul>
            <li>
              <button
                onClick={() => {
                  this.handleSelectTime(1);
                }}
              >
                <p>یک ساعت گذشته</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.selectedTime === 1 ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  this.handleSelectTime(6);
                }}
              >
                <p>شش ساعت گذشته</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.selectedTime === 6 ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  this.handleSelectTime(12);
                }}
              >
                <p>دوازده ساعت گذشته</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.selectedTime === 12 ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  this.handleSelectTime(24);
                }}
              >
                <p>بیست و چهار ساعت گذشته</p>
                <span className="radio">
                  <img
                    src={require(`../../image/radio${
                      this.state.selectedTime === 24 ? "S" : "U"
                    }.png`)}
                  />
                </span>
              </button>
            </li>
          </ul>
          <div className="time-div">
            <div className="right">
              <label>از تاریخ</label>
              <DatetimePickerTrigger
                shortcuts={shortcuts}
                moment={this.state.from}
                onChange={this.handleFromDate}
                showTimePicker={false}
                position="top"
                lang="fa"
                isSolar={true}
              >
                <input
                  className="date-input"
                  type="text"
                  value={this.state.from.format("jYYYY/jMM/jDD")}
                  readOnly
                />
              </DatetimePickerTrigger>
            </div>
            <div className="left">
              <label>تا تاریخ</label>
              <DatetimePickerTrigger
                shortcuts={shortcuts}
                moment={this.state.to}
                onChange={this.handleToDate}
                showTimePicker={false}
                position="top"
                lang="fa"
                isSolar={true}
              >
                <input
                  className="date-input"
                  type="text"
                  value={this.state.to.format("jYYYY/jMM/jDD")}
                  readOnly
                />
              </DatetimePickerTrigger>
            </div>
          </div>
          <div className="time-div" style={{ marginBottom: "0px" }}>
            <div className="right">
              <label>از ساعت</label>
              <TimePicker
                showSecond={false}
                className="time-input"
                defaultValue={this.state.from}
                onChange={this.handleFromDate}
              />
            </div>
            <div className="left">
              <label>تا ساعت</label>
              <TimePicker
                showSecond={false}
                className="time-input"
                defaultValue={this.state.to}
                onChange={this.handleToDate}
              />
            </div>
          </div>
          <div
            className="remindDiv"
            onClick={() => {
              this.setState({ showPoints: !this.state.showPoints });
            }}
          >
            <span className="checkbox">
              <img
                src={require(`../../image/${
                  this.state.showPoints === true ? "checked" : "uncheck"
                }.svg`)}
              />
            </span>
            <p className="blue" style={{ color: "#000" }}>
              نمایش مارکر ها
            </p>
          </div>
          <button className="blueBtn btn" onClick={this.getLocationReport}>
            دریافت سابقه
          </button>
          <button
            className="pinkBtn btn"
            onClick={this.closeTimeSelect}
            style={{ marginRight: "10px", padding: "15px 25px" }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAnimationSpeedOpen}
          onRequestClose={this.closeAnimationSpeed}
          style={{
            content: { top: "calc(100vh / 5)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>سرعت انیمیشن</p>
          </div>
          <div>
            <span className="circle">{this.state.value}</span>
            <div className="range-div">
              <span
                className={`step ${this.state.value >= 1 ? "blue" : ""}`}
                style={{ left: "0.5%" }}
              />
              <span
                className={`step ${this.state.value >= 2 ? "blue" : ""}`}
                style={{ left: "10.5%" }}
              />
              <span
                className={`step ${this.state.value >= 3 ? "blue" : ""}`}
                style={{ left: "20.5%" }}
              />
              <span
                className={`step ${this.state.value >= 4 ? "blue" : ""}`}
                style={{ left: "30.5%" }}
              />
              <span
                className={`step ${this.state.value >= 5 ? "blue" : ""}`}
                style={{ left: "40.5%" }}
              />
              <span
                className={`step ${this.state.value >= 6 ? "blue" : ""}`}
                style={{ left: "50.5%" }}
              />
              <span
                className={`step ${this.state.value >= 7 ? "blue" : ""}`}
                style={{ left: "60.5%" }}
              />
              <span
                className={`step ${this.state.value >= 8 ? "blue" : ""}`}
                style={{ left: "70.5%" }}
              />
              <span
                className={`step ${this.state.value >= 9 ? "blue" : ""}`}
                style={{ left: "80.5%" }}
              />
              <span
                className={`step ${this.state.value >= 10 ? "blue" : ""}`}
                style={{ right: "3%" }}
              />
              <input
                type="range"
                min={1}
                max={10}
                step={1}
                className="slider"
                onChange={this.handleRangeSpeed}
                value={this.state.value}
              />
            </div>
          </div>
          <button
            className="blueBtn btn"
            style={{ padding: "15px 60px" }}
            onClick={this.startAnimate}
          >
            شروع
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "10px", padding: "15px 25px" }}
            onClick={this.closeAnimationSpeed}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isExpiredTracersOpen}
          onRequestClose={() => {
            this.setState({ isExpiredTracersOpen: false });
          }}
          style={{
            content: { top: "calc(100vh / 5)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>اتمام شارژ سالیانه سامانه ردیاب</p>
          </div>

          <p style={{ fontSize: "13px", textAlign: "center" }}>
            شارژ سالیانه سامانه ردیاب های زیر به پایان رسیده است، به منظور
            استفاده از امکانات سامانه، لطفا در اولین فرصت اقدام به پرداخت
            نمایید!
          </p>

          <ul className="expiredTracers">
            {this.state.expiredTracers.map((key) => (
              <li>
                <div>
                  <label>عنوان ردیاب: </label>
                  <b>{key.title ? key.title : "بدون عنوان"}</b>
                </div>

                <div>
                  <label>تاریخ انقضا: </label>
                  <b>{moment(key.expires_at).format("jYYYY/jMM/jDD")}</b>
                </div>
              </li>
            ))}
          </ul>

          <a
            href={`http://irgogps.com/payment/?token=${config.token}`}
            target="blank"
          >
            <button className="blueBtn btn" style={{ padding: "15px 95px" }}>
              پرداخت
            </button>
          </a>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "10px", padding: "15px 55px" }}
            onClick={() => {
              this.setState({ isExpiredTracersOpen: false });
            }}
          >
            انصراف
          </button>
        </Modal>
        <div
          className={"infoBox"}
          style={{
            display:
              this.state.isInfoBoxOpen !== 0 &&
              (this.state.showPlayIcon === false ||
                this.state.showStopDiv === false)
                ? "block"
                : "none",
          }}
        >
          <button
            className="closePolyline"
            style={{ top: "7px" }}
            onClick={() => {
              this.setState({ isInfoBoxOpen: 0 });
            }}
          >
            بستن
          </button>
          <div className="infoHeader">
            <div className="right">
              <img src={require("../../image/Ellipse-2@2x.png")} />
              <p>{this.state.trackerData.title}</p>
            </div>
            <div className="left">
              <a
                href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${this.state.trackerData.last_lat},${this.state.trackerData.last_lng}`}
                target="blank"
              >
                <p>من تا خودرو</p>
              </a>
              <button>
                <i className="icon-share" />
              </button>
            </div>
          </div>
          <div className="infoContent">
            <div className="right">
              <button
                onClick={() => {
                  this.setState({ isTimeSelectOpen: true });
                }}
              >
                <i className="icon-time" />
                <p>سابقه مدیریت</p>
              </button>
            </div>
            <div className="left">
              <div className="right">
                <div>
                  <i className="icon-save" />
                  <p>
                    {moment(this.state.trackerData.last_sent).format(
                      "jYYYY/jMM/jDD | HH:mm"
                    )
                      ? moment(this.state.trackerData.last_sent).format(
                          "jYYYY/jMM/jDD | HH:mm"
                        )
                      : ""}
                  </p>
                </div>
                <div>
                  <i className="icon-speed" />
                  <p>{this.state.trackerData.last_speed} کیلومتر بر ساعت</p>
                </div>
              </div>
              <div className="left">
                <div>
                  <i className="icon-motor" />
                  <p>
                    {this.state.trackerData.last_acc === 0 ? "خاموش" : "روشن"}
                  </p>
                </div>
                <div>
                  <i className="icon-radar" />
                  <p>
                    {!this.state.trackerData.last_sat_count
                      ? 0
                      : this.state.trackerData.last_sat_count}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="stop-div"
          style={{ display: this.state.showStopDiv ? "block" : "none" }}
        >
          <p className="time">
            {moment(this.state.currentLocReport.sent_at).format(
              "jYYYY/jMM/jDD | HH:mm"
            )}
          </p>
          <button className="closePolyline" onClick={this.removepolyline}>
            بستن
          </button>
          <div>
            <h5>
              {parseFloat(this.state.currentLocReport.total_distance / 1000)}{" "}
              کیلو متر
            </h5>
            <p>مسافت طی شده</p>
          </div>
          <div>
            {this.state.isPause ? (
              <button
                className="stop"
                style={{ backgroundColor: "rgba(61, 141, 249, 0.15)" }}
                onClick={() => {
                  this.setState({ isAnimationSpeedOpen: true });
                }}
              >
                <i className="icon-play" />
              </button>
            ) : (
              <button className="stop" onClick={this.stopAnimate}>
                <i className="icon-pause" />
              </button>
            )}
          </div>
          <div>
            <h5>km/h {this.state.currentLocReport.speed}</h5>
            <p>سرعت</p>
          </div>
        </div>
        <button
          className="play"
          style={{ display: this.state.showPlayIcon ? "block" : "none" }}
          onClick={() => {
            this.setState({
              isAnimationSpeedOpen: true,
              showPlayIcon: false,
            });
          }}
        >
          <i className="icon-play" />
        </button>
      </div>
    );
  }
}
export default withAlert(MainMap);
