import React , {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider as AlertProvider } from 'react-alert';
import Alert from "./components/Alert";

const options = {
    position: 'top center',
    timeout: 5000,
    transition: 'scale'
};

class Root extends Component{

  componentDidMount() {

    }

    render(){
        return(
            <AlertProvider template={Alert} {...options}>
                <App/>
            </AlertProvider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
