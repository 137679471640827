import React, { Component } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import { DatetimePickerTrigger } from 'imrc-datetime-picker';
import { config } from '../../config';
import ReactToPrint from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const dateFormat = "YYYY-MM-DD";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: true
});

class MovingReports extends Component {

    constructor() {
        super();

        this.state = {
            selected: 0,
            data: [],
            selectedDataId: [],
            isCallOpen: false
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(id) {
        let array = this.state.selectedDataId;
        if (array.includes(id)) {
            let index = array.indexOf(id);
            if (index > -1) {
                array.splice(index, 1);
            }
        } else {
            array.push(id);
        }
        this.setState({ selectedDataId: array });
    }

    componentDidMount() {

    }

    render() {

        return (
            <div className='content col-lg-12 col-md-12'>
                <div className='content-body aboutus'>
                    <div className='center top'>
                        <img src={require('../../image/logo.png')} alt='Go Tracker' />
                        <p>Go Tracker</p>
                    </div>
                    <div class="menu">
                        <a href='https://telegram.me/go_gps' target='__blank' class="menu-item">
                            <div class="icon location">
                            </div>
                            <div class="label"><p>کانال تلگرام (سوالات متداول)</p></div>
                        </a>
                        <a href='http://mrea.co.ir' class="menu-item" target='__blank'>
                            <div class="icon computer">
                            </div>
                            <div class="label"><p>وبسایت</p></div>
                        </a>
                        <button class="menu-item" onClick={()=> {
                            this.setState({isCallOpen: true})
                        }}>
                            <div class="icon headphones">
                            </div>
                            <p class="label"><p>تماس با پشتیبانی</p></p>
                        </button>
                        <a href='instagram://user?username=mrea.co' target='__blank' class="menu-item" >
                            <div class="icon camera">
                            </div>
                            <div class="label"><p>اینستاگرام</p></div>
                        </a>
                    </div>
                </div>

                <Modal
          isOpen={this.state.isCallOpen}
          onRequestClose={() => {
            this.setState({ isCallOpen: false, editingTracerId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>تماس با پشتیبانی</p>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            یکی از روش های زیر را برای ارتباط با پشتیبانی انتخاب کنید
          </p>
          <a
            href="tel:09916111182"
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی تلفنی ۱</p>
          </a>
          <a
            href="tel:09916111183"
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی تلفنی ۲</p>
          </a>
          <a
            href='tel:09916111184'
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی تلفنی ساعات غیر کاری</p>
          </a>

          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            ۶ عصر تا تا ۱۱ شب - روز های تعطیل ساعت ۱۱ الی ۱۵
          </p>

          <a
            href='whatsapp://send?phone=+989916111182'
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی واتساپ ۱</p>
          </a>
          <a
            href='whatsapp://send?phone=+989916111183'
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی واتساپ ۲</p>
          </a>

          <a
            href='https://telegram.me/go_gpstracker'
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی تلگرام ۱</p>
          </a>
          <a
            href='https://telegram.me/go_gpstrackerr'
            className="blueBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block", backgroundColor: "#cae2ff" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            <p style={{color: "#3d8df9", margin: 0}}>پشتیبانی تلگرام ۲</p>
          </a>


          <button
            className="pinkBtn btn"
            style={{margin: "10px auto", padding: "15px 75px", display: "block" }}
            onClick={() => {
              this.setState({ isCallOpen: false, editingTracerId: 0 });
            }}
          >
            انصراف
          </button>
        </Modal>    

            </div>
        );
    }
}

export default MovingReports;