import React, { Component } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import { DatetimePickerTrigger } from 'imrc-datetime-picker';
import Pagination from "react-js-pagination";
import { config } from '../../config';
import Loading from "../Loading";
import ReactToPrint from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const dateFormat = "YYYY-MM-DD HH:mm:00";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: true
});

class FuelReports extends Component {

    constructor() {
        super();

        const defaultMoment = moment();

        this.state = {
            data: [],
            selected: 0,
            from: defaultMoment,
            to: defaultMoment,
            selectedTracker: 0,
            fuelReportToken: '',
            fuelRepData: [],
            selectedDataId: [],
            loading: false,
            timeOut: 0
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFromDate = this.handleFromDate.bind(this);
        this.handleToDate = this.handleToDate.bind(this);
        this.getFuelReports = this.getFuelReports.bind(this);
        this.checkFuelReports = this.checkFuelReports.bind(this);
    }

    handleSelect(id) {
        let array = this.state.selectedDataId;
        if (array.includes(id)) {
            let index = array.indexOf(id);
            if (index > -1) {
                array.splice(index, 1);
            }
        } else {
            array.push(id);
        }
        this.setState({ selectedDataId: array });
    }

    handleChange(e) {
        this.setState({ selectedTracker: e.target.value })
    }

    handleFromDate(moment) {
        this.setState({ from: moment });

    }

    handleToDate(moment) {
        this.setState({ to: moment });
    }

    getFuelReports() {
        if (this.state.selectedTracker === 0) {
            return;
        }
        this.setState({ loading: true });
        let start = this.state.from.utc().locale("en").format(dateFormat);
        let end = this.state.to.utc().locale("en").format(dateFormat);

        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/reports/fuel/request?id=' + this.state.selectedTracker + '&start_date=' + start + '&end_date=' + end,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        console.log(req.url);

        axios(req
        ).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({ fuelReportToken: resp.data.data.token });
                console.log(resp.data.data.token);
                setTimeout(this.checkFuelReports(1), 2000);

            }
        }).catch(err => {
            console.log(err.message);
        });

        this.state.from.local().locale("fa");
        this.state.to.local().locale("fa");
    }

    checkFuelReports(page) {
        this.setState({ currPage: page });
        let check = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/reports/fuel/check/' + this.state.fuelReportToken + '?page=' + page
        };
        let array = [];
        axios(check
        ).then((resp) => {
            if (resp.data.requestStatus === 3) {
                for (let i in resp.data.data) {
                    array.push(parseInt(i));
                }
                this.setState({ fuelRepData: resp.data.data, loading: false, selectedDataId: array });

            } else {
                if (this.state.timeOut <= 120000) {
                    setTimeout(function () {
                        console.log("not ready now")
                        this.checkFuelReports(this.state.currPage)
                    }.bind(this), 2000);
                    this.setState({ timeOut: this.state.timeOut + 2000 });
                } else {
                    this.props.alert.error('مشکلی در پاسخ دهی سرور آمد، لطفا دوباره امتحان کنید.');
                    this.setState({ timeOut: 0, loading: false });

                }
            }
        }).catch(err => {
            console.log(err.message);
        });
    }

    componentDidMount() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    data: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });

    }

    render() {
        const shortcuts = {
            'امروز': moment(),
            'دیروز': moment().subtract(1, 'days')
        };
        return (
            <div className='content col-lg-12 col-md-12'>
                <Loading loading={this.state.loading} />
                <div className='content-head filter-div col-md-12'>
                    <p>نمایش بر اساس </p>
                    <div className='input-group'>
                        <select onChange={this.handleChange}>
                            <option disabled selected>عنوان ردیاب</option>
                            {this.state.data.map((key) =>
                                <option value={key.id}>{key.title}</option>
                            )}
                        </select>
                    </div>
                    <DatetimePickerTrigger
                        shortcuts={shortcuts}
                        moment={this.state.from}
                        onChange={this.handleFromDate}
                        position="buttom"
                        lang='fa'
                        isSolar={true}
                    >
                        <div className='input-group'>
                            <p style={{ marginBottom: '0', width: '30%' }}>تاریخ شروع</p>
                            <input className='input' style={{ width: '70%' }} type="text"
                                value={this.state.from.format("jYYYY/jMM/jDD HH:mm")}
                                readOnly />
                        </div>
                    </DatetimePickerTrigger>
                    <DatetimePickerTrigger
                        shortcuts={shortcuts}
                        moment={this.state.to}
                        onChange={this.handleToDate}
                        position="buttom"
                        lang='fa'
                        isSolar={true}
                    >
                        <div className='input-group'>
                            <p style={{ marginBottom: '0', width: '30%' }}>تاریخ پایان</p>
                            <input className='input' style={{ width: '70%' }} type="text"
                                value={this.state.to.format("jYYYY/jMM/jDD HH:mm")}
                                readOnly />
                        </div>
                    </DatetimePickerTrigger>
                    <button className='blueBtn' onClick={this.getFuelReports}>نمایش گزارش</button>

                    <ReactToPrint
                        trigger={() => <button title='پرینت' className='blueBtn print-btn' style={{ float: 'left' }}><i
                            className='icon-print' /></button>}
                        content={() => this.printTbl}
                    />

                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="blueBtn excelBtn excel-btn"
                        table="exelTable"
                        filename="fuelReport"
                        sheet="tablexls"
                        buttonText={<i className='icon-excel' />} />
                </div>
                <div className='content-body'>
                    <table className='fuel tbl'>
                        <thead>
                            <tr>
                                <th><img src={require('../../image/tick.png')} /></th>
                                <th>مسافت طی شده(کیلومتر)</th>
                                <th>مصرف سوخت(لیتر)</th>
                                <th>سوخت مصرف شده(لیتر)</th>
                                <th>سوخت باقی مانده(لیتر)</th>
                                <th>حداقل سرعت(کیلومتر بر ساعت)</th>
                                <th>حداکثر سرعت(کیلومتر بر ساعت)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <button onClick={() => {
                                        this.handleSelect(0)
                                    }}>
                                        <span className='checkbox'>
                                            <img
                                                src={require(`../../image/${this.state.selected === 0 ? 'checked' : 'uncheck'}.svg`)} />
                                        </span>
                                    </button>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.total_distance}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.fuel_usage}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.total_fuel_usage}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.current_fuel}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.min_speed}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.max_speed}</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="mobile-info-card row">
                        <div className="col-xs-12 text-center">
                            <label>مسافت طی شده(کیلومتر): </label>
                            <p>{this.state.fuelRepData.total_distance}</p>
                        </div>
                        <div className="col-xs-12 text-center">
                            <label>مصرف سوخت(لیتر): </label>
                            <p>{this.state.fuelRepData.fuel_usage}</p>
                        </div>
                        <div className="col-xs-12 text-center">
                            <label>سوخت مصرف شده(لیتر): </label>
                            <p>{this.state.fuelRepData.total_fuel_usage}</p>
                        </div>
                        <div className="col-xs-12 text-center">
                            <label>سوخت باقی مانده(لیتر): </label>
                            <p>{this.state.fuelRepData.current_fuel}</p>
                        </div>
                        <div className="col-xs-12 text-center">
                            <label>حداقل سرعت(کیلومتر بر ساعت): </label>
                            <p>{this.state.fuelRepData.min_speed}</p>
                        </div><div className="col-xs-12 text-center">
                            <label>حداکثر سرعت(کیلومتر بر ساعت): </label>
                            <p>{this.state.fuelRepData.max_speed}</p>
                        </div>

                    </div>
                </div>
                <div className='hide'>
                    <table ref={el => (this.printTbl = el)} id={'exelTable'} className='brintTable'>
                        <thead>
                            <tr>
                                <th>مسافت طی شده(کیلومتر)</th>
                                <th>مصرف سوخت(لیتر)</th>
                                <th>سوخت مصرف شده(لیتر)</th>
                                <th>سوخت باقی مانده(لیتر)</th>
                                <th>حداقل سرعت(کیلومتر بر ساعت)</th>
                                <th>حداکثر سرعت(کیلومتر بر ساعت)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>
                                    <p>{this.state.fuelRepData.total_distance}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.fuel_usage}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.total_fuel_usage}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.current_fuel}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.min_speed}</p>
                                </td>
                                <td>
                                    <p>{this.state.fuelRepData.max_speed}</p>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default FuelReports;