import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import {withAlert} from 'react-alert';
import {config} from '../../config';
import Loading from "../Loading";

class RolesList extends Component {

    constructor() {
        super();

        this.state = {
            data:[],
            selected: 0,
            selectedTracker: 0,
            isAddNewGroupOpen:false,
            permissions:[],
            selectedPermissions:[],
            selectedPermissionsId:[],
            loading:false,
            isUpdateGroupOpen:false,
            oneRole:{},
            editingGroup: 0
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openAddNewGroup = this.openAddNewGroup.bind(this);
        this.closeAddNewGroup = this.closeAddNewGroup.bind(this);
        this.storeRole = this.storeRole.bind(this);
        this.getRoles = this.getRoles.bind(this);
        this.getOneRole = this.getOneRole.bind(this);
        this.updateOneRole = this.updateOneRole.bind(this);

    }

    handleSelect(selected) {
        if (this.state.selected === selected) {
            this.setState({selected: 0});
        } else {
            this.setState({selected: selected});
        }
    }

    handleChange(e){
        this.setState({selectedTracker: e.target.value})
    }
    openAddNewGroup(){
        this.setState({isAddNewGroupOpen: true});
    }
    closeAddNewGroup(){
        this.setState({isAddNewGroupOpen: false , selectedPermissions: [] , selectedPermissionsId: []});
    }
    handleSelectPermissions(permission){
        let permissions = this.state.selectedPermissions;
        let permissionsId = this.state.selectedPermissionsId;
        let include = false;

        for(let item of permissions){
            if(permission.id === item.id){
                let index = permissions.indexOf(item);
                if (index > -1) {
                    permissions.splice(index, 1);
                }
                include = true;
            }
        }
        if(include === false){
            permissions.push(permission);
        }
        if(permissionsId.includes(permission.id)) {
            let index = permissionsId.indexOf(permission.id);
            if (index > -1) {
                permissionsId.splice(index, 1);
            }
        }else {
            permissionsId.push(permission.id);
        }
        this.setState({selectedPermissions: permissions , selectedPermissionsId: permissionsId});
    }

    storeRole(){
        this.setState({loading: true});
        if(document.getElementById('roleSlug').value !== null || document.getElementById('roleName').value !== null || document.getElementById('roleDes').value !== null){
            let data = {
                'slug' : document.getElementById('roleSlug').value ,
                'name': document.getElementById('roleName').value,
                'description': document.getElementById('roleDes').value,
                'permissions' : this.state.selectedPermissions
            };
            let req = {
                method: 'post',
                url: config.baseUrl + 'api/v1/admins/roles',
                headers: {
                    Authorization: "Bearer " + config.token
                },
                data: data
            };
            axios(req).then((resp) => {
                if (resp.data.status === 1) {
                    this.getRoles();
                    this.props.alert.success('گروه دسترسی با موفقیت اضافه شد.');
                    this.setState({loading: false , isAddNewGroupOpen: false , selectedPermissions:[] , selectedPermissionsId:[]});
                }
            }).catch(err => {
                console.log(err);
                this.props.alert.error('خطایی رخ داده است.');
                this.setState({loading: false});
            });
        }else {
            this.props.alert.info('فیلد ها را به دقت پر کنید.');
        }

    }
    getOneRole(id){
        let permissions = [];
        let permissionsId = [];
        this.setState({isUpdateGroupOpen: true , loading:true , editingGroup: id});
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/roles/'+id,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                for(let item of resp.data.data.permissions){
                    permissionsId.push(item.id);
                    permissions.push(item);
                }
                console.log(permissions);
                this.setState({oneRole: resp.data.data , loading: false , selectedPermissionsId: permissionsId , selectedPermissions: permissions});
            }
        }).catch(err => {
            console.log(err);
            this.props.alert.error('خطایی رخ داده است.');
            this.setState({loading: false});
        });
    }

    updateOneRole(){
        this.setState({loading: true});
        if(document.getElementById('roleSlug2').value !== null || document.getElementById('roleName2').value !== null || document.getElementById('roleDes2').value !== null){
            let data = {
                'slug' : document.getElementById('roleSlug2').value ,
                'name': document.getElementById('roleName2').value,
                'description': document.getElementById('roleDes2').value,
                'permissions' : this.state.selectedPermissions
            };
            let req = {
                method: 'PUT',
                url: config.baseUrl + 'api/v1/admins/roles/'+this.state.editingGroup,
                headers: {
                    Authorization: "Bearer " + config.token
                },
                data: data
            };
            axios(req).then((resp) => {
                if (resp.data.status === 1) {
                    this.getRoles();
                    this.props.alert.success('تغییرات با موفقیت اضافه شد.');
                    this.setState({loading: false , isUpdateGroupOpen: false , selectedPermissions:[] , selectedPermissionsId:[] , oneRole:{}});
                }
            }).catch(err => {
                console.log(err);
                this.props.alert.error('خطایی رخ داده است.');
                this.setState({loading: false});
            });
        }else {
            this.props.alert.info('فیلد ها را به دقت پر کنید.');
        }
    }

    getRoles(){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/roles',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({data: resp.data.data});
            }
        }).catch(err => {
            console.log(err);
        });
    }

    componentDidMount(){
        this.getRoles();

        let req2 = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/permissions',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req2).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    permissions: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });

    }

    render() {

        return (
            <div className='content col-lg-12 col-md-12'>
                <Loading loading={this.state.loading} />
                <div className='content-head filter-div speed-filter col-md-12'>
                    <button className='blueBtn' style={{float:'left'}} onClick={this.openAddNewGroup}>افزودن گروه</button>
                </div>
                <div className='content-body'>
                    <table className='roles tbl'>
                        <thead>
                        <tr>
                            <th><img src={require('../../image/tick.png')}/></th>
                            <th>عنوان</th>
                            <th>کلید واژه</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key)=>
                            <tr>
                                <td>
                                    <button onClick={() => {
                                        this.handleSelect(1)
                                    }}>
                                     <span className='checkbox'>
                                         <img
                                             src={require(`../../image/${this.state.selected === 1 ? 'checked' : 'uncheck'}.svg`)}/>
                                     </span>
                                    </button>
                                </td>
                                <td>
                                    <p>{key.name}</p>
                                </td>
                                <td>
                                    <p>{key.slug}</p>
                                </td>
                                <td>
                                    <td>
                                        <button onClick={()=>{this.getOneRole(key.id)}} className='edit-btn' title='ویرایش'>
                                            <i className='icon-edit'/>
                                        </button>
                                        <button className='delete-btn' title='حذف' >
                                            <i className='icon-delete' />
                                        </button>
                                    </td>
                                </td>
                            </tr>
                        )}

                        </tbody>
                    </table>
                </div>
                <Modal
                    isOpen={this.state.isAddNewGroupOpen}
                    onRequestClose={this.closeAddNewGroup}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن گروه دسترسی</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات گروه دسترسی را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان گروه دسترسی</h5>
                            <div className='input-group'>
                                <input id='roleName' className='input' />
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان نمایشی</h5>
                            <div className='input-group'>
                                <input id='roleSlug' className='input'/>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>توضیحات</h5>
                        <div className='input-group'>
                            <input id='roleDes' className='input'/>
                        </div>
                    </div>
                    <div>
                        <p style={{display:'inline-block' , fontSize:'13px'}}>لیست دسترسی ها:</p>
                        <div style={{display:'inline-block' , padding:'0 10px'}} className='permissions-list'>
                            {this.state.permissions.map((key)=>
                                <button key={key.id} onClick={()=>{
                                    this.handleSelectPermissions(key);
                                }}>
                                    <p style={{display:'inline-block' , fontSize:'11px' , marginLeft:'5px'}}>{key.name}</p>
                                    <span className='checkbox'>
                                <img src={require(`../../image/${this.state.selectedPermissionsId.includes(key.id) === true ? 'checked' : 'uncheck'}.svg`)}/>
                            </span>
                                </button>

                            )}
                        </div>
                    </div>
                    <button onClick={this.storeRole} className='blueBtn btn' style={{padding: '15px 70px'}}>افزودن گروه دسترسی</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={this.closeAddNewGroup}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isUpdateGroupOpen}
                    onRequestClose={()=>{this.setState({
                        isUpdateGroupOpen: false,
                        selectedPermissionsId: [],
                        selectedPermissions: [],
                        oneRole: {}
                    })}}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>ویرایش گروه دسترسی</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات گروه دسترسی را ویرایش کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان گروه دسترسی</h5>
                            <div className='input-group'>
                                <input id='roleName2' className='input' defaultValue={this.state.oneRole !== {} ? this.state.oneRole.name : ''} />
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان نمایشی</h5>
                            <div className='input-group'>
                                <input id='roleSlug2' className='input' defaultValue={this.state.oneRole !== {} ? this.state.oneRole.slug : ''}/>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>توضیحات</h5>
                        <div className='input-group'>
                            <input id='roleDes2' className='input' defaultValue={this.state.oneRole !== {} ? this.state.oneRole.description : ''}/>
                        </div>
                    </div>
                    <div>
                        <p style={{display:'inline-block' , fontSize:'13px'}}>لیست دسترسی ها:</p>
                        <div style={{display:'inline-block' , padding:'0 10px'}} className='permissions-list'>
                            {this.state.permissions.map((key)=>
                                    <button key={key.id} onClick={()=>{
                                        this.handleSelectPermissions(key);
                                    }}>
                                        <p style={{display:'inline-block' , fontSize:'11px' , marginLeft:'5px'}}>{key.name}</p>
                                        <span className='checkbox'>
                                <img src={require(`../../image/${this.state.selectedPermissionsId.includes(key.id) === true ? 'checked' : 'uncheck'}.svg`)}/>
                            </span>
                                    </button>

                            )}
                        </div>
                    </div>
                    <button onClick={this.updateOneRole} className='blueBtn btn' style={{padding: '15px 70px'}}>ثبت تغییرات</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}}
                            onClick={()=>{this.setState({
                            isUpdateGroupOpen: false,
                                selectedPermissionsId: [],
                                selectedPermissions: [],
                                oneRole: {}
                            })}}
                    >انصراف</button>
                </Modal>

            </div>
        );
    }
}

export default withAlert(RolesList);