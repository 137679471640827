import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import {withAlert} from 'react-alert';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import Pagination from "react-js-pagination";
import {config} from '../../config';
import Loading from "../Loading";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: true
});

class Transactions extends Component {

    constructor() {
        super();
        const defaultMoment = moment();

        this.state = {
            data: [],
            loading: true
        };
    }


    componentDidMount() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'payment/api/user-invoices?token=' + config.token,
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    data: resp.data.data,
                    loading: false
                });
                console.log(resp.data.data);
            }
        }).catch(err => {
            console.log(err);
            this.setState({loading: false});

        });
    }

    render() {
        const shortcuts = {
            'امروز': moment(),
            'دیروز': moment().subtract(1, 'days')
        };

        return (
            <div className='content col-lg-12 col-md-12'>
                <Loading loading={this.state.loading}/>
                <div className='content-body'>
                    <table className='transaction tbl '>
                        <thead>
                        <tr>
                            <th>شماره فاکتور</th>
                            <th>مبلغ کل</th>
                            <th>تاریخ صدور</th>
                            <th>وضعیت</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key) =>
                            <tr>
                                <td>
                                    <p>{key.invoice_code}</p>
                                </td>
                                <td>
                                    <p>{key.extra.total}</p>
                                </td>
                                <td>
                                    <p>{moment(key.created_at).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    <p>{key.is_paid === 1 ? 'پرداخت شده' : 'پرداخت نشده'}</p>
                                </td>
                                <td>
                                    <a href={`https://irgogps.com/payment/invoice/${key.invoice_code}`} target='blank'>
                                        <button className='lightBlueBtn'>مشاهده فاکتور</button>
                                    </a>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    {this.state.data.length > 0 ? this.state.data.map((key, index) =>
                        <div className="mobile-info-card row">
                            <div className="col-xs-12 text-center">
                                <label>شماره فاکتور: </label>
                                <p>{key.invoice_code}</p>
                            </div>
                            <div className="col-xs-12 text-center">
                                <label>مبلغ کل: </label>
                                <p>{key.extra.total}</p>
                            </div>
                            <div className="col-xs-12 text-center">
                                <label>تاریخ صدور: </label>
                                <p style={{direction: 'ltr'}}>{moment(key.created_at).format("jYYYY/jMM/jDD")}</p>
                            </div>
                            <div className="col-xs-12 text-center">
                                <label>وضعیت: </label>
                                <p style={{color: key.is_paid === 1 ? 'green' : 'red'}} >{key.is_paid === 1 ? 'پرداخت شده' : 'پرداخت نشده'}</p>
                            </div>

                            <div className="col-xs-12 card-btn-div" style={{padding: '0 10px'}}>
                                <div className="col-xs-12">
                                    <a  href={`https://irgogps.com/payment/invoice/${key.invoice_code}`} target='blank'>
                                        <button className='lightBlueBtn' style={{width: '100%'}}>مشاهده فاکتور
                                        </button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    ): <div className="mobile-info-card row"><p>در حال حاضر تراکنشی وجود ندارد.</p></div>
                    }
                </div>
            </div>
        );
    }
}

export default withAlert(Transactions);