import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import {withAlert} from 'react-alert'
import axios from 'axios';
import * as Cookies from 'js-cookie';
import moment from 'moment-jalaali';
import Pagination from "react-js-pagination";
import {config} from '../../config';
import ReactToPrint from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

let timeOut;

class UsersList extends Component {

    constructor(){
        super();

        this.state={
            selected: 0,
            data: [],
            currPage:1,
            totalItem:0,
            isActionsOpen: false,
            isEditUserOpen:false,
            actionsData: [],
            selectedDataId:[],
            actionsCurrPage:1,
            actionsTotalItem:0,
            currActionsUserId:0,
            roles:[],
            editingUser:{},
            selectedRoles:[],
            userStatus:'',
            isAddNewUserOpen:false,
            isDeleteUserOpen:false,
            isResetPassOpen:false,
            resetPassId:0,
            deletingUserId:0,
            fUserName:'',
            fMcode:'',
            fMobile:'',
            fImei:'',
            fRole:'',
            fStatus:''
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.getDataPerPage = this.getDataPerPage.bind(this);
        this.openActions = this.openActions.bind(this);
        this.closeActions = this.closeActions.bind(this);
        this.loadActions = this.loadActions.bind(this);
        this.loadNewActionsPage = this.loadNewActionsPage.bind(this);
        this.openEditUser = this.openEditUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeEditUser = this.closeEditUser.bind(this);
        this.updateEditedUser = this.updateEditedUser.bind(this);
        this.selectRole = this.selectRole.bind(this);
        this.addNewUser = this.addNewUser.bind(this);
        this.closeAddNewUser = this.closeAddNewUser.bind(this);
        this.openUserProfile = this.openUserProfile.bind(this);
        this.openUserTracers = this.openUserTracers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.resetPass = this.resetPass.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
    }

    handleSelect(id){
        let array = this.state.selectedDataId;
        if (array.includes(id)) {
            let index = array.indexOf(id);
            if (index > -1) {
                array.splice(index, 1);
            }
        } else {
            array.push(id);
        }
        this.setState({selectedDataId: array});
    }

    getDataPerPage(page){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/users?page='+page+'&name='+this.state.fUserName+'&mcode='+this.state.fMcode+'&mobile='+this.state.fMobile+'&imei='+this.state.fImei+'&role='+this.state.fRole+'&status='+this.state.fStatus,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        let array= [];
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                for (let i in resp.data.data) {
                    array.push(resp.data.data[i].id);
                }
                this.setState({
                    data: resp.data.data , totalItem: resp.data.total ,currPage: page, selectedDataId: array
                });
                console.log(resp.data.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    openActions(id){
        this.setState({isActionsOpen: true, currActionsUserId: id});
        this.loadActions(1,id);

    }
    loadNewActionsPage(page){
        this.loadActions(page,this.state.currActionsUserId);
    }
    loadActions(page,id){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/users/'+id+'/logs?page='+page,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    actionsData: resp.data.data , actionsTotalItem: resp.data.total , actionsCurrPage: page
                });
                console.log(resp.data.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    closeActions(){
        this.setState({isActionsOpen: false , actionsData:[]});
    }
    openEditUser(id){
        this.setState({isEditUserOpen: true});

        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/users/'+id,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    editingUser: resp.data.data , userStatus: resp.data.data.status
                });
                for(let i in resp.data.data.roles){
                    let array=[];
                    array.push(resp.data.data.roles[i].id);
                    this.setState({selectedRoles: array});
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }
    closeEditUser(){
        this.setState({isEditUserOpen: false , editingUser:{}});
    }
    handleChange(e){
        this.setState({userStatus: e.target.value})
    }
    selectRole(id){
        let array = this.state.selectedRoles;
        if(array.includes(id)){
            let index=array.indexOf(id);
            array.splice(index,1);
        }
        else{
            array.push(id);
        }
        this.setState({selectedRoles: array});
    }
    updateEditedUser(){
        let data = {
            'mobile' : document.getElementById('user-phone').value ,
            'password': document.getElementById('user-pass').value ,
            'mcode': document.getElementById('user-mcode').value ,
            'name' : document.getElementById('user-name').value,
            'email':'',
            'status':this.state.userStatus,
            'role_ids':this.state.selectedRoles
        };
        let req = {
            method: 'put',
            url: config.baseUrl + 'api/v1/admins/users/'+this.state.editingUser.id,
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        console.log(data);
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({isEditUserOpen: false , editingUser:{}});
                this.props.alert.success('ثبت با موفقیت انجام شد.');
                this.getDataPerPage(this.state.currPage);

            }

        }).catch((err) => {
            console.log(err);
        });
    }
    addNewUser(){
        let data = {
            'mobile' : document.getElementById('user-phone').value ,
            'password': document.getElementById('user-pass').value ,
            'mcode': document.getElementById('user-mcode').value ,
            'name' : document.getElementById('user-name').value,
            'email':'',
            'role_ids':this.state.selectedRoles
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/admins/users',
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        console.log(data);
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({isAddNewUserOpen: false});
                this.props.alert.success('ثبت با موفقیت انجام شد.')
            }

        }).catch((err) => {
            console.log(err);
        });
    }
    closeAddNewUser(){
        this.setState({isAddNewUserOpen: false});
    }
    openUserProfile(id){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/users/'+id+'/token',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                window.open('/#token='+resp.data.token,'_blank');
            }
        }).catch(err => {
            console.log(err);
        });
    }
    openUserTracers(id){
        this.props.history.push('/tracermanager#id='+id);
    }
    deleteUser(){
        let req = {
            method: 'delete',
            url: config.baseUrl + 'api/v1/admins/users/'+this.state.deletingUserId,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    isDeleteUserOpen: false,
                    deletingUserId:0
                });
                this.props.alert.success('ردیاب مورد نظر با موفقیت حذف شد.');
                this.getDataPerPage(this.state.currPage);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    resetPass(){
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/admins/users/'+this.state.resetPassId+'/reset-password',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    isResetPassOpen: false,
                    resetPassId:0
                });
                this.props.alert.success('ریست با موفقیت انجام شد.');
            }
        }).catch(err => {
            console.log(err);
        });
    }
    handleFilter(e){
        this.setState({[e.target.name] : e.target.value});
        clearTimeout(timeOut);
        timeOut = setTimeout(()=>{this.getDataPerPage(1)},500);

    }
    clearFilter(){
        this.setState({
            fUserName: '',
            fImei: '',
            fMcode:'',
            fMobile:'',
            fRole:'',
            fStatus:''
        });
        setTimeout(()=>{this.getDataPerPage(1)},300);

    }
    componentDidMount(){
        this.getDataPerPage(1);

        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/roles',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    roles: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }


    render() {
        return (
            <div className='content col-lg-12 col-md-12'>
                <div className='content-head'>
                    <ReactToPrint
                        trigger={() => <button title='پرینت' className='blueBtn' style={{float: 'left'}}><i
                            className='icon-print'/></button>}
                        content={() => this.printTbl}

                    />

                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="blueBtn"
                        table="exelTable"
                        filename="usersList"
                        sheet="tablexls"
                        buttonText={<i className='icon-excel'/>}/>
                    {window.localStorage.getItem('prmsn').split(',').includes("1")?
                    <button onClick={()=>{this.setState({isAddNewUserOpen: true})}} className='blueBtn'>افزودن کاربر</button>
                        :''}
                    <div className='filter-div user-filter col-lg-12 col-md-12'>
                        <p style={{display:'block' , fontSize:'12px'}}>جستجو بر اساس:</p>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fUserName' className='input'  type="text" placeholder={'نام و نام خانوادگی'} value={this.state.fUserName}/>
                        </div>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fMcode' className='input'  type="text" placeholder={'کد ملی'} value={this.state.fMcode}/>
                        </div>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fMobile' className='input'  type="text" placeholder={'شماره موبایل'} value={this.state.fMobile}/>
                        </div>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fImei' className='input'  type="text" placeholder={'IMEI'} value={this.state.fImei}/>
                        </div>
                        <div className='input-group'>
                            <select name='fRole' onChange={this.handleFilter}>
                                <option value={''} selected={this.state.fRole === '' ? true :false}>گروه دسترسی</option>
                                {this.state.roles.map((key)=>
                                    <option selected={this.state.fRole === key.id ? true :false} value={key.id}>{key.name}</option>
                                )}
                            </select>
                        </div>
                        <div className='input-group'>
                            <select name='fStatus' onChange={this.handleFilter}>
                                <option selected={this.state.fStatus === '' ? true :false} value={''} >وضعیت</option>
                                <option selected={this.state.fStatus === 1 ? true :false} value={1}>فعال</option>
                                <option selected={this.state.fStatus === 0 ? true :false} value={0}>غیر فعال </option>
                            </select>
                        </div>
                        <button onClick={this.clearFilter} className='cancelBtn blueBtn' title='حذف فیلتر ها'>
                            <i />
                        </button>
                    </div>
                </div>
                <div className='content-body'>
                    <table className='tbl user-tbl'>
                        <thead>
                        <tr>
                            <th><img src={require('../../image/tick.png')} /></th>
                            <th>وضعیت</th>
                            <th>نام و نام خانوادگی ، id</th>
                            <th>IMEI ردیاب ها</th>
                            <th>کد ملی</th>
                            <th>شماره موبایل</th>
                            <th>تاریخ ایجاد</th>
                            <th>گروه دسترسی</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key)=>
                            <tr>
                                <td>
                                    <button onClick={()=>{this.handleSelect(key.id)}}>
                                     <span className='checkbox'>
                                         <img src={require(`../../image/${this.state.selectedDataId.includes(key.id) ? 'checked' : 'uncheck'}.svg`)}/>
                                     </span>
                                    </button>
                                </td>
                                <td>
                                    <img src={require(`../../image/Ellipse-${key.status}@2x.png`)} />
                                </td>
                                <td>
                                    <p>{key.name}</p>
                                    <p style={{color:'#B5B5B5'}}>{key.id}</p>
                                </td>
                                <td>
                                    {key.tracers.map((Tkey) =>
                                        <p style={{color: Tkey.data_color}}>{Tkey.imei}</p>)}
                                </td>
                                <td>
                                    <p>{key.mcode}</p>
                                </td>
                                <td>
                                    <p>{key.mobile}</p>
                                </td>
                                <td>
                                    <p>{moment(key.created_at).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    {key.roles.map((role)=>
                                        <p>{role.name}</p>
                                    )}
                                </td>
                                <td>
                                    <button className='edit-btn' title='اقدامات کاربر' onClick={()=>{this.openActions(key.id)}}>
                                        <i className='icon-chart'/>
                                    </button>
                                    {window.localStorage.getItem('prmsn').split(',').includes("9")?
                                    <button onClick={()=>{this.openUserTracers(key.id)}} className='edit-btn' title='مشاهده ردیاب های کاربر'>
                                        <i className='icon-signal'/>
                                    </button>:''}
                                    {key.roles[0].slug === 'client' && window.localStorage.getItem('prmsn').split(',').includes("5") ?
                                        <button onClick={() => {this.openUserProfile(key.id)}} className='edit-btn' title='مشاهده صفحه ی کاربر'>
                                            <i className='icon-avatar'/>
                                        </button>
                                        : ''
                                    }
                                    {window.localStorage.getItem('prmsn').split(',').includes("2")?
                                    <button onClick={()=>{this.openEditUser(key.id)}} className='edit-btn' title='ویرایش'>
                                        <i className='icon-edit'/>
                                    </button> : ''}
                                    {window.localStorage.getItem('prmsn').split(',').includes("2")?
                                    <button onClick={()=>{this.setState({isResetPassOpen: true , resetPassId: key.id})}} className='conf-btn' title='ریست کردن رمز عبور'>
                                        <i className='icon-password'/>
                                    </button>:''}
                                    {window.localStorage.getItem('prmsn').split(',').includes("4")?
                                    <button onClick={()=>{this.setState({isDeleteUserOpen: true , deletingUserId: key.id})}} className='delete-btn' title='حذف'>
                                        <i className='icon-delete'/>
                                    </button>:''}

                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <Pagination
                        activePage={this.state.currPage}
                        itemsCountPerPage={20}
                        totalItemsCount={this.state.totalItem}
                        pageRangeDisplayed={5}
                        onChange={this.getDataPerPage}
                    />
                </div>
                <Modal
                    isOpen={this.state.isActionsOpen}
                    onRequestClose={this.closeActions}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>اقدامات کاربر</p>
                    </div>
                    <div className='user-actions' style={{maxHeight:'400px' , overflow:'scroll'}}>
                        <ul>
                            {this.state.actionsData.map((key)=>
                                <li>
                                    <p style={{textAlign: 'right'}}>{key.description}</p>
                                    <p style={{textAlign: 'left' , direction:'ltr'}}>{moment(key.created_at).format("jYYYY/jMM/jDD HH:mm")}</p>
                                </li>
                            )}
                        </ul>
                    </div>
                    <Pagination
                        activePage={this.state.actionsCurrPage}
                        itemsCountPerPage={20}
                        totalItemsCount={this.state.actionsTotalItem}
                        pageRangeDisplayed={5}
                        onChange={this.loadNewActionsPage}
                    />
                </Modal>
                <Modal
                    isOpen={this.state.isEditUserOpen}
                    onRequestClose={this.closeEditUser}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>ویرایش اطلاعات کاربر</p>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>نام و نام خانوادگی</h5>
                            <div className='input-group'>
                                <input id='user-name' className='input' style={{textAlign:'right' , direction:'rtl'}} defaultValue={this.state.editingUser.name}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>کد ملی</h5>
                            <div className='input-group'>
                                <input id='user-mcode' className='input num' placeholder='12...' defaultValue={this.state.editingUser.mcode}/>
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>شماره موبایل</h5>
                            <div className='input-group'>
                                <input id='user-phone'  className='input num' placeholder='12...' defaultValue={this.state.editingUser.mobile}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>رمز عبور</h5>
                            <div className='input-group'>
                                <input id='user-pass'  className='input' type={'password'} placeholder='*****'/>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>وضعیت</h5>
                        <div className='input-group'>
                            <select onChange={this.handleChange}>
                                <option disabled default>وضعیت</option>
                                <option selected={this.state.userStatus=== 1 ? true : false} value={1}>فعال</option>
                                <option selected={this.state.userStatus=== 0 ? true : false} value={0}>غیر فعال </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <p style={{display:'inline-block' , fontSize:'13px'}}>گروه های دسترسی:</p>
                        <div style={{display:'inline-block' , padding:'0 10px'}}>
                            {this.state.roles.map((key)=>
                                <button onClick={()=>this.selectRole(key.id)}>
                                    <p style={{display:'inline-block' , fontSize:'11px' , marginLeft:'5px'}}>{key.name}</p>
                                    <span className='checkbox'>
                                     <img src={require(`../../image/${this.state.selectedRoles.includes(key.id) ? 'checked' : 'uncheck'}.svg`)}/>
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                    <button onClick={this.updateEditedUser} className='blueBtn btn' style={{padding: '15px 80px'}}>ذخیره تغییرات</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={this.closeEditUser}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isAddNewUserOpen}
                    onRequestClose={this.closeAddNewUser}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن کاربر جدید</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات کاربر جدید را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>نام و نام خانوادگی</h5>
                            <div className='input-group'>
                                <input id='user-name' className='input' />
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>کد ملی</h5>
                            <div className='input-group'>
                                <input id='user-mcode' className='input num' placeholder='12...'/>
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>شماره موبایل</h5>
                            <div className='input-group'>
                                <input id='user-phone'  className='input num' placeholder='12...'/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>رمز عبور</h5>
                            <div className='input-group'>
                                <input id='user-pass'  className='input' type={'password'} placeholder='*****'/>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>وضعیت</h5>
                        <div className='input-group'>
                            <select onChange={this.handleChange}>
                                <option disabled default selected>وضعیت</option>
                                <option >فعال</option>
                                <option >غیر فعال </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <p style={{display:'inline-block' , fontSize:'13px'}}>گروه های دسترسی:</p>
                        <div style={{display:'inline-block' , padding:'0 10px'}}>
                            {this.state.roles.map((key)=>
                                <button onClick={()=>this.selectRole(key.id)}>
                                    <p style={{display:'inline-block' , fontSize:'11px' , marginLeft:'5px'}}>{key.name}</p>
                                    <span className='checkbox'>
                                     <img src={require(`../../image/${this.state.selectedRoles.includes(key.id) ? 'checked' : 'uncheck'}.svg`)}/>
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                    <button onClick={this.addNewUser} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت نام</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={this.closeAddNewUser}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isDeleteUserOpen}
                    onRequestClose={()=>{
                        this.setState({isDeleteUserOpen:false, deletingUserId:0})
                    }}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>حذف کاربر</p>
                    </div>
                    <p style={{fontSize: '14px' , textAlign: 'center', marginBottom: '40px' }}>آیا از حذف این کاربر اطمینان دارید؟</p>
                    <button onClick={this.deleteUser} className='blueBtn btn' style={{padding: '15px 85px'}}>بله</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 75px'}} onClick={()=>{
                        this.setState({isDeleteUserOpen:false, deletingUserId:0})
                    }}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isResetPassOpen}
                    onRequestClose={()=>{
                        this.setState({isResetPassOpen:false, resetPassId:0})
                    }}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>ریست پسورد</p>
                    </div>
                    <p style={{fontSize: '14px' , textAlign: 'center', marginBottom: '40px' }}>آیا از ریست کردن پسورد اطمینان دارید؟</p>
                    <button onClick={this.resetPass} className='blueBtn btn' style={{padding: '15px 85px'}}>بله</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 75px'}} onClick={()=>{
                        this.setState({isResetPassOpen:false, resetPassId:0})
                    }}>انصراف</button>
                </Modal>
                <div className='hide'>
                    <table ref={el => (this.printTbl = el)} id={'exelTable'} className='brintTable'>
                        <thead>
                        <tr>
                            <th>نام و نام خانوادگی ، id</th>
                            <th>IMEI ردیاب ها</th>
                            <th>کد ملی</th>
                            <th>شماره موبایل</th>
                            <th>تاریخ ایجاد</th>
                            <th>گروه دسترسی</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key)=>
                            <tr style={{display: `${this.state.selectedDataId.includes(key.id) ? 'table-row' : 'none'}`}}>
                                <td>
                                    <p>{key.name}</p>
                                    <p style={{color:'#B5B5B5'}}>{key.id}</p>
                                </td>
                                <td>
                                    {key.tracers.map((Tkey) =>
                                        <p style={{color: Tkey.data_color}}>{Tkey.imei}</p>)}
                                </td>
                                <td>
                                    <p>{key.mcode}</p>
                                </td>
                                <td>
                                    <p>{key.mobile}</p>
                                </td>
                                <td>
                                    <p>{moment(key.created_at).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    {key.roles.map((role)=>
                                        <p>{role.name}</p>
                                    )}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }
}

export default withAlert(UsersList);