import React, {Component} from 'react';
import { Link , Redirect } from 'react-router-dom';
import {withAlert} from 'react-alert'
import axios from 'axios';
import Timer from "./Timer";
import * as Cookies from 'js-cookie';
import {config} from '../config';

class Resetpass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            percentage: 1,
            isCorrect : false,
            phone: ''
        };
        this.time = 3000;
        this.resetPass = this.resetPass.bind(this);
        this.hideTimer = this.hideTimer.bind(this);
        this.timer = this.timer.bind(this);
    }

    timer(){
        this.time = this.time- 1;
        let percent = (this.time / 3000)*100;
        this.setState({
            percentage: percent
        });
        if(this.state.percentage === 0 ){
            clearInterval(this.interval)
        }
    }
    resetPass() {

        let data ={
            'mobile' : this.state.phone,
            'password': document.getElementById('pass').value,
            'forget_token': document.getElementById('code').value
        };

        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/auth/password/reset',
            data: data
        };
        axios(req
        ).then((resp) => {

            if(resp.data.status) {
                this.setState({
                    isCorrect: true
                });
            }
        }).catch(err => {
            console.log(err);
        });
        console.log(data);

    }


    hideTimer(){
        this.time=3000;
        clearInterval(this.interval)
    }

    componentDidMount(){
        this.interval = setInterval(this.timer, 10);
        this.setState({phone: Cookies.get('phone')})

    }

    render() {
        return (
            <div className='App form-group'>
                <div className='img-div col-lg-9 col-md-8 col-sm-6 hidden-xs'>
                        <span>
                        </span>
                </div>
                <div className='left-div col-lg-3 col-md-4 col-sm-6 col-xs-12'>

                        <div>
                            <h5>فراموشی رمز عبور</h5>
                            <p className='blue'>کد ارسال شده به شماره موبایل خود را وارد کنید</p>
                            <span className='line'></span>
                            <form>
                                <div className='input-div '>
                                    <h5>کد ارسال شده</h5>
                                    <div className='input-group'>
                                        <i className='icon-code inputIcon'></i>
                                        <input id='code' name='code' className='input num' placeholder={'77...'}/>
                                    </div>
                                </div>
                                <div className='input-div '>
                                    <h5>رمز عبور جدید</h5>
                                    <div className='input-group'>
                                        <i className='icon-password inputIcon'></i>
                                        <input id='pass' type='password' className='input num' placeholder={'****'}/>
                                    </div>
                                </div>
                            </form>
                            {this.state.percentage === 0 ?
                                <div className='resend'>
                                    <Link to='/Forgetpass'>
                                    <button onClick={this.hideTimer}><p>ارسال مجدد</p></button>
                                    </Link>
                                </div> : ''}
                            <div className='button-div blueBtn'>
                                {this.state.isCorrect ? <Redirect to='/login' /> :''}
                                <button onClick={this.resetPass}><h5>تایید</h5></button>
                            </div>
                            <div className='button-div pinkBtn'>
                                <Link to={'/'}><button onClick={this.hideTimer}><h5>انصراف</h5></button></Link>
                            </div>
                            <div style={{textAlign: "center" , padding: " 20px 0"}}>
                                <Timer
                                    strokeWidth="5"
                                    sqSize="100"
                                    percentage={this.state.percentage}
                                    time = {this.time}
                                />
                            </div>

                        </div>
                </div>
            </div>
        );
    }
}

export default Resetpass;