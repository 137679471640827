import React, {Component} from 'react';

class Alert extends Component{

    constructor(props){
        super(props);

        this.type = 0;
        this.color = '';

        this.changeType = this.changeType.bind(this);
    }

    changeType(type , color){
        this.type = type;
        this.color = color;
    }

    render(){

        const { options, message, close } = this.props;

        return(
            <div className='alert'>
                {options.type === 'info' && this.changeType(2, 'orange')}
                {options.type === 'success' && this.changeType(1 , 'lightgreen')}
                {options.type === 'error' && this.changeType(0 , 'lightred')}
                <div>
                    <p style={{color: this.color}}>{this.type === 0 ? 'خطا!' : this.type === 1 ? 'موفقیت آمیز!' : 'هشدار!'}</p>
                    <p>{message}</p>
                </div>
                <button onClick={close}>
                    <img  src={require(`../image/${this.type === 0 ? 'error' : this.type === 1 ? 'success' : 'warning'}.png`)} />
                </button>
            </div>
        )
    }
}

export default Alert;