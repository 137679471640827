import React, { Component } from "react";
import Modal from "react-modal";
import axios from "axios";
import { withAlert } from "react-alert";
import * as Cookies from "js-cookie";
import moment from "moment-jalaali";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import { DatetimePickerTrigger } from "imrc-datetime-picker";
import Pagination from "react-js-pagination";
import { config } from "../../config";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Loading from "../Loading";

let timeOut;

class TracerManager extends Component {
  constructor() {
    super();

    const defaultMoment = moment();

    this.state = {
      selected: 0,
      data: [],
      currPage: 1,
      totalItem: 0,
      isActionsOpen: false,
      isAdminAddTrackerOpen: false,
      isAdminMultiAddTrackerOpen: false,
      isAdminOtaAddTrackerOpen: false,
      isAdminEditTracerOpen: false,
      isEditExpireOpen: false,
      isRemoveArchiveOpen: false,
      isDeleteTracerOpen: false,
      isShowResultOta: false,
      actionsData: [],
      selectedDataId: [],
      actionsCurrPage: 1,
      actionsTotalItem: 0,
      currActionsUserId: 0,
      tracerTypes: [],
      singleCreatedAt: defaultMoment,
      manufacturedAt: moment(),
      expiresAt: moment(),
      archiveDate: moment(),
      editingTracer: {},
      editingTracerId: 0,
      selectedType: 0,
      status: "",
      sentData: [],
      deliveredData: [],
      fSerial: "",
      fImei: "",
      fSimNumber: "",
      fTypeId: "",
      loading: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.getTracerDataPerPage = this.getTracerDataPerPage.bind(this);
    this.storeFile = this.storeFile.bind(this);
    this.closeAdminAddTracker = this.closeAdminAddTracker.bind(this);
    this.closeAdminMultiAddTracker = this.closeAdminMultiAddTracker.bind(this);
    this.closeAdminOtaAddTracker = this.closeAdminOtaAddTracker.bind(this);
    this.afterAdminEditTrackerOpen = this.afterAdminEditTrackerOpen.bind(this);
    this.handleManufacturDate = this.handleManufacturDate.bind(this);
    this.handleExpireAt = this.handleExpireAt.bind(this);
    this.updateEditTracer = this.updateEditTracer.bind(this);
    this.openEditExpire = this.openEditExpire.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.updateExpiresAt = this.updateExpiresAt.bind(this);
    this.removeArchive = this.removeArchive.bind(this);
    this.deleteTracer = this.deleteTracer.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.addTracersWithOta = this.addTracersWithOta.bind(this);
    this.AddSingleTracer = this.AddSingleTracer.bind(this);
    this.getDeliveryListOta = this.getDeliveryListOta.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  handleSelect(id) {
    let array = this.state.selectedDataId;
    if (array.includes(id)) {
      let index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    this.setState({ selectedDataId: array });
  }

  getTracerDataPerPage(page) {
    let req = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/admins/tracers?page=" +
        page +
        "&serial=" +
        this.state.fSerial +
        "&imei=" +
        this.state.fImei +
        "&sim_number=" +
        this.state.fSimNumber +
        "&type_id=" +
        this.state.fTypeId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    let array = [];
    axios(req)
      .then((resp) => {
        for (let i in resp.data.data) {
          array.push(resp.data.data[i].id);
        }
        if (resp.data.status === 1) {
          this.setState({
            data: resp.data.data,
            totalItem: resp.data.total,
            currPage: page,
            selectedDataId: array,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  storeFile() {
    console.log(document.getElementById("excelFile"));
  }

  closeAdminAddTracker() {
    this.setState({ isAdminAddTrackerOpen: false });
  }
  closeAdminMultiAddTracker() {
    this.setState({ isAdminMultiAddTrackerOpen: false });
  }
  closeAdminOtaAddTracker() {
    this.setState({ isAdminOtaAddTrackerOpen: false });
  }
  afterAdminEditTrackerOpen() {
    this.setState({ loading: true });
    let req = {
      method: "get",
      url:
        config.baseUrl + "api/v1/admins/tracers/" + this.state.editingTracerId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            editingTracer: resp.data.data,
            manufacturedAt: moment(resp.data.data.manufactured_at),
            status: resp.data.data.status,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleManufacturDate(moment) {
    this.setState({ manufacturedAt: moment });
  }
  handleExpireAt(moment) {
    this.setState({ expiresAt: moment });
  }
  updateExpiresAt() {
    let data = this.state.editingTracer;
    data.expires_at = this.state.expiresAt.locale("en").format("YYYY/MM/DD");
    let req = {
      method: "put",
      url:
        config.baseUrl + "api/v1/admins/tracers/" + this.state.editingTracerId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({ isEditExpireOpen: false, editingTracer: {} });
          this.props.alert.success("ثبت با موفقیت انجام شد.");
          this.getTracerDataPerPage(this.state.currPage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.state.expiresAt.locale("fa");
  }
  updateEditTracer() {
    let data = {
      manufacturer: document.getElementById("manufacturer").value,
      manufactured_at: this.state.manufacturedAt
        .locale("en")
        .format("YYYY/MM/DD"),
      type_id: this.state.selectedType,
      status: this.state.status,
    };
    let req = {
      method: "put",
      url:
        config.baseUrl + "api/v1/admins/tracers/" + this.state.editingTracerId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({ isAdminEditTracerOpen: false, editingTracer: {} });
          this.props.alert.success("ثبت با موفقیت انجام شد.");
          this.getTracerDataPerPage(this.state.currPage);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
    this.state.manufacturedAt.locale("fa");
  }
  openEditExpire(id) {
    this.setState({ isEditExpireOpen: true, editingTracerId: id });

    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/admins/tracers/" + id,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          if (resp.data.data.expires_at !== null) {
            this.setState({
              editingTracer: resp.data.data,
              expiresAt: moment(resp.data.data.expires_at),
            });
          } else {
            this.props.alert.info("این ردیاب تاریخ انقضا ندارد.");
            this.setState({ isEditExpireOpen: false });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleTypeChange(e) {
    this.setState({ selectedType: e.target.value });
  }
  removeArchive() {
    let req = {
      method: "delete",
      url:
        config.baseUrl +
        "api/v1/admins/tracers/delete-archive?id=" +
        this.state.editingTracerId +
        "&start_date=" +
        this.state.archiveDate.utc().locale("en").format("YYYY-MM-DD HH:mm:00"),
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            isRemoveArchiveOpen: false,
            deletingUserId: 0,
          });
          this.props.alert.success(
            "سابقه موقعیت ردیاب مورد نظر با موفقیت حذف شد."
          );
          this.getTracerDataPerPage(this.state.currPage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  deleteTracer() {
    let req = {
      method: "delete",
      url:
        config.baseUrl + "api/v1/admins/tracers/" + this.state.editingTracerId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            isDeleteTracerOpen: false,
            editingTracerId: 0,
          });
          this.props.alert.success("ردیاب مورد نظر با موفقیت حذف شد.");
          this.getTracerDataPerPage(this.state.currPage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleStatusChange(e) {
    this.setState({ status: e.target.value });
  }
  addTracersWithOta() {
    let data = {
      command: document.getElementById("command").value,
      type_id: this.state.selectedType,
    };
    console.log(data);
    let req = {
      method: "post",
      url: config.baseUrl + "api/v1/admins/tracers/ota/send",
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({ selectedType: 0, sentData: resp.data.data.imeis });
          setTimeout(() => {
            this.getDeliveryListOta(resp.data.data.serial);
          }, 200000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getDeliveryListOta(serial) {
    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/admins/tracers/ota/delivery/" + serial,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    console.log(req.url);
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            isAdminOtaAddTrackerOpen: false,
            isShowResultOta: true,
            selectedType: 0,
            deliveredData: resp.data.data.imeis,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  AddSingleTracer() {
    let data = {
      imei: document.getElementById("Add_imei").value,
      manufacturer: document.getElementById("Add_Manufacturer").value,
      manufactured_at: this.state.singleCreatedAt
        .locale("en")
        .format("YYYY/MM/DD"),
      type_id: this.state.selectedType,
    };
    let req = {
      method: "post",
      url: config.baseUrl + "api/v1/admins/tracers/",
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({ isAdminAddTrackerOpen: false });
          this.props.alert.success("ثبت با موفقیت انجام شد.");
        }
      })
      .catch((err) => {
        this.props.alert.error(
          { err }.err.response.data.errors[0].field +
            " " +
            { err }.err.response.data.errors[0].message
        );
      });
    this.state.manufacturedAt.locale("fa");
  }
  handleFilter(e) {
    this.setState({ [e.target.name]: e.target.value });
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      this.getTracerDataPerPage(1);
    }, 500);
  }
  clearFilter() {
    this.setState({
      fSerial: "",
      fImei: "",
      fSimNumber: "",
      fTypeId: "",
    });
    setTimeout(() => {
      this.getTracerDataPerPage(1);
    }, 300);
  }
  componentDidMount() {
    let url = window.location.href;
    let id;
    if (url.match(/\#(?:id)\=([\S\s]*?)$/) != null) {
      id = url.match(/\#(?:id)\=([\S\s]*?)$/)[1];
      let req = {
        method: "get",
        url: config.baseUrl + "api/v1/admins/tracers/?user_id=" + id,
        headers: {
          Authorization: "Bearer " + config.token,
        },
      };
      console.log(req.url);
      axios(req)
        .then((resp) => {
          if (resp.data.status === 1) {
            this.setState({
              data: resp.data.data,
              totalItem: 0,
              currPage: 0,
              loading: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (url.match(/\#(?:id)\=([\S\s]*?)$/) == null) {
      this.getTracerDataPerPage(1);
    }

    let typeReq = {
      method: "get",
      url: config.baseUrl + "api/v1/admins/tracers/types",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(typeReq)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            tracerTypes: resp.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const shortcuts = {
      امروز: moment(),
      دیروز: moment().subtract(1, "days"),
    };
    return (
      <div className="content col-lg-12 col-md-12">
        <Loading loading={this.state.loading} />
        <div className="content-head">
          <ReactToPrint
            trigger={() => (
              <button
                title="پرینت"
                className="blueBtn"
                style={{ float: "left" }}
              >
                <i className="icon-print" />
              </button>
            )}
            content={() => this.printTbl}
          />

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="blueBtn"
            table="exelTable"
            filename="tracerManagerList"
            sheet="tablexls"
            buttonText={<i className="icon-excel" />}
          />
          {window.localStorage.getItem("prmsn").split(",").includes("7") ? (
            <button
              onClick={() => {
                this.setState({ isAdminMultiAddTrackerOpen: true });
              }}
              className="blueBtn"
            >
              <i className="icon-download" title="افرودن گروهی ردیاب" />
            </button>
          ) : (
            ""
          )}
          {window.localStorage.getItem("prmsn").split(",").includes("7") ? (
            <button
              onClick={() => {
                this.setState({ isAdminOtaAddTrackerOpen: true });
              }}
              className="blueBtn"
            >
              <i className="icon-terminal" title="ارسال گروهی دستور" />
            </button>
          ) : (
            ""
          )}
          {window.localStorage.getItem("prmsn").split(",").includes("7") ? (
            <button
              onClick={() => {
                this.setState({ isAdminAddTrackerOpen: true });
              }}
              className="blueBtn"
            >
              افزودن ردیاب
            </button>
          ) : (
            ""
          )}
          <div className="filter-div user-filter tracer-filter col-lg-8 col-md-8">
            <p style={{ display: "inline-block", fontSize: "12px" }}>
              جستجو بر اساس:
            </p>
            <div className="input-group">
              <input
                onChange={this.handleFilter}
                style={{ textAlign: "right" }}
                name="fSerial"
                className="input"
                type="text"
                placeholder={"شماره سریال"}
                value={this.state.fSerial}
              />
            </div>
            <div className="input-group">
              <input
                onChange={this.handleFilter}
                style={{ textAlign: "right" }}
                name="fSimNumber"
                className="input"
                type="text"
                placeholder={"شماره موبایل"}
                value={this.state.fSimNumber}
              />
            </div>
            <div className="input-group">
              <input
                onChange={this.handleFilter}
                style={{ textAlign: "right" }}
                name="fImei"
                className="input"
                type="text"
                placeholder={"IMEI"}
                value={this.state.fImei}
              />
            </div>
            <div className="input-group">
              <select name="fTypeId" onChange={this.handleFilter}>
                <option
                  selected={this.state.fTypeId === "" ? true : false}
                  value={""}
                >
                  نوع ردیاب
                </option>
                {this.state.tracerTypes.map((key) => (
                  <option
                    selected={this.state.fTypeId === key.id ? true : false}
                    value={key.id}
                  >
                    {key.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={this.clearFilter}
              className="cancelBtn blueBtn"
              title="حذف فیلتر ها"
            >
              <i />
            </button>
          </div>
        </div>
        <div className="content-body">
          <table className="tbl tracer-tbl">
            <thead>
              <tr>
                <th>
                  <img src={require("../../image/tick.png")} />
                </th>
                <th>وضعیت</th>
                <th>عنوان و شماره سریال</th>
                <th>IMEI و کاربر ردیاب</th>
                <th>نوع ردیاب</th>
                <th>کد راه اندازی و سیم کارت</th>
                <th>راننده</th>
                <th>تاریخ نصب</th>
                <th>تاریخ انقضا</th>
                <th>آخرین ارسال</th>
                <th>گزینه ها</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((key) => (
                <tr>
                  <td>
                    <button
                      onClick={() => {
                        this.handleSelect(key.id);
                      }}
                    >
                      <span className="checkbox">
                        <img
                          src={require(`../../image/${
                            this.state.selectedDataId.includes(key.id)
                              ? "checked"
                              : "uncheck"
                          }.svg`)}
                        />
                      </span>
                    </button>
                  </td>
                  <td>
                    <img
                      src={require(`../../image/Ellipse-${key.status}@2x.png`)}
                    />
                  </td>
                  <td>
                    <p>{key.title}</p>
                    <p style={{ color: "#B5B5B5" }}>{key.serial}</p>
                  </td>
                  <td>
                    <p>{key.imei}</p>
                    <p style={{ color: "#B5B5B5" }}>
                      {key.user === null ? "" : key.user.name}
                    </p>
                  </td>
                  <td>
                    <p>{key.type.name}</p>
                  </td>
                  <td>
                    <p>{key.starter}</p>
                    <p style={{ color: "#B5B5B5" }}>{key.sim_number}</p>
                  </td>
                  <td>
                    <p>{key.driver_name}</p>
                  </td>
                  <td>
                    <p>
                      {key.installed_at === null
                        ? ""
                        : moment(key.installed_at).format("jYYYY/jMM/jDD")}
                    </p>
                  </td>
                  <td>
                    <p>
                      {key.expires_at === null
                        ? ""
                        : moment(key.expires_at).format("jYYYY/jMM/jDD")}
                    </p>
                  </td>
                  <td>
                    <p style={{ direction: "ltr" }}>
                      {key.last_sent === null
                        ? ""
                        : moment(key.last_sent).format("jYYYY/jMM/jDD | HH:mm")}
                    </p>
                  </td>
                  <td>
                    {window.localStorage
                      .getItem("prmsn")
                      .split(",")
                      .includes("8") ? (
                      <button
                        className="edit-btn"
                        title="ویرایش"
                        onClick={() => {
                          this.setState({
                            isAdminEditTracerOpen: true,
                            editingTracerId: key.id,
                          });
                        }}
                      >
                        <i className="icon-edit" />
                      </button>
                    ) : (
                      ""
                    )}
                    {window.localStorage
                      .getItem("prmsn")
                      .split(",")
                      .includes("8") ? (
                      <button
                        className="edit-btn"
                        title="ویرایش تاریخ انقضا"
                        onClick={() => {
                          this.openEditExpire(key.id);
                        }}
                      >
                        <i className="calender" />
                      </button>
                    ) : (
                      ""
                    )}
                    {window.localStorage
                      .getItem("prmsn")
                      .split(",")
                      .includes("11") ? (
                      <button
                        className="delete-btn"
                        title="حذف آرشیو"
                        onClick={() => {
                          this.setState({
                            isRemoveArchiveOpen: true,
                            editingTracerId: key.id,
                          });
                        }}
                      >
                        <i className="icon-refresh" />
                      </button>
                    ) : (
                      ""
                    )}
                    {window.localStorage
                      .getItem("prmsn")
                      .split(",")
                      .includes("10") ? (
                      <button
                        className="delete-btn"
                        title="حذف"
                        onClick={() => {
                          this.setState({
                            isDeleteTracerOpen: true,
                            editingTracerId: key.id,
                          });
                        }}
                      >
                        <i className="icon-delete" />
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            activePage={this.state.currPage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.totalItem}
            pageRangeDisplayed={5}
            onChange={this.getTracerDataPerPage}
          />
        </div>
        <Modal
          isOpen={this.state.isAdminAddTrackerOpen}
          onRequestClose={this.closeAdminAddTracker}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>افزودن ردیاب جدید</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            اطلاعات ردیاب جدید را وارد کنید.
          </p>
          <div className="half">
            <div className="input-div ">
              <h5>کد IMEI</h5>
              <div className="input-group">
                <input id="Add_imei" className="input" />
              </div>
            </div>
            <div className="input-div ">
              <h5>نوع ردیاب</h5>
              <div className="input-group">
                <select onChange={this.handleTypeChange}>
                  <option disabled selected default>
                    نوع ردیاب
                  </option>
                  {this.state.tracerTypes.map((key) => (
                    <option value={key.id}>{key.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="half">
            <div className="input-div ">
              <h5>سازنده</h5>
              <div className="input-group">
                <input id="Add_Manufacturer" className="input" />
              </div>
            </div>
            <div className="input-div ">
              <h5>تاریخ ساخت</h5>
              <DatetimePickerTrigger
                shortcuts={shortcuts}
                moment={this.state.singleCreatedAt}
                onChange={(moment) => {
                  this.setState({ singleCreatedAt: moment });
                }}
                showTimePicker={false}
                position="buttom"
                lang="fa"
                isSolar={true}
              >
                <div className="input-group">
                  <input
                    className="input"
                    type="text"
                    value={this.state.singleCreatedAt.format("jYYYY/jMM/jDD")}
                    readOnly
                  />
                </div>
              </DatetimePickerTrigger>
            </div>
          </div>
          <button
            onClick={this.AddSingleTracer}
            className="blueBtn btn"
            style={{ padding: "15px 100px" }}
          >
            ثبت نام
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 50px" }}
            onClick={this.closeAdminAddTracker}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAdminMultiAddTrackerOpen}
          onAfterOpen={this.afterAdminAddTrackerOpen}
          onRequestClose={this.closeAdminMultiAddTracker}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>افزودن دسته جمعی ردیاب ها</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            اطلاعات ردیاب های جدید را وارد کنید.
          </p>
          <div className="half">
            <div className="input-div ">
              <h5>کد IMEI</h5>
              <div className="input-group">
                <input className="input" />
              </div>
            </div>
            <div className="input-div ">
              <h5>نوع ردیاب</h5>
              <div className="input-group">
                <select onChange={this.handleChange}>
                  {this.state.tracerTypes.map((key) => (
                    <option value={key.id}>{key.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="half">
            <div className="input-div ">
              <h5>سازنده</h5>
              <div className="input-group">
                <input className="input num" placeholder="12..." />
              </div>
            </div>
            <div className="input-div ">
              <h5>تاریخ ساخت</h5>
              <DatetimePickerTrigger
                shortcuts={shortcuts}
                moment={this.state.to}
                onChange={this.handleToDate}
                showTimePicker={false}
                position="buttom"
                lang="fa"
                isSolar={true}
              >
                <div className="input-group">
                  <input
                    className="input"
                    type="text"
                    value={this.state.singleCreatedAt.format("jYYYY/jMM/jDD")}
                    readOnly
                  />
                </div>
              </DatetimePickerTrigger>
            </div>
          </div>
          <div className="input-div " style={{ padding: "0 10px" }}>
            <h5>فایل اکسل</h5>
            <div className="input-group" style={{ backgroundColor: "#fcfcfc" }}>
              <input
                onChange={this.storeFile}
                id="excelFile"
                className="input"
                type="file"
                style={{ direction: "rtl" }}
              />
            </div>
          </div>
          <button className="blueBtn btn" style={{ padding: "15px 100px" }}>
            ثبت نام
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 50px" }}
            onClick={this.closeAdminMultiAddTracker}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAdminOtaAddTrackerOpen}
          onAfterOpen={this.afterAdminAddTrackerOpen}
          onRequestClose={this.closeAdminOtaAddTracker}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>ارسال دستور گروهی</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            اطلاعات ردیاب های جدید را وارد کنید.
          </p>
          <div className="half">
            <div className="input-div ">
              <h5>دستور (کامند)</h5>
              <div className="input-group">
                <input id="command" className="input" />
              </div>
            </div>
          </div>
          <div className="half">
            <div className="input-div ">
              <h5>نوع ردیاب</h5>
              <div className="input-group">
                <select onChange={this.handleTypeChange}>
                  {this.state.tracerTypes.map((key) => (
                    <option value={key.id}>{key.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <button
            onClick={this.addTracersWithOta}
            className="blueBtn btn"
            style={{ padding: "15px 100px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 50px" }}
            onClick={this.closeAdminOtaAddTracker}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAdminEditTracerOpen}
          onAfterOpen={this.afterAdminEditTrackerOpen}
          onRequestClose={() => {
            this.setState({ isAdminEditTracerOpen: false, editingTracerId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>ویرایش ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            اطلاعات ردیاب را ویرایش کنید.
          </p>
          <div className="half">
            <div className="input-div ">
              <h5>کد IMEI</h5>
              <div className="input-group">
                <input
                  id="imei"
                  readOnly
                  className="input"
                  defaultValue={this.state.editingTracer.imei}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>نوع ردیاب</h5>
              <div className="input-group">
                <select onChange={this.handleTypeChange}>
                  {this.state.tracerTypes.map((key) => (
                    <option
                      selected={
                        this.state.editingTracer.type_id === key.id
                          ? true
                          : false
                      }
                      value={key.id}
                    >
                      {key.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="half">
            <div className="input-div ">
              <h5>سازنده</h5>
              <div className="input-group">
                <input
                  id="manufacturer"
                  className="input num"
                  placeholder="12..."
                  defaultValue={this.state.editingTracer.manufacturer}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>تاریخ ساخت</h5>
              <DatetimePickerTrigger
                shortcuts={shortcuts}
                moment={this.state.manufacturedAt}
                onChange={this.handleManufacturDate}
                showTimePicker={false}
                position="buttom"
                lang="fa"
                isSolar={true}
              >
                <div className="input-group">
                  <input
                    className="input"
                    type="text"
                    value={this.state.manufacturedAt.format("jYYYY/jMM/jDD")}
                    readOnly
                  />
                </div>
              </DatetimePickerTrigger>
            </div>
          </div>
          <div className="input-div " style={{ padding: "0 10px" }}>
            <h5>وضعیت</h5>
            <div className="input-group">
              <select onChange={this.handleStatusChange}>
                <option disabled default selected>
                  وضعیت
                </option>
                <option
                  selected={this.state.status === 1 ? true : false}
                  value={1}
                >
                  فعال
                </option>
                <option
                  selected={this.state.status === 0 ? true : false}
                  value={0}
                >
                  غیر فعال{" "}
                </option>
              </select>
            </div>
          </div>
          <button
            className="blueBtn btn"
            style={{ padding: "15px 90px" }}
            onClick={this.updateEditTracer}
          >
            ثبت تغییرات
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 50px" }}
            onClick={() => {
              this.setState({
                isAdminEditTracerOpen: false,
                editingTracerId: 0,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isEditExpireOpen}
          onRequestClose={() => {
            this.setState({ isEditExpireOpen: false, editingTracerId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>ویرایش تاریخ انقضا ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            تاریخ انقضای ردیاب را ویرایش کنید.
          </p>
          <div className="input-div ">
            <h5>تاریخ انقضا</h5>
            <DatetimePickerTrigger
              shortcuts={shortcuts}
              moment={this.state.expiresAt}
              onChange={this.handleExpireAt}
              showTimePicker={false}
              position="buttom"
              lang="fa"
              isSolar={true}
            >
              <div className="input-group">
                <input
                  className="input"
                  type="text"
                  value={this.state.expiresAt.format("jYYYY/jMM/jDD")}
                  readOnly
                />
              </div>
            </DatetimePickerTrigger>
          </div>
          <button
            className="blueBtn btn"
            style={{ padding: "15px 40px" }}
            onClick={this.updateExpiresAt}
          >
            ثبت تغییرات
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 25px" }}
            onClick={() => {
              this.setState({ isEditExpireOpen: false, editingTracerId: 0 });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isRemoveArchiveOpen}
          onRequestClose={() => {
            this.setState({ isRemoveArchiveOpen: false, editingTracerId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>حذف سابقه موقعیت ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            تاریخ مورد نظر را وارد کنید.
          </p>
          <div className="input-div ">
            <h5>از تاریخ</h5>
            <DatetimePickerTrigger
              shortcuts={shortcuts}
              moment={this.state.archiveDate}
              onChange={(moment) => {
                this.setState({ archiveDate: moment });
              }}
              position="buttom"
              lang="fa"
              isSolar={true}
            >
              <div className="input-group">
                <input
                  className="input"
                  type="text"
                  value={this.state.archiveDate.format("jYYYY/jMM/jDD HH:mm")}
                  readOnly
                />
              </div>
            </DatetimePickerTrigger>
          </div>
          <button
            className="blueBtn btn"
            style={{ padding: "15px 40px" }}
            onClick={this.removeArchive}
          >
            ثبت تغییرات
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 25px" }}
            onClick={() => {
              this.setState({ isRemoveArchiveOpen: false, editingTracerId: 0 });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isDeleteTracerOpen}
          onRequestClose={() => {
            this.setState({ isDeleteTracerOpen: false, editingTracerId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>حذف ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            آیا از حذف این ردیاب اطمینان دارید؟
          </p>
          <button
            onClick={this.deleteTracer}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            بله
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 75px" }}
            onClick={() => {
              this.setState({ isDeleteTracerOpen: false, editingTracerId: 0 });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isShowResultOta}
          onRequestClose={() => {
            this.setState({
              isShowResultOta: false,
              sentData: [],
              deliveredData: [],
            });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>نتیجه ارسال دستور</p>
          </div>
          <div className="half" style={{ textAlign: "center" }}>
            <h4>{this.state.deliveredData.length}</h4>
            <p>تعداد دریافتی موفق</p>
          </div>
          <div className="half" style={{ textAlign: "center" }}>
            <h4>{this.state.sentData.length}</h4>
            <p>تعداد ارسال شده</p>
          </div>
          <button className="blueBtn btn" style={{ padding: "15px 85px" }}>
            بله
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 75px" }}
            onClick={() => {
              this.setState({
                isShowResultOta: false,
                sentData: [],
                deliveredData: [],
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <div className="hide">
          <table
            ref={(el) => (this.printTbl = el)}
            id={"exelTable"}
            className="brintTable"
          >
            <thead>
              <tr>
                <th>عنوان و شماره سریال</th>
                <th>IMEI و کاربر ردیاب</th>
                <th>نوع ردیاب</th>
                <th>کد راه اندازی و سیم کارت</th>
                <th>راننده</th>
                <th>تاریخ نصب</th>
                <th>تاریخ انقضا</th>
                <th>آخرین ارسال</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((key) => (
                <tr
                  style={{
                    display: `${
                      this.state.selectedDataId.includes(key.id)
                        ? "table-row"
                        : "none"
                    }`,
                  }}
                >
                  <td>
                    <p>{key.title}</p>
                    <p style={{ color: "#B5B5B5" }}>{key.serial}</p>
                  </td>
                  <td>
                    <p>{key.imei}</p>
                    <p style={{ color: "#B5B5B5" }}>
                      {key.user === null ? "" : key.user.name}
                    </p>
                  </td>
                  <td>
                    <p>{key.type.name}</p>
                  </td>
                  <td>
                    <p>{key.starter}</p>
                    <p style={{ color: "#B5B5B5" }}>{key.sim_number}</p>
                  </td>
                  <td>
                    <p>{key.driver_name}</p>
                  </td>
                  <td>
                    <p>
                      {key.installed_at === null
                        ? ""
                        : moment(key.installed_at).format("jYYYY/jMM/jDD")}
                    </p>
                  </td>
                  <td>
                    <p>
                      {key.expires_at === null
                        ? ""
                        : moment(key.expires_at).format("jYYYY/jMM/jDD")}
                    </p>
                  </td>
                  <td>
                    <p>
                      {key.last_sent === null
                        ? ""
                        : moment(key.last_sent).format("jYYYY/jMM/jDD")}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withAlert(TracerManager);
