import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import {withAlert} from 'react-alert';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import Pagination from "react-js-pagination";
import {config} from '../../config';
import Loading from "../Loading";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: true
});

let timeOut;

class AdminTransactions extends Component {

    constructor(){
        super();
        const defaultMoment = moment();

        this.state={
            data:[],
            fMcode:'',
            fImei:'',
            fFactorNum:'',
            currPage:1,
            totalItem:0
        };

        this.handleFilter = this.handleFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.getTransactionData = this.getTransactionData.bind(this);
    }

    handleFilter(e){
        this.setState({[e.target.name] : e.target.value});
        clearTimeout(timeOut);
        timeOut = setTimeout(()=>{this.getTransactionData(1)},500);

    }
    clearFilter(){
        this.setState({
            fImei: '',
            fFactorNum: '',
            fMcode:''
        });
        setTimeout(()=>{this.getTransactionData(1)},300);

    }
    getTransactionData(page){
        let req = {
            method: 'get',
            url: config.baseUrl + 'payment/api/admin-invoices?token=' + config.token + '&page=' + page +'&mcode=' + this.state.fMcode + '&invoice_code=' + this.state.fFactorNum+ '&imei='+ this.state.fImei,
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    data: resp.data.data , currPage: page ,totalItem: resp.data.total
                });
                console.log(resp.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    componentDidMount(){
        this.getTransactionData(1);
    }

    render() {

        return (
            <div className='content col-lg-12 col-md-12'>
                <Loading loading={this.state.loading}/>
                <div className='content-head'>
                    <div className='filter-div user-filter col-lg-9 col-md-9'>
                        <p style={{display:'inline-block' , fontSize:'12px'}}>جستجو بر اساس:</p>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fMcode' className='input'  type="text" placeholder={'کد ملی'} value={this.state.fMcode}/>
                        </div>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fImei' className='input'  type="text" placeholder={'imei'} value={this.state.fImei}/>
                        </div>
                        <div className='input-group'>
                            <input onChange={this.handleFilter} style={{textAlign: 'right'}} name='fFactorNum' className='input'  type="text" placeholder={'شماره فاکتور'} value={this.state.fFactorNum}/>
                        </div>
                        <button onClick={this.clearFilter} className='cancelBtn blueBtn'>
                            <i />
                        </button>
                    </div>
                </div>

                <div className='content-body'>
                    <table className='transaction tbl admin'>
                        <thead>
                        <tr>
                            <th>شماره فاکتور</th>
                            <th>مبلغ کل</th>
                            <th>تاریخ صدور</th>
                            <th>وضعیت</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key)=>
                            <tr>
                                <td>
                                    <p>{key.invoice_code}</p>
                                </td>
                                <td>
                                    <p>{key.extra.total}</p>
                                </td>
                                <td>
                                    <p>{moment(key.created_at).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    <p>{key.isPaid === 1 ? 'پرداخت شده' : 'پرداخت نشده'}</p>
                                </td>
                                <td>
                                    <a href={`https://irgogps.com/payment/invoice/${key.invoice_code}`} target='blank'>
                                        <button className='lightBlueBtn'>مشاهده فاکتور</button>
                                    </a>
                                </td>
                            </tr>

                        )}
                        </tbody>
                    </table>
                    <Pagination
                        activePage={this.state.currPage}
                        itemsCountPerPage={20}
                        totalItemsCount={this.state.totalItem}
                        pageRangeDisplayed={5}
                        onChange={this.getTransactionData}
                    />
                </div>
            </div>
        );
    }
}

export default withAlert(AdminTransactions);