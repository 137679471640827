import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import moment from 'moment-jalaali';
import {withAlert} from 'react-alert';

import {config} from '../../config';

class ServicesGroup extends Component {

    constructor(){
        super();

        this.state={
            selected: 0,
            isAddNewServiceGroupOpen:false,
            isUpdateServiceGroupOpen:false,
            isDeleteGroupOpen:false,
            data:[],
            types:[],
            selectedType:0,
            editingGroup:{}
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.addNewServiceGroup = this.addNewServiceGroup.bind(this);
        this.getOneGroupInfo = this.getOneGroupInfo.bind(this);
        this.updateServiceGroup = this.updateServiceGroup.bind(this);
        this.deleteServiceGroup = this.deleteServiceGroup.bind(this);
    }

    handleSelect(selected){
        if(this.state.selected === selected){
            this.setState({selected: 0});
        }else {
            this.setState({selected: selected});
        }
    }
    handleTypeChange(e){
        this.setState({selectedType: e.target.value});
    }

    getServicesData(){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/services/groups',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    data: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });

    }

    addNewServiceGroup(){
        let data = {
            'name' : document.getElementById('service-title').value ,
            'type_id': this.state.selectedType
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/admins/services/groups',
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };

        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({selectedType:0 , isAddNewServiceGroupOpen: false});
                this.props.alert.success('گروه سرویس جدید با موفقیت ثبت شد.');
                this.getServicesData();
            }

        }).catch((err) => {
            console.log(err);
        });
    }

    getOneGroupInfo(id){
        this.setState({isUpdateServiceGroupOpen: true});

        let typsReq = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/services/groups/'+id,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(typsReq).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    editingGroup: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });


    }

    updateServiceGroup(){
        let data = {
            'name' : document.getElementById('service-title').value
        };

        let req = {
            method: 'update',
            url: config.baseUrl + 'api/v1/admins/services/groups/'+this.state.editingGroup.id,
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    editingGroup: {},
                    isUpdateServiceGroupOpen: false
                });
                this.props.alert.success('ویرایش گروه سرویس با موفقیت ثبت شد.');
                this.getServicesData();

            }
        }).catch(err => {
            console.log(err);
        });

    }

    deleteServiceGroup(){
        let req = {
            method: 'delete',
            url: config.baseUrl + 'api/v1/admins/services/groups/'+this.state.deletingGroupId,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    isDeleteGroupOpen: false,
                    deletingGroupId:0
                });
                this.props.alert.success('ردیاب مورد نظر با موفقیت حذف شد.');
                this.getServicesData();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    componentDidMount(){

        this.getServicesData();

        let typsReq = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/services/groups/types',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(typsReq).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    types: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });


    }

    render() {
        return (
            <div className='content col-lg-12 col-md-12'>
                <div className='content-head'>
                    <button className='blueBtn' onClick={()=>{
                        this.setState({isAddNewServiceGroupOpen: true})
                    }}>افزودن گروه</button>
                </div>
                <div className='content-body'>
                    <table className='services-tbl tbl service-groups'>
                        <thead>
                        <tr>
                            <th><img src={require('../../image/tick.png')} /></th>
                            <th>id</th>
                            <th>عنوان</th>
                            <th>نوع انقضا</th>
                            <th>تاریخ ایجاد</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.state.data.map((key)=>
                            <tr>
                                <td>
                                    <button onClick={() => {
                                        this.handleSelect(1)
                                    }}>
                                     <span className='checkbox'>
                                         <img
                                             src={require(`../../image/${this.state.selected === 1 ? 'checked' : 'uncheck'}.svg`)}/>
                                     </span>
                                    </button>
                                </td>
                                <td>
                                    <p>{key.id}</p>
                                </td>
                                <td>
                                    <p>{key.name}</p>
                                </td>
                                <td>
                                    <p>{key.types.name}</p>
                                </td>
                                <td>
                                    <p>{moment(key.created_at).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    <td>
                                        <button onClick={()=>{this.getOneGroupInfo(key.id)}} className='edit-btn' title='ویرایش'>
                                            <i className='icon-edit'/>
                                        </button>
                                        <button onClick={()=>{this.setState({isDeleteGroupOpen:true , deletingGroupId: key.id})}} className='delete-btn' title='حذف' >
                                            <i className='icon-delete'/>
                                        </button>
                                    </td>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                <Modal
                    isOpen={this.state.isAddNewServiceGroupOpen}
                    onRequestClose={()=>{this.setState({isAddNewServiceGroupOpen: false , selectedType:0})}}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن گروه سرویس</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات گروه سرویس جدید را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان گروه سرویس</h5>
                            <div className='input-group'>
                                <input id='service-title' className='input' />
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>نوع گروه سرویس</h5>
                            <div className='input-group'>
                                <select onChange={this.handleTypeChange}>
                                    <option selected disabled>نوع</option>
                                    {this.state.types.map((key)=>
                                        <option value={key.id}>{key.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <button onClick={this.addNewServiceGroup} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={()=>{
                        this.setState({isAddNewServiceGroupOpen: false , selectedType:0})
                    }}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isUpdateServiceGroupOpen}
                    onRequestClose={()=>{this.setState({isUpdateServiceGroupOpen: false , editingGroup:{} , selectedType: 0})}}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>ویرایش گروه سرویس</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات گروه سرویس را ویرایش کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان گروه سرویس</h5>
                            <div className='input-group'>
                                <input style={{textAlign:'right' , direction: 'rtl'}} id='service-title' className='input' defaultValue={this.state.editingGroup.name} />
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>نوع گروه سرویس</h5>
                            <div className='input-group'>
                                <select onChange={this.handleTypeChange}>
                                    {this.state.types.map((key)=>
                                        <option selected={this.state.editingGroup.type_id === key.id ? true : false} value={key.id}>{key.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <button onClick={this.updateServiceGroup} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={()=>{
                        this.setState({isUpdateServiceGroupOpen: false , editingGroup:{} , selectedType: 0})
                    }}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isDeleteGroupOpen}
                    onRequestClose={()=>{
                        this.setState({isDeleteGroupOpen:false, deletingGroupId:0})
                    }}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>حذف گروه سرویس</p>
                    </div>
                    <p style={{fontSize: '14px' , textAlign: 'center', marginBottom: '40px' }}>آیا از حذف این گروه اطمینان دارید؟</p>
                    <button onClick={this.deleteServiceGroup} className='blueBtn btn' style={{padding: '15px 85px'}}>بله</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 75px'}} onClick={()=>{
                        this.setState({isDeleteGroupOpen:false, deletingGroupId:0})
                    }}>انصراف</button>
                </Modal>
            </div>
        );
    }
}

export default withAlert(ServicesGroup);