import React, { Component } from "react";
import Modal from "react-modal";
import axios from "axios";
import { withAlert } from "react-alert";
import moment from "moment-jalaali";
import { config } from "../../config";
import Loading from "../Loading";
import InputRange from "react-input-range";

import "react-input-range/lib/css/index.css";

class TracerSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editingTracerId: this.props.id,
      tracerSettingTitle: "",
      tracerSettingType: "",
      selectedSetting: 2,
      settingChecked: [0],
      commandText: "",
      commandType: "",
      commandRespText: "",
      commandCheckToken: "",
      selectTypeTitle: "",
      isAddAdminsOpen: false,
      isAddTracerOwnerOpen: false,
      isCommandRespOpen: false,
      isSelectDefenseTimeOpen: false,
      isSelectVibrationOpen: false,
      isAddOneAdminOpen: false,
      isRemoveOneAdminOpen: false,
      isPowerSelectSendTypeOpen: false,
      isBatterySelectSendTypeOpen: false,
      isDoorSelectSendTypeOpen: false,
      isTurnOnOffSelectSendTypeOpen: false,
      isMovingSelectSendTypeOpen: false,
      isOverSpeedSelectSendTypeOpen: false,
      isVibrationSelectSendTypeOpen: false,
      isVibrationRangeSelectOpen: false,
      isCustomCommandOpen: false,
      isAddSOSOpen: false,
      isSOSCallTimeOpen: false,
      isLocTimingOpen: false,
      isLocDistOpen: false,
      isSOSALMOpen: false,
      switchId: 0,
      rangeValue: 1,
      rangeOFFTiming: 10,
      rangeONTiming: 10,
      voltRangeValue: 100,
      selectedSendType: 0,
      AccSelect: 0,
      TimingRangeValue: 1,
      timeOut: 0,
    };

    this.textInput = React.createRef();

    this.handleSettingCheckbox = this.handleSettingCheckbox.bind(this);
    this.createTracerSettingsSwitchRow =
      this.createTracerSettingsSwitchRow.bind(this);
    this.createTracerSettingsButtonRow =
      this.createTracerSettingsButtonRow.bind(this);
    this.sendCommand = this.sendCommand.bind(this);
    this.checkCommandResp = this.checkCommandResp.bind(this);
    this.createEmptySettingText = this.createEmptySettingText.bind(this);
  }

  handleSettingCheckbox(id) {
    // console.log(id);
    // let array = this.state.settingChecked;
    // if (array.includes(id)) {
    //     let index = array.indexOf(id);
    //     if (index > -1) {
    //         array.splice(index, 1);
    //     }
    //     console.log(`img${id}`);
    //     document.getElementById(`img${id}`).src = require(`../../image/off.png`)
    // } else {
    //     array.push(id);
    //     console.log(`img${id}`);
    //     document.getElementById(`img${id}`).src = require(`../../image/on.png`)
    // }
    // this.setState({settingChecked: array});
  }

  createTracerSettingsSwitchRow(
    parentDivId,
    title,
    id,
    func,
    enableCommand,
    disableCommand
  ) {
    // let img;
    // let command;
    // if (!this.state.settingChecked.includes(id)) {
    //     img = require(`../../image/off.png`)
    // } else {
    //     img = require(`../../image/on.png`)
    // }

    // if (this.state.settingChecked.includes(id)) {
    //     command = disableCommand;
    //     console.log(command);
    // } else {
    //     command = enableCommand
    // }

    let node =
      "<div class='settingDiv'>" +
      "<p class='text-center'>" +
      title +
      "</p>" +
      "<div class='row'>" +
      "<div class='col-xs-6'><button class='delete-btn' onclick=window." +
      func +
      "('" +
      disableCommand +
      "'," +
      id +
      "," +
      0 +
      ") >" +
      "<span> غیر فعال </span>" +
      "</button></div>" +
      "<div class='col-xs-6'><button class='edit-btn' onclick=window." +
      func +
      "('" +
      enableCommand +
      "'," +
      id +
      "," +
      1 +
      ") >" +
      "<span> فعال </span>" +
      "</button></div>" +
      "</div></div>";

    document.getElementById(parentDivId).insertAdjacentHTML("beforeend", node);
  }

  createTracerSettingsButtonRow(parentDivId, title, id, func, command) {
    let node =
      "<div class='settingDiv'>" +
      "<p>" +
      title +
      "</p>" +
      "<button onclick=window." +
      func +
      "('" +
      command +
      "'," +
      id +
      ") class='lightBlueBtn' >تنظیم و ارسال</button>" +
      "</div>";
    document.getElementById(parentDivId).insertAdjacentHTML("beforeend", node);
  }
  createEmptySettingText(parentDivId) {
    let node =
      "<div class='settingDiv emptySetting'>" +
      "<p>این ردیاب دستورات آنلاین ندارد، لطفا از طریق SMS  (اپلیکیشن موبایل) اقدام به ارسال دستور نمایید.</p>" +
      "</div>";
    document.getElementById(parentDivId).insertAdjacentHTML("beforeend", node);
  }

  sendCommand(cmd, id) {
    this.setState({ loading: true });

    let command = cmd;
    command = command.replace("!", " ");

    if (this.state.commandType === "addAdmins") {
      if (this.props.type == "m300-plus" || this.props.type == "m500-plus") {
        command = command.replace(
          "num1",
          "98" + document.getElementById("num1").value.substring(1)
        );
        command = command.replace(
          "num2",
          "98" + document.getElementById("num2").value.substring(1)
        );
        command = command.replace(
          "num3",
          "98" + document.getElementById("num3").value.substring(1)
        );
      } else {
        command = command.replace(
          "num1",
          document.getElementById("num1").value
        );
        command = command.replace(
          "num2",
          document.getElementById("num2").value
        );
        command = command.replace(
          "num3",
          document.getElementById("num3").value
        );
      }
    } else if (this.state.commandType === "addOwner") {
      command = command.replace("$", document.getElementById("owner").value);
    } else if (this.state.commandType === "customCommand") {
      command = command.replace(
        "$",
        document.getElementById("customCommand").value
      );
    } else if (
      this.state.commandType === "defense" ||
      this.state.commandType === "vibration"
    ) {
      command = command.replace("$", this.state.rangeValue);
    } else if (this.state.commandType === "addOneAdmin") {
      command = command.replace(
        "$",
        document.getElementById("oneAdminNum").value
      );
    } else if (this.state.commandType === "addOneOwner") {
      command = command.replace("$", document.getElementById("owner").value);
    } else if (this.state.commandType === "removeOneAdmin") {
      command = command.replace(
        "$",
        document.getElementById("removeAdminNum").value
      );
    } else if (this.state.commandType === "selectType") {
      command = command.replace("RM", this.state.selectedSendType);
    } else if (this.state.commandType === "moving") {
      command = command.replace("$", this.state.rangeValue);
      command = command.replace("RM", this.state.selectedSendType);
    } else if (this.state.commandType === "AddSOS") {
      command = command.replace(
        "num1",
        "98" + document.getElementById("SOS1").value.substring(1)
      );
      command = command.replace(
        "num2",
        "98" + document.getElementById("SOS2").value.substring(1)
      );
      command = command.replace(
        "num3",
        "98" + document.getElementById("SOS3").value.substring(1)
      );
    } else if (this.state.commandType === "LocTiming") {
      command = command.replace("T1", this.state.rangeONTiming);
      command = command.replace("T2", this.state.rangeOFFTiming);
    } else if (this.state.commandType === "PowerPlus") {
      command = command.replace("T1", this.state.rangeValue);
      command = command.replace("RM", this.state.selectedSendType);
    } else if (this.state.commandType === "BatteryPlus") {
      command = command.replace("T1", this.state.rangeValue);
      command = command.replace("N1", this.state.voltRangeValue);
      command = command.replace("RM", this.state.selectedSendType);
    } else if (this.state.commandType === "ACCPlus") {
      command = command.replace("T", this.state.rangeValue);
      command = command.replace("N1", this.state.AccSelect);
      command = command.replace("RM", this.state.selectedSendType);
    } else if (this.state.commandType === "SpeedPlus") {
      command = command.replace("T", this.state.TimingRangeValue);
      command = command.replace("$", this.state.rangeValue);
      command = command.replace("RM", this.state.selectedSendType);
    }

    let data = {
      command: command,
    };
    let req = {
      method: "post",
      url:
        config.baseUrl +
        "api/v1/clients/tracers/" +
        this.props.id +
        "/commands/custom/send",
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };

    axios(req)
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.status === 1) {
          this.setState({
            commandCheckToken: resp.data.data.token,
            switchId: 0,
            rangeValue: 1,
            selectedSendType: 0,
            isAddAdminsOpen: false,
            isAddTracerOwnerOpen: false,
            isCommandRespOpen: false,
            isSelectDefenseTimeOpen: false,
            isSelectVibrationOpen: false,
            isAddOneAdminOpen: false,
            isRemoveOneAdminOpen: false,
            isPowerSelectSendTypeOpen: false,
            isBatterySelectSendTypeOpen: false,
            isDoorSelectSendTypeOpen: false,
            isTurnOnOffSelectSendTypeOpen: false,
            isMovingSelectSendTypeOpen: false,
            isOverSpeedSelectSendTypeOpen: false,
            isVibrationSelectSendTypeOpen: false,
            isVibrationRangeSelectOpen: false,
            isCustomCommandOpen: false,
            isSOSCallTimeOpen: false,
            isLocTimingOpen: false,
            isLocDistOpen: false,
            isSOSALMOpen: false,
          });
          this.props.alert.success("دستور با موفقیت ارسال شد.");
          setTimeout(() => {
            this.checkCommandResp(id);
          }, 2000);
        }
      })
      .catch((err) => {});
  }

  checkCommandResp(id) {
    this.setState({ loading: true });
    if (id !== 0) {
      this.handleSettingCheckbox(id);
    }

    let check = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/tracers/" +
        this.props.id +
        "/commands/check/" +
        this.state.commandCheckToken,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    axios(check)
      .then((resp) => {
        if (resp.data.requestStatus === 3) {
          console.log(resp.data.data);
          this.setState({
            commandRespText: resp.data.data.response,
            loading: false,
            isCommandRespOpen: true,
            isAddAdminsOpen: false,
            isAddTracerOwnerOpen: false,
            isSelectSendTypeOpen: false,
          });
        } else {
          if (this.state.timeOut <= 120000) {
            setTimeout(() => {
              this.checkCommandResp(id);
            }, 2000);
            this.setState({ timeOut: this.state.timeOut + 2000 });
            console.log(resp.data.message);
          } else {
            this.props.alert.error(
              "مشکلی در پاسخ دهی ردیاب پیش آمد، لطفا دوباره امتحان کنید."
            );
            this.setState({ timeOut: 0, loading: false });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.alert.error("مشکلی پیش آمد.");
      });
  }

  AddAdmins(command, id) {
    this.setState({
      isAddAdminsOpen: true,
      commandText: command,
      commandType: "addAdmins",
      switchId: id,
    });
  }

  AddSOS(command, id) {
    this.setState({
      isAddSOSOpen: true,
      commandText: command,
      commandType: "addSOS",
      switchId: id,
    });
  }

  AddTracerOwner(command, id) {
    this.setState({
      isAddTracerOwnerOpen: true,
      commandText: command,
      commandType: "addOwner",
      switchId: id,
    });
  }

  customCommand(command, id) {
    this.setState({
      isCustomCommandOpen: true,
      commandText: command,
      commandType: "customCommand",
      switchId: id,
    });
  }

  AddOneTracerOwner(command, id) {
    this.setState({
      isAddTracerOwnerOpen: true,
      commandText: command,
      commandType: "addOneOwner",
      switchId: id,
    });
  }

  SelectDefenseTimeOpen(command, id) {
    this.setState({
      isSelectDefenseTimeOpen: true,
      commandText: command,
      commandType: "defense",
      switchId: id,
    });
  }

  SelectVibrationLevelOpen(command, id) {
    this.setState({
      isSelectVibrationOpen: true,
      commandText: command,
      commandType: "vibration",
      switchId: id,
    });
  }

  AddOneAdmin(command, id) {
    this.setState({
      isAddOneAdminOpen: true,
      commandText: command,
      commandType: "addOneAdmin",
      switchId: id,
    });
  }

  removeOneAdmin(command, id) {
    this.setState({
      isRemoveOneAdminOpen: true,
      commandText: command,
      commandType: "removeOneAdmin",
      switchId: id,
    });
  }

  PowerSelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isPowerSelectSendTypeOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
      });
    }
  }
  PowerSelectSendTypePlus(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isPowerSelectSendTypeOpen: true,
        commandText: command,
        commandType: "PowerPlus",
        switchId: id,
        rangeValue: 2,
      });
    }
  }
  BatterySelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isBatterySelectSendTypeOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
      });
    }
  }
  BatterySelectSendTypePlus(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isBatterySelectSendTypeOpen: true,
        commandText: command,
        commandType: "BatteryPlus",
        switchId: id,
        rangeValue: 1,
        voltRangeValue: 100,
      });
    }
  }
  DoorSelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isDoorSelectSendTypeOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
      });
    }
  }
  TurnOnOffSelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isTurnOnOffSelectSendTypeOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
      });
    }
  }
  TurnOnOffSelectSendTypePlus(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isTurnOnOffSelectSendTypeOpen: true,
        commandText: command,
        commandType: "ACCPlus",
        switchId: id,
        rangeValue: 5,
      });
    }
  }
  MovingSelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isMovingSelectSendTypeOpen: true,
        commandText: command,
        commandType: "moving",
        switchId: id,
        rangeValue: 600,
      });
    }
  }
  OverSpeedSelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isOverSpeedSelectSendTypeOpen: true,
        commandText: command,
        commandType: "moving",
        switchId: id,
        rangeValue: 50,
      });
    }
  }
  OverSpeedSelectSendTypePlus(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isOverSpeedSelectSendTypeOpen: true,
        commandText: command,
        commandType: "SpeedPlus",
        switchId: id,
        rangeValue: 50,
        TimingRangeValue: 5,
      });
    }
  }
  VibrationSelectSendType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isVibrationSelectSendTypeOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
      });
    }
  }
  VibrationRangeSelect(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isVibrationRangeSelectOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
        rangeValue: 1,
      });
    }
  }
  SOSALMSelectType(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isSOSALMOpen: true,
        commandText: command,
        commandType: "selectType",
        switchId: id,
      });
    }
  }
  SOSCallSelect(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isSOSCallTimeOpen: true,
        commandText: command,
        commandType: "vibration",
        switchId: id,
        rangeValue: 1,
      });
    }
  }

  SendLocationTiming(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isLocTimingOpen: true,
        commandText: command,
        commandType: "LocTiming",
        switchId: id,
        rangeONTiming: 10,
        rangeOFFTiming: 10,
      });
    }
  }
  SendLocDistanse(command, id, type) {
    if (type === 0) {
      this.sendCommand(command, id);
    } else {
      this.setState({
        isLocDistOpen: true,
        commandText: command,
        commandType: "vibration",
        switchId: id,
        rangeValue: 50,
      });
    }
  }

  componentDidMount() {
    console.log(this.props.type);

    //alerts
    switch (this.props.type) {
      case "wg010e":
      case "m100":
      case "m500":
      case "gt06n":
      case "gt06f":
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار قطع اینترنت ردیاب', 1, "sendCommand", "GPRSALM,ON#", "GPRSALM,OFF#");
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار قطع برق",
          2,
          "PowerSelectSendType",
          "POWERALM,ON,RM,5,300#",
          "POWERALM,OFF"
        );
        if (this.props.type !== "m100" && this.props.type !== "m500") {
          this.createTracerSettingsSwitchRow(
            "alertSetting",
            "هشدار ضعف باتری",
            3,
            "BatterySelectSendType",
            "BATALM,ON,RM#",
            "BATALM,OFF#"
          );
        }
        if (this.props.type !== "gt06n" && this.props.type !== "m100") {
          this.createTracerSettingsSwitchRow(
            "alertSetting",
            "هشدار باز شدن درب خودرو",
            4,
            "DoorSelectSendType",
            "DOORALM,ON,RM#",
            "DOORALM,OFF#"
          );
        }
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار روشن و خاموش کردن خودرو",
          5,
          "TurnOnOffSelectSendType",
          "ACCALM,ON,RM,10##",
          "ACCALM,OFF#"
        );
        if (this.props.type !== "m100" && this.props.type !== "m500") {
          this.createTracerSettingsSwitchRow(
            "alertSetting",
            "هشدار جابجایی (جرثقیل)",
            6,
            "MovingSelectSendType",
            "MOVING,ON,$,RM#",
            "MOVING,OFF#"
          );
        }
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار سرعت غیرمجاز",
          7,
          "OverSpeedSelectSendType",
          "SPEED,ON,5,$,RM#",
          "SPEED,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار لرزه",
          8,
          "VibrationSelectSendType",
          "SENALM,ON,RM#",
          "SENALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "خاموش کردن خودرو",
          9,
          "sendCommand",
          "RELAY,1#",
          "RELAY,0#"
        );
        break;

      case "gm031l":
      case "ob22":
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار قطع اینترنت ردیاب', 1, "sendCommand", "GPRSALM,ON#", "GPRSALM,OFF#");
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار قطع برق",
          2,
          "PowerSelectSendType",
          "POWERALM,ON,RM,5,300#",
          "POWERALM,OFF"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار ضعف باتری",
          3,
          "BatterySelectSendType",
          "BATALM,ON,RM#",
          "BATALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار روشن و خاموش کردن خودرو",
          5,
          "sendCommand",
          "ACCALM,ON,RM,10##",
          "ACCALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار جابجایی (جرثقیل)",
          6,
          "MovingSelectSendType",
          "MOVING,ON,$,RM#",
          "MOVING,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار لرزه",
          8,
          "VibrationSelectSendType",
          "SENALM,ON,RM#",
          "SENALM,OFF#"
        );
        break;

      case "gp021n":
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار قطع اینترنت ردیاب', 1, "sendCommand", "GPRSALM,ON#", "GPRSALM,OFF#");
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار ضعف باتری",
          3,
          "BatterySelectSendType",
          "BATALM,ON,RM#",
          "BATALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار روشن شدن",
          5,
          "sendCommand",
          "BOOTALM,ON,RM#",
          "BOOTALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار جابجایی (جرثقیل)",
          6,
          "MovingSelectSendType",
          "MOVING,ON,$,RM#",
          "MOVING,OFF#"
        );
        break;
      case "tk103":
      case "tk80":
      case "basicnew":
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار قطع برق', 2, "sendCommand","Extrapower123456!1", "Extrapower123456!0");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار روشن و خاموش کردن خودرو', 5, "sendCommand","Acc123456!1", "Acc123456!0");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار سرعت غیرمجاز', 7, "OverSpeedSelectSendType","Speed123456!$", "Speed123456!0#");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار لرزه', 8, "handleSettingCheckbox","Vibrate123456!$", "Vibrate123456!0");
        // this.createTracerSettingsSwitchRow('alertSetting', 'خاموش کردن خودرو', 9, "sendCommand","Dy123456","Ty123456");
        // this.createTracerSettingsSwitchRow('alertSetting', 'دریافت هشدارها از طریق تماس', 10, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'دریافت هشدارها از طریق پیامک', 11, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'لغو دریافت هشدارها', 13, "handleSettingCheckbox");
        this.createEmptySettingText("alertSetting");
        break;
      case "plus": {
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار روشن و خاموش کردن خودرو', 5, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار سرعت غیرمجاز', 7, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'خاموش کردن خودرو', 9, "handleSettingCheckbox");
        this.createEmptySettingText("alertSetting");
        break;
      }
      case "co303": {
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار قطع برق', 2, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار ضعف باتری', 3, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار سرعت غیرمجاز', 7, "handleSettingCheckbox");
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "خاموش کردن خودرو",
          9,
          "sendCommand",
          "STOP123456",
          "RESUME123456"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "فعال سازی دزدگیر (با صدا)",
          10,
          "sendCommand",
          "ARM123456",
          "ARM123456"
        );
        // this.createTracerSettingsSwitchRow('alertSetting', 'فعال سازی دزدگیر (بدون صدا)', 11, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'غیرفعال سازی دزدگیر', 12, "handleSettingCheckbox");
        break;
      }
      case "riva101":
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار جابجایی (جرثقیل)', 6, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'هشدار سرعت غیرمجاز', 7, "handleSettingCheckbox");
        // this.createTracerSettingsSwitchRow('alertSetting', 'خاموش کردن خودرو', 9, "handleSettingCheckbox");
        this.createEmptySettingText("alertSetting");
        break;

      case "gt800":
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار قطع برق",
          2,
          "PowerSelectSendType",
          "POWERALM,ON,RM#",
          "POWERALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار باز شدن درب خودرو",
          4,
          "DoorSelectSendType",
          "DOORALM,ON#",
          "DOORALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار ضعف باتری",
          3,
          "BatterySelectSendType",
          "BATALM,ON#",
          "BATALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار روشن و خاموش کردن خودرو",
          5,
          "TurnOnOffSelectSendType",
          "ACCALM,ON,RM,10#",
          "ACCALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار جابجایی (جرثقیل)",
          6,
          "MovingSelectSendType",
          "MOVING,ON,$,RM#",
          "MOVING,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار سرعت غیرمجاز",
          7,
          "OverSpeedSelectSendType",
          "SPEED,ON,5,$,RM#",
          "SPEED,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "خاموش کردن خودرو",
          9,
          "sendCommand",
          "RELAY,1#",
          "RELAY,0#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار لرزه",
          8,
          "VibrationSelectSendType",
          "SENALM,ON,RM#",
          "SENALM,OFF#"
        );
        break;

      case "gp027n":
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار ضعف باتری",
          3,
          "BatterySelectSendType",
          "BATALM,ON#",
          "BATALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار روشن شدن",
          5,
          "sendCommand",
          "BOOTALM,ON#",
          "BOOTALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار جدا شدن",
          7,
          "sendCommand",
          "REMALM,ON#",
          "REMALM,OFF#"
        );
        break;

      case "m300-plus":
      case "m500-plus":
      case "m700" : 
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار قطع برق دستگاه",
          2,
          "PowerSelectSendTypePlus",
          "POWERALM,ON,RM,T1,300#",
          "POWERALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار ضعف باتری خودرو",
          3,
          "BatterySelectSendTypePlus",
          "EXBATALM,ON,RM,N1,160,T1#",
          "EXBATALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار باز شدن درب خودرو",
          4,
          "DoorSelectSendType",
          "DOORALM,ON,RM#",
          "DOORALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار روشن و خاموش کردن خودرو",
          5,
          "TurnOnOffSelectSendTypePlus",
          "ACCALM,ON,RM,T,N1#",
          "ACCALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار جابجایی (جرثقیل)",
          6,
          "MovingSelectSendType",
          "MOVING,ON,$,RM#",
          "MOVING,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار لرزش",
          8,
          "VibrationSelectSendType",
          "SENALM,ON,RM#",
          "SENALM,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار سرعت غیرمجاز",
          7,
          "OverSpeedSelectSendTypePlus",
          "SPEED,ON,T,$,RM#",
          "SPEED,OFF#"
        );
        if (this.props.type !== "m700"){
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "خاموش کردن خودرو",
          9,
          "sendCommand",
          "RELAY,1#",
          "RELAY,0#"
        );
      }
        this.createTracerSettingsSwitchRow(
          "alertSetting",
          "هشدار کلید SOS",
          10,
          "SOSALMSelectType",
          "SOSALM,ON,RM#",
          "SOSALM,OFF#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "روشن کردن خودرو(پس از سوئیچ مخفی)",
          0,
          "sendCommand",
          "RELAY,0#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "وضعیت هشدار قطع برق دستگاه",
          0,
          "sendCommand",
          "POWERALM#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "وضعیت هشدار روشن و خاموش کردن خودرو",
          0,
          "sendCommand",
          "ACCALM#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "وضعیت هشدار باز شدن درب خودرو",
          0,
          "sendCommand",
          "DOORALM#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "وضعیت هشدار هشدار جابجایی (جرثقیل)",
          0,
          "sendCommand",
          "MOVING#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "وضعیت هشدار لرزش",
          0,
          "sendCommand",
          "SENALM#"
        );
        this.createTracerSettingsButtonRow(
          "alertSetting",
          "وضعیت روشن کردن خودرو (پس از سوئیچ مخفی)",
          0,
          "sendCommand",
          "RELAY#"
        );

        break;
    }

    //settings

    switch (this.props.type) {
      case "wg010e":
      case "m100":
      case "m500":
      case "gt06n":
      case "gt06f": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف مدیر (Admin)",
          0,
          "AddAdmins",
          "SOS,A,num1,num2,num3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف شماره صاحب ردیاب",
          0,
          "AddTracerOwner",
          "CENTER,A,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره صاحب ردیاب",
          0,
          "sendCommand",
          "CENTER#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر اول",
          0,
          "sendCommand",
          "SOS,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر دوم",
          0,
          "sendCommand",
          "SOS,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر سوم",
          0,
          "sendCommand",
          "SOS,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف تمامی مدیر ها",
          0,
          "sendCommand",
          "SOS,D,1,2,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف شماره صاحب ردیاب",
          0,
          "sendCommand",
          "CENTER,D#"
        );
        
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره مدیرها",
          0,
          "sendCommand",
          "SOS#"
        );
        if (
          this.props.type !== "gt06n" &&
          this.props.type !== "m100" &&
          this.props.type !== "m500"
        ) {
          this.createTracerSettingsSwitchRow(
            "deviceSetting",
            "فعال سازی قطب مثبت شستی لادری",
            10,
            "sendCommand",
            "DOOR,1#",
            "DOOR,0#"
          );
        }
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد زمانی حالت دفاعی",
          0,
          "SelectDefenseTimeOpen",
          "DEFENSE,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد حساسیت لرزه",
          0,
          "SelectVibrationLevelOpen",
          "LEVEL,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست وضعیت ردیاب و باتری",
          0,
          "sendCommand",
          "STATUS#"
        );
        if (this.props.type !== "m100" && this.props.type !== "m500") {
          this.createTracerSettingsButtonRow(
            "deviceSetting",
            "درخواست تماس از سوی ردیاب",
            0,
            "sendCommand",
            "JT#"
          );
        }
        if (
          this.props.type !== "gt06n" &&
          this.props.type !== "m100" &&
          this.props.type !== "m500"
        ) {
          this.createTracerSettingsButtonRow(
            "deviceSetting",
            "درخواست هشدار پیدا کردن خودرو",
            0,
            "sendCommand",
            "FIND#"
          );
        }
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست لینک گوگل",
          0,
          "sendCommand",
          "URL#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "RESET#"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*141*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دستور سفارشی",
          0,
          "customCommand",
          "$"
        );
        // this.createTracerSettingsButtonRow('deviceSetting', 'مشاهده شارژ سیم کارت', 0, "sendCommand", "JT#");
        break;
      }

      case "m300-plus":
      case "m500-plus":
      case "m700": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف مدیر (Admin)",
          0,
          "AddAdmins",
          "center,A,num1,num2,num3#"
        );
        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "تعریف شماره صاحب ردیاب",
        //   0,
        //   "AddOneTracerOwner",
        //   "SETADMIN123456!$"
        // );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره مدیرها",
          0,
          "sendCommand",
          "center#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر اول",
          0,
          "sendCommand",
          "center,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر دوم",
          0,
          "sendCommand",
          "center,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر سوم",
          0,
          "sendCommand",
          "center,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف تمامی مدیر ها",
          0,
          "sendCommand",
          "center,D,1,2,3#"
        );
        
        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "مشاهده شماره صاحب ردیاب",
        //   0,
        //   "sendCommand",
        //   "CENTER#"
        // );

        if (this.props.type !== "m700"){
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          " تعریف کلید SOS",
          0,
          "AddSOS",
          "SOS,A,num1,num2,num3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف کلید SOS (اضطراری)اول",
          0,
          "sendCommand",
          "SOS,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف کلید SOS (اضطراری)دوم",
          0,
          "sendCommand",
          "SOS,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف کلید SOS (اضطراری)سوم",
          0,
          "sendCommand",
          "SOS,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره های گلید SOS",
          0,
          "sendCommand",
          "SOS#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعداد دفعات تماس با شماره های SOS",
          0,
          "SOSCallSelect",
          "CALL,$#"
        );
      }
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "زمان‌بندی ارسال لوکیشن",
          0,
          "SendLocationTiming",
          "TIMER,T1,T2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "متراژ ارسال لوکیشن",
          0,
          "SendLocDistanse",
          "DISTANCE,$#"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "فعال سازی قطب مثبت شستی لادری",
          20,
          "sendCommand",
          "DOOR,1#",
          "DOOR,0#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد زمانی حالت دفاعی",
          0,
          "SelectDefenseTimeOpen",
          "DEFENSE,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد حساسیت لرزش",
          0,
          "SelectVibrationLevelOpen",
          "LEVEL,$#"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "سویچ مخفی",
          21,
          "sendCommand",
          "ERELAY,ON#",
          "ERELAY,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "قفل شماره های مدیر",
          22,
          "sendCommand",
          "CENTERLOCK,ON#",
          "CENTERLOCK,OFF#"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "خاموش کردن LED های روی دستگاه",
          23,
          "sendCommand",
          "LEDSLEEP,ON#",
          "LEDSLEEP,OFF#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          " وضعیت عمومی ردیاب",
          0,
          "sendCommand",
          "param#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          " وضعیت اتصال دستگاه",
          0,
          "sendCommand",
          "STATUS#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دریافت موقعیت آفلاین",
          0,
          "sendCommand",
          "URL#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دریافت آخرین موقعیت جغرافیایی",
          0,
          "sendCommand",
          "where#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "RESET#"
        );
        
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*141*1##"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دستور سفارشی",
          0,
          "customCommand",
          "$"
        );
        break;
      }

      case "gm031l":
      case "ob22": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف مدیر (Admin)",
          0,
          "AddAdmins",
          "SOS,A,num1,num2,num3#"
        );
        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "تعریف شماره صاحب ردیاب",
        //   0,
        //   "AddTracerOwner",
        //   "CENTER,A,$#"
        // );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره مدیرها",
          0,
          "sendCommand",
          "SOS#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر اول",
          0,
          "sendCommand",
          "SOS,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر دوم",
          0,
          "sendCommand",
          "SOS,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر سوم",
          0,
          "sendCommand",
          "SOS,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف تمامی مدیر ها",
          0,
          "sendCommand",
          "SOS,D,1,2,3#"
        );
        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "حذف شماره صاحب ردیاب",
        //   0,
        //   "sendCommand",
        //   "CENTER,D#"
        // );
        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "مشاهده شماره صاحب ردیاب",
        //   0,
        //   "sendCommand",
        //   "CENTER#"
        // );
        
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد زمانی حالت دفاعی",
          0,
          "SelectDefenseTimeOpen",
          "DEFENSE,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد حساسیت لرزه",
          0,
          "SelectVibrationLevelOpen",
          "LEVEL,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست وضعیت ردیاب و باتری",
          0,
          "sendCommand",
          "STATUS#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست لینک گوگل",
          0,
          "sendCommand",
          "URL#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "RESET#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دستور سفارشی",
          0,
          "customCommand",
          "$"
        );

        // this.createTracerSettingsButtonRow('deviceSetting', 'مشاهده شارژ سیم کارت', 0, "handleSettingCheckbox");
        break;
      }

      case "gp021n": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف مدیر (Admin)",
          0,
          "AddAdmins",
          "SOS,A,num1,num2,num3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره مدیرها",
          0,
          "sendCommand",
          "SOS#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر اول",
          0,
          "sendCommand",
          "SOS,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر دوم",
          0,
          "sendCommand",
          "SOS,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر سوم",
          0,
          "sendCommand",
          "SOS,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف تمامی مدیر ها",
          0,
          "sendCommand",
          "SOS,D,1,2,3#"
        );
        
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "فعال سازی لغو تماس دو طرفه",
          8,
          "sendCommand",
          "JC,1#",
          "JC,0#"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "فعال سازی حالت سایلنت",
          9,
          "sendCommand",
          "LS,1#",
          "LS,0#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "خاموش کردن ردیاب",
          0,
          "sendCommand",
          "SHUTDOWN#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست وضعیت ردیاب و باتری",
          0,
          "sendCommand",
          "STATUS#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست تماس از سوی ردیاب",
          0,
          "sendCommand",
          "JT#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست لینک گوگل",
          0,
          "sendCommand",
          "URL#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "RESET#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );
        break;
      }

      case "tk103":
      case "tk80":
      case "basicnew": {
        // this.createTracerSettingsButtonRow('deviceSetting', 'تعریف شماره مدیر', 0, "AddOneAdmin", "ADMIN123456!$");
        // this.createTracerSettingsButtonRow('deviceSetting', 'تعریف شماره صاحب ردیاب', 0, "AddOneTracerOwner", 'CENTERNUM123456!$');
        // this.createTracerSettingsButtonRow('deviceSetting', 'حذف شماره مدیر', 0, "removeOneAdmin", "NOADMIN123456!$");
        // this.createTracerSettingsSwitchRow('deviceSetting', 'شنود', 8, "sendCommand", "MONITOR123456", "TRACKER123456");
        // this.createTracerSettingsButtonRow('deviceSetting', 'درخواست وضعیت ردیاب و باتری', 0, "sendCommand", "Check123456");
        // this.createTracerSettingsButtonRow('deviceSetting', 'راه اندازی مجدد ردیاب', 0, "sendCommand", "Reset123456");
        this.createEmptySettingText("deviceSetting");
        break;
      }

      case "plus": {
        // this.createTracerSettingsButtonRow('deviceSetting', 'تعریف شماره مدیر', 0, "AddOneAdmin", "#admin#123456#$#");
        // this.createTracerSettingsButtonRow('deviceSetting', 'حذف شماره مدیر', 0, "removeOneAdmin", "#noadmin#123456#$#");
        // this.createTracerSettingsSwitchRow('deviceSetting', 'شنود', 8, "sendCommand", "#monitor#123456#", "#tracker#123456#");
        // this.createTracerSettingsButtonRow('deviceSetting', 'درخواست لینک گوگل', 0, "sendCommand", "#smslink#123456#");
        // this.createTracerSettingsButtonRow('deviceSetting', 'درخواست وضعیت ردیاب و باتری', 0, "sendCommand", "TCP");
        // this.createTracerSettingsButtonRow('deviceSetting', 'راه اندازی مجدد ردیاب', 0, "sendCommand", "#reboot#123456#");
        this.createEmptySettingText("deviceSetting");
        break;
      }

      case "co303": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف شماره مدیر",
          0,
          "AddOneAdmin",
          "ADMIN123456!$"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف شماره صاحب ردیاب",
          0,
          "AddOneTracerOwner",
          "SETADMIN123456!$"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف شماره مدیر",
          0,
          "removeOneAdmin",
          "NOADMIN123456!$"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف شماره صاحب ردیاب",
          0,
          "sendCommand",
          "NOCENTERNUM123456"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "شنود",
          8,
          "sendCommand",
          "MONITOR123456",
          "TRACKER123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست وضعیت ردیاب و باتری",
          0,
          "sendCommand",
          "Check123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "Reset123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دستور سفارشی",
          0,
          "customCommand",
          "$"
        );

        break;
      }

      case "riva101": {
        // this.createTracerSettingsButtonRow('deviceSetting', 'تعریف شماره مدیر', 0, "AddOneAdmin", "ADMIN123456!$");
        // this.createTracerSettingsButtonRow('deviceSetting', 'تعریف شماره صاحب ردیاب', 0, "AddOneTracerOwner", 'CENTERNUM123456!$');
        // this.createTracerSettingsButtonRow('deviceSetting', 'حذف شماره مدیر', 0, "removeOneAdmin", "NOADMIN123456!$");
        // this.createTracerSettingsButtonRow('deviceSetting', 'تایید شنود برای تمامی ادمین ها', 0, "sendCommand", "ALLMONITOR");
        // this.createTracerSettingsButtonRow('deviceSetting', 'تایید شنود فقط برای صاحب ردیاب', 0, "sendCommand", "RESTRICTMONITOR");
        // this.createTracerSettingsButtonRow('deviceSetting', 'لغو شنود', 0, "sendCommand", "CLOSEMONITOR");
        // this.createTracerSettingsButtonRow('deviceSetting', 'درخواست وضعیت ردیاب و باتری', 0, "sendCommand", "TCP");
        // this.createTracerSettingsButtonRow('deviceSetting', 'درخواست لینک گوگل', 0, "sendCommand", "GOOGLE");
        // this.createTracerSettingsButtonRow('deviceSetting', 'راه اندازی مجدد ردیاب', 0, "sendCommand", "REBOOT123456");
        this.createEmptySettingText("deviceSetting");
        break;
      }

      case "gt800": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف مدیر (Admin)",
          0,
          "AddAdmins",
          "SOS,A,num1,num2,num3#"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره مدیرها",
          0,
          "sendCommand",
          "SOS#"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف شماره صاحب ردیاب",
          0,
          "AddOneTracerOwner",
          "CENTER,A,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر اول",
          0,
          "sendCommand",
          "SOS,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر دوم",
          0,
          "sendCommand",
          "SOS,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر سوم",
          0,
          "sendCommand",
          "SOS,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف تمامی مدیر ها",
          0,
          "sendCommand",
          "SOS,D,1,2,3#"
        );
        
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره صاحب ردیاب",
          0,
          "sendCommand",
          "CENTER#"
        );
        this.createTracerSettingsSwitchRow(
          "deviceSetting",
          "فعال سازی قطب مثبت شستی لادری",
          10,
          "sendCommand",
          "DOOR,1#",
          "DOOR,0#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد زمانی حالت دفاعی",
          0,
          "SelectDefenseTimeOpen",
          "DEFENSE,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تنظیم حد حساسیت لرزه",
          0,
          "SelectVibrationLevelOpen",
          "LEVEL,$#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست تماس از سوی ردیاب",
          0,
          "sendCommand",
          "JT#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست وضعیت ردیاب و باتری",
          0,
          "sendCommand",
          "Check123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "Reset123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست لینک گوگل",
          0,
          "sendCommand",
          "URL#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دستور سفارشی",
          0,
          "customCommand",
          "$"
        );
        break;
      }

      case "gp027n": {
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "تعریف مدیر (Admin)",
          0,
          "AddAdmins",
          "SOS,A,num1,num2,num3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شماره مدیرها",
          0,
          "sendCommand",
          "SOS#"
        );

        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "تعریف شماره صاحب ردیاب",
        //   0,
        //   "AddOneTracerOwner",
        //   "SETADMIN123456!$"
        // );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر اول",
          0,
          "sendCommand",
          "SOS,D,1#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر دوم",
          0,
          "sendCommand",
          "SOS,D,2#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف مدیر سوم",
          0,
          "sendCommand",
          "SOS,D,3#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "حذف تمامی مدیر ها",
          0,
          "sendCommand",
          "SOS,D,1,2,3#"
        );
        
        // this.createTracerSettingsButtonRow(
        //   "deviceSetting",
        //   "مشاهده شماره صاحب ردیاب",
        //   0,
        //   "sendCommand",
        //   "CENTER#"
        // );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست تماس از سوی ردیاب",
          0,
          "sendCommand",
          "JT#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست وضعیت ردیاب و باتری",
          0,
          "sendCommand",
          "Check123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "راه اندازی مجدد ردیاب",
          0,
          "sendCommand",
          "Reset123456"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "درخواست لینک گوگل",
          0,
          "sendCommand",
          "URL#"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "مشاهده شارژ همراه اول",
          0,
          "sendCommand",
          "BALANCE,*140*11###"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد ایرانسل",
          0,
          "sendCommand",
          "BALANCE,*555*3*4*1##"
        );

        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "فعال‌سازی تعرفه آزاد همراه اول",
          0,
          "sendCommand",
          "BALANCE,*10*231##"
        );
        this.createTracerSettingsButtonRow(
          "deviceSetting",
          "دستور سفارشی",
          0,
          "customCommand",
          "$"
        );
        break;
      }
    }

    window.handleSettingCheckbox = this.handleSettingCheckbox;
    window.AddAdmins = this.AddAdmins.bind(this);
    window.AddOneAdmin = this.AddOneAdmin.bind(this);
    window.removeOneAdmin = this.removeOneAdmin.bind(this);
    window.AddTracerOwner = this.AddTracerOwner.bind(this);
    window.AddOneTracerOwner = this.AddOneTracerOwner.bind(this);
    window.SelectDefenseTimeOpen = this.SelectDefenseTimeOpen.bind(this);
    window.SelectVibrationLevelOpen = this.SelectVibrationLevelOpen.bind(this);
    window.PowerSelectSendType = this.PowerSelectSendType.bind(this);
    window.BatterySelectSendType = this.BatterySelectSendType.bind(this);
    window.DoorSelectSendType = this.DoorSelectSendType.bind(this);
    window.TurnOnOffSelectSendType = this.TurnOnOffSelectSendType.bind(this);
    window.MovingSelectSendType = this.MovingSelectSendType.bind(this);
    window.OverSpeedSelectSendType = this.OverSpeedSelectSendType.bind(this);
    window.VibrationSelectSendType = this.VibrationSelectSendType.bind(this);
    window.VibrationRangeSelect = this.VibrationRangeSelect.bind(this);
    window.customCommand = this.customCommand.bind(this);
    window.sendCommand = this.sendCommand;
    window.AddSOS = this.AddSOS.bind(this);
    window.SOSCallSelect = this.SOSCallSelect.bind(this);
    window.SendLocationTiming = this.SendLocationTiming.bind(this);
    window.SendLocDistanse = this.SendLocDistanse.bind(this);
    window.PowerSelectSendTypePlus = this.PowerSelectSendTypePlus.bind(this);
    window.BatterySelectSendTypePlus =
      this.BatterySelectSendTypePlus.bind(this);
    window.TurnOnOffSelectSendTypePlus =
      this.TurnOnOffSelectSendTypePlus.bind(this);
    window.OverSpeedSelectSendTypePlus =
      this.OverSpeedSelectSendTypePlus.bind(this);
    window.SOSALMSelectType = this.SOSALMSelectType.bind(this);
  }

  render() {
    return (
      <div className="tracer-setting">
        <Loading loading={this.state.loading} />
        <div className="title">
          <p>تنظیمات ردیاب {this.props.title}</p>
        </div>
        <div className="half">
          <button
            onClick={() => {
              this.setState({ selectedSetting: 1 });
            }}
            className={`settingTypeBtn lightBlueBtn ${
              this.state.selectedSetting === 1 ? "selected" : ""
            }`}
          >
            تنظیم دستگاه
          </button>
        </div>
        <div className="half">
          <button
            onClick={() => {
              this.setState({ selectedSetting: 2 });
            }}
            className={`settingTypeBtn lightBlueBtn ${
              this.state.selectedSetting === 2 ? "selected" : ""
            }`}
          >
            تنظیم هشدار
          </button>
        </div>
        <div
          id="deviceSetting"
          className="settingContent"
          style={{
            display: this.state.selectedSetting === 1 ? "block" : "none",
          }}
        ></div>

        <div
          id="alertSetting"
          className="settingContent"
          style={{
            display: this.state.selectedSetting === 2 ? "block" : "none",
          }}
        ></div>
        <Modal
          isOpen={this.state.isAddAdminsOpen}
          onRequestClose={() => {
            this.setState({
              isAddAdminsOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />

          <div className="title">
            <p>تعریف مدیر</p>
          </div>
          <div className="input-div ">
            <h5>شماره مدیر اول</h5>
            <div className="input-group">
              <input id="num1" type="number" className="input num" />
            </div>
          </div>
          <div className="input-div ">
            <h5>شماره مدیر دوم</h5>
            <div className="input-group">
              <input id="num2" type="number" className="input num" />
            </div>
          </div>
          <div className="input-div ">
            <h5>شماره مدیر سوم</h5>
            <div className="input-group">
              <input id="num3" type="number" className="input num" />
            </div>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isAddAdminsOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAddSOSOpen}
          onRequestClose={() => {
            this.setState({
              isAddSOSOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />

          <div className="title">
            <p>تعریف شماره های SOS</p>
          </div>
          <div className="input-div ">
            <h5>شماره SOS (اضطراری)اول</h5>
            <div className="input-group">
              <input id="SOS1" type="number" className="input num" />
            </div>
          </div>
          <div className="input-div ">
            <h5>شماره SOS (اضطراری)دوم</h5>
            <div className="input-group">
              <input id="SOS2" type="number" className="input num" />
            </div>
          </div>
          <div className="input-div ">
            <h5>شماره SOS (اضطراری)سوم</h5>
            <div className="input-group">
              <input id="SOS3" type="number" className="input num" />
            </div>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isAddSOSOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAddTracerOwnerOpen}
          onRequestClose={() => {
            this.setState({
              isAddTracerOwnerOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>تعریف صاحب ردیاب</p>
          </div>
          <div className="input-div ">
            <h5>شماره موبایل صاحب ردیاب</h5>
            <div className="input-group">
              <input id="owner" type="number" className="input num" />
            </div>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isAddTracerOwnerOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isCustomCommandOpen}
          onRequestClose={() => {
            this.setState({
              isCustomCommandOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>دستور سفارشی</p>
          </div>
          <div className="input-div ">
            <h5>دستور</h5>
            <div className="input-group">
              <input id="customCommand" type="text" className="input " />
            </div>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isCustomCommandOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isAddOneAdminOpen}
          onRequestClose={() => {
            this.setState({
              isAddOneAdminOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>تعریف شماره مدیر</p>
          </div>
          <div className="input-div ">
            <h5>شماره موبایل مدیر</h5>
            <div className="input-group">
              <input id="oneAdminNum" type="number" className="input num" />
            </div>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isAddOneAdminOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isRemoveOneAdminOpen}
          onRequestClose={() => {
            this.setState({
              isRemoveOneAdminOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>حذف شماره مدیر</p>
          </div>
          <div className="input-div ">
            <h5>شماره موبایل مدیر</h5>
            <div className="input-group">
              <input id="removeAdminNum" type="number" className="input num" />
            </div>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isRemoveOneAdminOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isSelectDefenseTimeOpen}
          onRequestClose={() => {
            this.setState({
              isSelectDefenseTimeOpen: false,
              commandText: "",
              commandType: "",
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>تنظیم حد زمانی حالت دفاعی</p>
          </div>
          <div className="input-div ">
            <h5>تعیین زمان (بازه ۱ تا ۱۰ دقیقه)</h5>
            <InputRange
              maxValue={10}
              minValue={1}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isSelectDefenseTimeOpen: false,
                commandText: "",
                commandType: "",
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isSOSCallTimeOpen}
          onRequestClose={() => {
            this.setState({
              isSelectDefenseTimeOpen: false,
              commandText: "",
              commandType: "",
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>تعداد دفعات تماس با شماره های SOS</p>
          </div>
          <div className="input-div ">
            <h5>تعداد دفعات تماس با شماره های SOS</h5>
            <InputRange
              maxValue={3}
              minValue={1}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isSOSCallTimeOpen: false,
                commandText: "",
                commandType: "",
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isLocTimingOpen}
          onRequestClose={() => {
            this.setState({
              isLocTimingOpen: false,
              commandText: "",
              commandType: "",
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>زمان‌بندی ارسال لوکیشن</p>
          </div>
          <div className="input-div ">
            <h5>در حالت روشن</h5>
            <InputRange
              maxValue={6000}
              minValue={10}
              value={this.state.rangeONTiming}
              onChange={(value) => this.setState({ rangeONTiming: value })}
            />
          </div>
          <div className="input-div ">
            <h5>در حالت خاموش</h5>
            <InputRange
              maxValue={6000}
              minValue={10}
              value={this.state.rangeOFFTiming}
              onChange={(value) => this.setState({ rangeOFFTiming: value })}
            />
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isSOSCallTimeOpen: false,
                commandText: "",
                commandType: "",
                rangeONTiming: 10,
                rangeOFFTiming: 10,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isLocDistOpen}
          onRequestClose={() => {
            this.setState({
              isLocDistOpen: false,
              commandText: "",
              commandType: "",
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>متراژ ارسال لوکیشن</p>
          </div>
          <div className="input-div ">
            <h5>متراژ</h5>
            <InputRange
              maxValue={1000}
              minValue={50}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>

          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isLocDistOpen: false,
                commandText: "",
                commandType: "",
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isSelectVibrationOpen}
          onRequestClose={() => {
            this.setState({
              isSelectVibrationOpen: false,
              commandText: "",
              commandType: "",
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>تنظیم حد حساسیت لرزه</p>
          </div>
          <h5>میزان حساسیت</h5>
          <div className="input-div ">
            <InputRange
              maxValue={5}
              minValue={1}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isSelectVibrationOpen: false,
                commandText: "",
                commandType: "",
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isPowerSelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isPowerSelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار قطع برق</p>
          </div>
          {this.props.type === "m300-plus" ||
          this.props.type === "m500-plus" ? (
            <div>
              <h5>زمان تشخیص</h5>
              <div className="input-div ">
                <InputRange
                  maxValue={60}
                  minValue={2}
                  value={this.state.rangeValue}
                  onChange={(value) => this.setState({ rangeValue: value })}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option selected value={0}>
                آنلاین
              </option>
              <option value={1}>آنلاین و پیامک</option>
              {this.props.type !== "m100" ? (
                <option value={2}>آنلاین و پیامک و تماس</option>
              ) : (
                ""
              )}
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isPowerSelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isBatterySelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isBatterySelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار ضعف باتری</p>
          </div>
          {this.props.type === "m300-plus" ||
          this.props.type === "m500-plus" ? (
            <div>
              <h5>افت ولتاژ تا </h5>
              <div className="input-div ">
                <InputRange
                  maxValue={130}
                  minValue={100}
                  value={this.state.voltRangeValue}
                  onChange={(value) => this.setState({ voltRangeValue: value })}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.props.type === "m300-plus" ||
          this.props.type === "m500-plus" ? (
            <div>
              <h5>زمان تشخیص</h5>
              <div className="input-div ">
                <InputRange
                  maxValue={300}
                  minValue={1}
                  value={this.state.rangeValue}
                  onChange={(value) => this.setState({ rangeValue: value })}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option selected value={0}>
                آنلاین
              </option>
              <option value={1}>آنلاین و پیامک</option>
              {this.props.type !== "m100" ? (
                <option value={2}>آنلاین و پیامک و تماس</option>
              ) : (
                ""
              )}
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isBatterySelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
                rangeValue: 1,
                voltRangeValue: 100,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isDoorSelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isDoorSelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار باز شدن درب خودرو</p>
          </div>
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
                console.log(e.target.value)
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option value={0}>آنلاین</option>
              <option value={1}>آنلاین و پیامک</option>
              {this.props.type !== "m100" && this.props.type !== "m500" ? (
                <option value={2}>آنلاین و پیامک و تماس</option>
              ) : (
                ""
              )}
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isDoorSelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isTurnOnOffSelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isTurnOnOffSelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار روشن خاموش شدن خودرو</p>
          </div>

          {this.props.type === "m300-plus" ||
          this.props.type === "m500-plus" ? (
            <div>
              <h5>زمان تشخیص</h5>
              <div className="input-div ">
                <InputRange
                  maxValue={600}
                  minValue={5}
                  value={this.state.rangeValue}
                  onChange={(value) => this.setState({ rangeValue: value })}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.props.type === "m300-plus" ||
          this.props.type === "m500-plus" ? (
            <div>
              <h5>حالت </h5>
              <div className="input-group input-div ">
                <select
                  onChange={(e) => {
                    this.setState({ AccSelect: e.target.value });
                  }}
                  defaultValue={this.state.AccSelect}
                >
                  <option value={0}>اعلام خاموش و روشن</option>
                  <option value={1}>اعلام خاموش </option>
                  <option value={2}>اعلام روشن</option>
                </select>
              </div>
            </div>
          ) : (
            ""
          )}
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option value={0}>آنلاین</option>
              <option value={1}>آنلاین و پیامک</option>
              {this.props.type == "m100" ||
              this.props.type == "wg010e" ||
              this.props.type == "gt06n" ||
              this.props.type == "gt06f" ||
              this.props.type == "gt800" ||
              this.props.type == "m500" ? (
                ""
              ) : (
                <option value={2}>آنلاین و پیامک و تماس</option>
              )}
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isTurnOnOffSelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isMovingSelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isMovingSelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار جابجایی (جرثقیل)</p>
          </div>
          <h5>شعاع(متر)</h5>
          <div className="input-div ">
            <InputRange
              maxValue={1000}
              minValue={600}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option selected value={0}>
                آنلاین
              </option>
              <option value={1}>آنلاین و پیامک</option>
              {this.props.type !== "m100" && this.props.type !== "m500" ? (
                <option value={2}>آنلاین و پیامک و تماس</option>
              ) : (
                ""
              )}
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isMovingSelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isOverSpeedSelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isOverSpeedSelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار سرعیت غیر مجاز</p>
          </div>
          <h5>میزان حساسیت</h5>
          <div className="input-div ">
            <InputRange
              maxValue={255}
              minValue={50}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>
          {this.props.type === "m300-plus" ||
          this.props.type === "m500-plus" ? (
            <div>
              <h5>زمان تشخیص</h5>
              <div className="input-div ">
                <InputRange
                  maxValue={600}
                  minValue={5}
                  value={this.state.TimingRangeValue}
                  onChange={(value) =>
                    this.setState({ TimingRangeValue: value })
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option selected value={0}>
                آنلاین
              </option>
              <option value={1}>آنلاین و پیامک</option>
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isOverSpeedSelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
                rangeValue: 1,
                TimingRangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isVibrationSelectSendTypeOpen}
          onRequestClose={() => {
            this.setState({
              isVibrationSelectSendTypeOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار لرزه</p>
          </div>
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option selected value={0}>
                آنلاین
              </option>
              <option value={1}>آنلاین و پیامک</option>
              {this.props.type !== "m100" ? (
                <option value={2}>آنلاین و پیامک و تماس</option>
              ) : (
                ""
              )}
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isVibrationSelectSendTypeOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isSelectVibrationOpen}
          onRequestClose={() => {
            this.setState({
              isSelectVibrationOpen: false,
              commandText: "",
              commandType: "",
              rangeValue: 1,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار لرزه</p>
          </div>
          <h5>میزان حساسیت</h5>
          <div className="input-div ">
            <InputRange
              maxValue={15}
              minValue={1}
              value={this.state.rangeValue}
              onChange={(value) => this.setState({ rangeValue: value })}
            />
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, 0);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isSelectVibrationOpen: false,
                commandText: "",
                commandType: "",
                rangeValue: 1,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isCommandRespOpen}
          onRequestClose={() => {
            this.setState({
              isCommandRespOpen: false,
              commandText: "",
              commandType: "",
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <img
              style={{ width: "45%" }}
              src={require("../../image/done.png")}
            />
          </div>
          <p
            style={{
              textAlign: "center",
              direction: "ltr",
              marginBottom: "20px",
            }}
          >
            {this.state.commandRespText}
          </p>
          <button
            onClick={() => {
              this.setState({
                isCommandRespOpen: false,
                commandText: "",
                commandType: "",
              });
            }}
            className="blueBtn btn"
            style={{ width: "100%" }}
          >
            تایید
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isSOSALMOpen}
          onRequestClose={() => {
            this.setState({
              isSOSALMOpen: false,
              commandText: "",
              commandType: "",
              selectedSendType: 0,
            });
          }}
          style={{
            content: { top: "calc(100vh / 4)", left: "37%", right: "37%" },
          }}
        >
          <Loading loading={this.state.loading} />
          <div className="title">
            <p>هشدار کلید SOS</p>
          </div>
          <h5>ارسال از طریق</h5>
          <div className="input-group input-div">
            <select
              onChange={(e) => {
                this.setState({ selectedSendType: e.target.value });
              }}
              defaultValue={this.state.selectedSendType}
            >
              <option selected value={0}>
                آنلاین
              </option>
              <option value={1}>آنلاین و پیامک</option>

              <option value={2}>آنلاین و پیامک و تماس</option>
            </select>
          </div>
          <button
            onClick={() => {
              this.sendCommand(this.state.commandText, this.state.switchId);
            }}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            ارسال
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 40px" }}
            onClick={() => {
              this.setState({
                isSOSALMOpen: false,
                commandText: "",
                commandType: "",
                selectedSendType: 0,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
      </div>
    );
  }
}

export default withAlert(TracerSetting);
