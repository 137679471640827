import * as firebase from "firebase/app";
import "firebase/messaging";
export const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBQesKfNYdEFyrRmYELD2gmRYMTi60zsyY",
    authDomain: "gotracker-1548830893698.firebaseapp.com",
    databaseURL: "https://gotracker-1548830893698.firebaseio.com",
    projectId: "gotracker-1548830893698",
    storageBucket: "gotracker-1548830893698.appspot.com",
    messagingSenderId: "480233725499",
    appId: "1:480233725499:web:0fbfda434bd4da93"
});
let messaging = null;
if (firebase.messaging.isSupported()) {
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BGA4aSaVCPA7YgsVEgAvWBgdEonlhmQWq2XvyvOrzMSVxM_MrFZbRkKzj1-DaFyOn5dmhUndehjBF0ilSsFKbe0"
    );
}
export{messaging};
