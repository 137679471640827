import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import {config} from '../../config';
import ReactToPrint from 'react-to-print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const dateFormat = "YYYY-MM-DD";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: true
});

class MovingReports extends Component {

    constructor() {
        super();

        this.state = {
            selected: 0,
            data: [],
            selectedDataId: []
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(id) {
        let array = this.state.selectedDataId;
        if (array.includes(id)) {
            let index = array.indexOf(id);
            if (index > -1) {
                array.splice(index, 1);
            }
        } else {
            array.push(id);
        }
        this.setState({selectedDataId: array});
    }

    componentDidMount() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/reports/moving',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        let array = [];
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                console.log(resp.data.data)
                for (let i in resp.data.data) {
                    array.push(parseInt(i));
                }
                this.setState({
                    data: resp.data.data,
                    selectedDataId: array
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    render() {

        return (
            <div className='content col-lg-12 col-md-12'>
                <div className='content-head filter-div col-md-12'>
                    <ReactToPrint
                        trigger={() => <button title='پرینت' className='blueBtn print-btn' style={{float: 'left'}}><i
                            className='icon-print'/></button>}
                        content={() => this.printTbl}
                    />

                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="blueBtn excelBtn excel-btn"
                        table="exelTable"
                        filename="movingReport"
                        sheet="tablexls"
                        buttonText={<i className='icon-excel'/>}/>
                </div>
                <div className='content-body'>
                    <table className='moving tbl'>
                        <thead>
                        <tr>
                            <th><img src={require('../../image/tick.png')}/></th>
                            <th>ID</th>
                            <th>نام ردیاب</th>
                            <th>شماره تماس</th>
                            <th>سرعت(کیلومتر بر ساعت)</th>
                            <th>آخرین ارسال</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key, index) =>
                            <tr>
                                <td>
                                    <button onClick={() => {
                                        this.handleSelect(index)
                                    }}>
                                     <span className='checkbox'>
                                         <img
                                             src={require(`../../image/${this.state.selectedDataId.includes(index) ? 'checked' : 'uncheck'}.svg`)}/>
                                     </span>
                                    </button>
                                </td>
                                <td>
                                    <p>{key.id ? key.id :' '}</p>
                                </td>
                                <td>
                                    <p>{key.title}</p>
                                </td>
                                <td>
                                    <p>{key.sim_number}</p>
                                </td>
                                <td>
                                    <p>{key.last_speed}</p>
                                </td>
                                <td>
                                    <p>{moment(key.last_sent).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    <a href={`https://maps.google.com/?q=${key.last_lat},${key.last_lng}`}
                                       target='blank'>
                                        <button className='lightBlueBtn'>نمایش روی نقشه</button>
                                    </a>
                                </td>
                            </tr>
                        )}

                        </tbody>
                    </table>
                    {this.state.data.length > 0 ? this.state.data.map((key, index) =>
                        <div className="mobile-info-card row">
                            <div className="col-xs-6">
                                <label>نام ردیاب: </label>
                                <span>{key.title}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>شماره تماس: </label>
                                <span>{key.sim_number}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>سرعت(کیلومتر بر ساعت): </label>
                                <span>{key.last_speed}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>آخرین ارسال: </label>
                                <span>{moment(key.last_sent).format("jYYYY/jMM/jDD")}</span>
                            </div>
                            <div className="col-xs-12 card-btn-div" style={{padding: '0 10px'}}>
                                <div className="col-xs-12">
                                    <a href={`https://maps.google.com/?q=${key.last_lat},${key.last_lng}`}
                                       target='blank'>
                                        <button className='lightBlueBtn' style={{width:'100%'}}>نمایش روی نقشه</button>
                                    </a>
                                </div>

                            </div>
                        </div>
                    ) : <div className="mobile-info-card row"><p>در حال حاضر ردیاب در حال حرکت وجود ندارد</p></div>
                    }
                </div>
                <div className='hide'>
                    <table ref={el => (this.printTbl = el)} id={'exelTable'} className='brintTable'>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>نام ردیاب</th>
                            <th>شماره تماس</th>
                            <th>سرعت(کیلومتر بر ساعت)</th>
                            <th>آخرین ارسال</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key, index) =>
                            <tr style={{display: `${this.state.selectedDataId.includes(index) ? 'table-row' : 'none'}`}}>

                                <td>
                                    <p>{key.id ? key.id :' '}</p>
                                </td>
                                <td>
                                    <p>{key.title}</p>
                                </td>
                                <td>
                                    <p>{key.sim_number}</p>
                                </td>
                                <td>
                                    <p>{key.last_speed}</p>
                                </td>
                                <td>
                                    <p>{moment(key.last_sent).format("jYYYY/jMM/jDD")}</p>
                                </td>
                            </tr>
                        )}

                        </tbody>
                    </table>

                </div>

            </div>
        );
    }
}

export default MovingReports;