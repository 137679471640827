import React, {Component} from 'react';
import { Link , Redirect} from 'react-router-dom';
import {withAlert} from 'react-alert'
import axios from 'axios';
import * as Cookies from 'js-cookie';
import {config} from '../config';



class Forgetpass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSend: false
        };
        this.handleGetCode = this.handleGetCode.bind(this);
    }


    handleGetCode() {

        let phone = document.getElementById('mobile').value;

        let data ={
            'mobile' : phone
        };

        console.log(data);

        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/auth/password/forget',
            data: data
        };
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({
                    isSend: true,
                });
                Cookies.set('phone', phone);
            }
        }).catch(err => {
            console.log(err);
        });


    }

    render() {
        return (
            <div className='App form-group'>
                <div className='img-div col-lg-9 col-md-8 col-sm-6 hidden-xs'>
                        <span>
                        </span>
                </div>
                <div className='left-div col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                        <div>
                        <h5>فراموشی رمز عبور</h5>
                        <p className='blue'>جهت دریافت رمز عبور، شماره موبایل خود را وارد کنید</p>
                        <span className='line'></span>
                            <form>
                                <div className='input-div '>
                                    <h5>شماره موبایل</h5>
                                    <div className='input-group'>
                                        <i className='icon-phone inputIcon'></i>
                                        <input id='mobile' className='input num' placeholder='09...'/>
                                    </div>
                                </div>

                            </form>
                        <div className='button-div blueBtn'>
                            {this.state.isSend ? <Redirect to='/Resetpass' /> :''}
                            <button onClick={this.handleGetCode}>
                                <h5>ادامه</h5>
                            </button>
                        </div>
                        <div className='button-div pinkBtn'>
                            <Link to={'/login'}><button><h5>انصراف</h5></button></Link>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Forgetpass;