import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import {withAlert} from 'react-alert'
import {config} from '../../config';
import 'rc-time-picker/assets/index.css';
import Loading from "../Loading";

let map;
let bounds  = new window.google.maps.LatLngBounds();
let loc;
let drawingManager;

class UpdateArea extends Component{

    constructor(props) {
        super(props);

        this.state={
            data: [],
            mapTypesListIsOpen: false,
            isSaveAreaOpen:false,
            mapType: 'roadmap',
            tracers:[],
            selectedTracer:0,
            sendSms:0,
            areaTitle:'',
            loading:false
        };

        this.coordinates = [];

        this.onScriptLoad = this.onScriptLoad.bind(this);
        this.closeMapTypsList = this.closeMapTypsList.bind(this);
        this.reloadMap = this.reloadMap.bind(this);
        this.getUpdatingArea = this.getUpdatingArea.bind(this);
        this.removePolygon = this.removePolygon.bind(this);
        this.updateEditingArea = this.updateEditingArea.bind(this);
    }
    onScriptLoad(mapType) {
        let access_token = "pk.eyJ1Ijoic29iaW1vciIsImEiOiJjajZuYmlicXowNmtmMzNqbnVmaHBkamFvIn0.nxr--YcWiqkbXzMQePit3w";
        map = new window.google.maps.Map(
            document.getElementById("myMap"),
            {
                center: { lat: 38.0843038, lng: 46.270212 },
                zoom: 14,
                zoomControl: false,
                mapTypeControl: true,
                mapTypeId: mapType
            });
        map.mapTypes.set("OSM", new window.google.maps.ImageMapType({
            getTileUrl: function(coord, zoom) {
                return "https://tile.openstreetmap.org/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
            },
            tileSize: new window.google.maps.Size(256, 256),
            name: "OpenStreetMap",
            maxZoom: 18
        }));
        map.mapTypes.set("mpx-street", new window.google.maps.ImageMapType({
            getTileUrl: function(coord, zoom) {
                return "https://api.tiles.mapbox.com/v4/mapbox.streets/" + zoom + "/" + coord.x + "/" + coord.y + ".png?access_token="+ access_token;
            },
            tileSize: new window.google.maps.Size(256, 256),
            name: "mpx-street",
            maxZoom: 18
        }));
        map.mapTypes.set("mpx-satellite", new window.google.maps.ImageMapType({
            getTileUrl: function(coord, zoom) {
                return "https://api.tiles.mapbox.com/v4/mapbox.streets-satellite/" + zoom + "/" + coord.x + "/" + coord.y + ".png?access_token="+ access_token;
            },
            tileSize: new window.google.maps.Size(256, 256),
            name: "mpx-satellite",
            maxZoom: 18
        }));
        map.mapTypes.set("mpx-dark", new window.google.maps.ImageMapType({
            getTileUrl: function(coord, zoom) {
                return "https://api.tiles.mapbox.com/v4/mapbox.dark/" + zoom + "/" + coord.x + "/" + coord.y + ".png?access_token="+ access_token;
            },
            tileSize: new window.google.maps.Size(256, 256),
            name: "mpx-dark",
            maxZoom: 18
        }));


        drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            polygonOptions:{
                fillColor: '#3D8DF9',
                strokeColor: '#3D8DF9',
                draggable: true,
                geodesic: true
            }

        });

        drawingManager.setMap(map);

        window.google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon) =>{

            let path = polygon.getPath();

            for (let i = 0 ; i < path.length ; i++) {
                this.coordinates.push({
                    lat: path.getAt(i).lat(),
                    lng: path.getAt(i).lng()
                });
            }
            this.setState({isSaveAreaOpen: true});

        });

    }
    chanegMapType(type){
        this.onScriptLoad(type);
        this.setState({mapType: type , isInfoBoxOpen: false});
    }
    closeMapTypsList(){
        this.setState({mapTypesListIsOpen: false});
    }
    reloadMap(){
        this.onScriptLoad(this.state.mapType);
        this.setState({isSaveAreaOpen: false})
    }
    updateEditingArea(){
        this.setState({loading: true});
        console.log(this.coordinates);
        let data = {
            'send_sms' : this.state.sendSms ,
            'name': this.state.areaTitle ,
            'coordinates': this.coordinates
        };
        let req = {
            method: 'put',
            url: config.baseUrl + 'api/v1/clients/tracers/'+this.state.selectedTracer+'/geo-areas/'+this.props.match.params.geoId,
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({loading:false});
                this.props.history.push('/areaslist');
                this.props.alert.success('تغییرات با موفقیت ثبت شد.');
            }

        }).catch((err) => {
            console.log(err);
            this.setState({loading:false});
            this.props.alert.error('مشکلی پیش آمده دوباره تلاش کنید.');
        });
    }
    getUpdatingArea(){
        this.setState({loading: true ,isSaveAreaOpen: true});
        this.coordinates = [];

        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers/'+this.props.match.params.tracerId+'/geo-areas/'+this.props.match.params.geoId,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {

                this.coordinates = resp.data.data.coordinates;
                drawingManager = new window.google.maps.Polygon({
                    drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                    drawingControl: false,
                    fillColor: '#3D8DF9',
                    strokeColor: '#3D8DF9',
                    draggable: true,
                    geodesic: true,
                    paths: this.coordinates

                });
                for(let i in this.coordinates) {
                    loc = new window.google.maps.LatLng(this.coordinates[i].lat, this.coordinates[i].lng);
                    bounds.extend(loc);
                }
                console.log(this.coordinates);
                drawingManager.setMap(map);
                map.fitBounds(bounds , 150);
                map.panToBounds(bounds);

                this.setState({
                    areaTitle: resp.data.data.name,
                    selectedTracer: resp.data.data.tracer.id,
                    sendSms: resp.data.data.send_sms,
                    loading:false
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    removePolygon(){
        this.coordinates = [];
        drawingManager.setMap(null);
    }
    componentDidMount() {

        const appendChild = Element.prototype.appendChild;

        const urlCatchers = [
            "/AuthenticationService.Authenticate?",
            "/QuotaService.RecordEvent?"
        ];

        Element.prototype.appendChild = function (element) {
            const isGMapScript = element.tagName === 'SCRIPT' && /maps\.googleapis\.com/i.test(element.src);
            const isGMapAccessScript = isGMapScript && urlCatchers.some(url => element.src.includes(url));

            if (!isGMapAccessScript) {
                return appendChild.call(this, element);
            }
            return element;
        };

        this.onScriptLoad("roadmap");

        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    tracers: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });

        this.getUpdatingArea();
    }

    render(){


        return(
            <div>
                <Loading loading={this.state.loading}/>
                <div id={"myMap"} className='Map col-lg-12 col-md-12'/>

                <button className='map-btn' style={{bottom: '70px'}} onClick={this.reloadMap}>
                    <i className='icon-mapRefresh'></i>
                </button>
                <button className='map-btn' style={{bottom: '20px'}} onClick={()=>this.setState({mapTypesListIsOpen: true})}>
                    <i className='icon-world'></i>
                </button>

                <div className={'infoBox saveArea'} style={{display: this.state.isSaveAreaOpen === true ? 'block' : 'none'}}>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان</h5>
                            <div className='input-group'>
                                <input defaultValue={this.state.areaTitle} onChange={(e)=>{this.setState({areaTitle: e.target.value})}} className='input' placeholder='محدوده شرکت' style={{textAlign: 'right' , paddingRight: '0' , direction:'rtl'}} />
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>ردیاب</h5>
                            <div className='input-group'>
                                <select disabled  defaultValue={this.state.selectedTracer}>
                                    <option value={0}> ردیاب</option>
                                    {this.state.tracers.map((key)=>
                                        <option selected={this.state.selectedTracer === key.id ? true : false} value={parseInt(key.id)}>{key.title}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>نحوه اطلاع رسانی(در صورت خروج از محدوده مشخص شده)</h5>
                        <div className='input-group'>
                            <select defaultValue={this.state.sendSms} onChange={(e)=>{this.setState({sendSms:e.target.value})}}>
                                <option value={0}>ناتیفیکیشن</option>
                            </select>
                        </div>
                    </div>
                    <button onClick={this.updateEditingArea} className='blueBtn btn' style={{padding: '12px 30px'}}>ذخیره محدوده انتخاب شده</button>
                    <button className='pinkBtn btn' style={{marginRight: '12px' , padding: '12px 15px'}} onClick={this.removePolygon}>حذف محدوده انتخابی</button>
                </div>
                <Modal
                    isOpen={this.state.mapTypesListIsOpen}
                    onRequestClose={this.closeMapTypsList}
                    style={{content : {top: 'calc(100vh / 5)'  ,left: '40%' , right: '40%'}}}
                >
                    <p>نقشه ها</p>
                    <ul>
                        <li>
                            <button onClick={()=>this.chanegMapType("roadmap")}>
                                <img src={require(`../../image/Ellipse-${this.state.mapType === "roadmap" ?'1':'0'}@2x.png`)}/>
                                <p>نقشه پیش فرض Google</p>
                                <span className='radio'>
                            <img src={require(`../../image/radio${this.state.mapType === "roadmap" ? 'S' : 'U'}.png`)}/>
                            </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>this.chanegMapType("satellite")}>
                                <img src={require(`../../image/Ellipse-${this.state.mapType === "satellite" ?'1':'0'}@2x.png`)}/>
                                <p>نقشه ماهواره ای Google</p>
                                <span className='radio'>
                            <img src={require(`../../image/radio${this.state.mapType === "satellite" ? 'S' : 'U'}.png`)}/>
                            </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>this.chanegMapType("OSM")}>
                                <img src={require(`../../image/Ellipse-${this.state.mapType === "OSM" ?'1':'0'}@2x.png`)}/>
                                <p>نقشه openstrest</p>
                                <span className='radio'>
                            <img src={require(`../../image/radio${this.state.mapType === "OSM" ? 'S' : 'U'}.png`)}/>
                            </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>this.chanegMapType("mpx-street")}>
                                <img src={require(`../../image/Ellipse-${this.state.mapType === "mpx-street" ? '1' : '0'}@2x.png`)}/>
                                <p>نقشه mapbox street</p>
                                <span className='radio'>
                                    <img src={require(`../../image/radio${this.state.mapType === "mpx-street" ? 'S' : 'U'}.png`)}/>
                                </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>this.chanegMapType("mpx-satellite")}>
                                <img src={require(`../../image/Ellipse-${this.state.mapType === "mpx-satellite" ? '1' : '0'}@2x.png`)}/>
                                <p>نقشه ماهواره ای mapbox</p>
                                <span className='radio'>
                                    <img src={require(`../../image/radio${this.state.mapType === "mpx-satellite" ? 'S' : 'U'}.png`)}/>
                                </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={()=>this.chanegMapType("mpx-dark")}>
                                <img src={require(`../../image/Ellipse-${this.state.mapType === "mpx-dark" ? '1' : '0'}@2x.png`)}/>
                                <p>حالت تاریک نقشه mapbox</p>
                                <span className='radio'>
                                    <img src={require(`../../image/radio${this.state.mapType === "mpx-dark" ? 'S' : 'U'}.png`)}/>
                                </span>
                            </button>
                        </li>
                    </ul>
                </Modal>

            </div>
        );
    }
}
export default withAlert(UpdateArea);