import React, { Component } from "react";
import Modal from "react-modal";
import axios from "axios";
import * as Cookies from "js-cookie";
import moment from "moment-jalaali";
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import { DatetimePickerTrigger } from "imrc-datetime-picker";
import Pagination from "react-js-pagination";

import { config } from "../../config";
import Loading from "../Loading";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const dateFormat = "YYYY-MM-DD HH:mm:00";

moment.loadPersian({
  dialect: "persian-modern",
  usePersianDigits: true,
});

class SpeedReports extends Component {
  constructor() {
    super();

    const defaultMoment = moment();

    this.state = {
      data: [],
      selected: 0,
      from: defaultMoment,
      to: defaultMoment,
      selectedTracker: 0,
      speedReportToken: "",
      speedRepData: [],
      speedExportData: [],
      selectedDataId: [],
      currPage: 1,
      totalItem: 0,
      loading: false,
      timeOut: 0,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFromDate = this.handleFromDate.bind(this);
    this.handleToDate = this.handleToDate.bind(this);
    this.getSpeedReports = this.getSpeedReports.bind(this);
    this.checkSpeedReports = this.checkSpeedReports.bind(this);
    this.exportSpeedReports = this.exportSpeedReports.bind(this);
  }

  handleSelect(id) {
    let array = this.state.selectedDataId;
    if (array.includes(id)) {
      let index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    this.setState({ selectedDataId: array });
  }

  handleChange(e) {
    this.setState({ selectedTracker: e.target.value });
  }

  handleFromDate(moment) {
    this.setState({ from: moment });
  }

  handleToDate(moment) {
    this.setState({ to: moment });
  }

  getSpeedReports() {
    if (this.state.selectedTracker === 0) {
      return;
    }
    this.setState({ loading: true });
    let start = this.state.from.utc().locale("en").format(dateFormat);
    let end = this.state.to.utc().locale("en").format(dateFormat);
    let speed = document.getElementById("speed").value | 0;

    let req = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/reports/speed/request?id=" +
        this.state.selectedTracker +
        "&start_date=" +
        start +
        "&end_date=" +
        end +
        "&min_speed=" +
        speed,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };

    console.log(req.url);

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({ speedReportToken: resp.data.data.token });
          setTimeout(this.checkSpeedReports(1), 2000);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    this.state.from.local().locale("fa");
    this.state.to.local().locale("fa");
  }

  checkSpeedReports(page) {
    this.setState({ currPage: page });
    let check = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/reports/speed/check/" +
        this.state.speedReportToken +
        "?page=" +
        page,
    };
    let array = [];
    axios(check)
      .then((resp) => {
        if (resp.data.requestStatus === 3) {
          for (let i in resp.data.data) {
            array.push(parseInt(i));
          }

          this.setState({
            speedRepData: resp.data.data,
            totalItem: resp.data.total,
            loading: false,
            selectedDataId: array,
          });
          this.exportSpeedReports(page);
        } else {
          if (this.state.timeOut <= 120000) {
            setTimeout(function () {
              this.checkSpeedReports(this.state.currPage)
            }.bind(this), 2000);
            this.setState({ timeOut: this.state.timeOut + 2000 });
          } else {
            this.props.alert.error(
              "مشکلی در پاسخ دهی سرور آمد، لطفا دوباره امتحان کنید."
            );
            this.setState({ timeOut: 0, loading: false });
          }
        }
      })
      .catch((err) => {
        console.log(err.response.message);
      });
  }

  exportSpeedReports(page) {
    this.setState({ currPage: page });
    let check = {
      method: "get",
      url:
        config.baseUrl +
        "api/v1/clients/reports/speed/export/" +
        this.state.speedReportToken +
        "?page=1",
    };
    let array = [];
    axios(check)
      .then((resp) => {
        if (resp.data.requestStatus === 3) {
          for (let i in resp.data.data) {
            array.push(parseInt(i));
          }
          this.setState({
            speedExportData: resp.data.data.locations,
            loading: false,
            selectedDataId: array,
          });
        } else {
          this.props.alert.error(
            "مشکلی در پاسخ دهی سرور آمد، لطفا دوباره امتحان کنید."
          );
        }
      })
      .catch((err) => {
        console.log(err.response.message);
      });
  }

  componentDidMount() {
    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/clients/tracers",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            data: resp.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const shortcuts = {
      امروز: moment(),
      دیروز: moment().subtract(1, "days"),
    };
    return (
      <div className="content col-lg-12 col-md-12">
        <Loading loading={this.state.loading} />
        <div className="content-head filter-div speed-filter col-md-12">
          <p style={{ fontSize: "11px" }}>نمایش بر اساس </p>
          <div className="input-group">
            <select onChange={this.handleChange}>
              <option disabled selected>
                عنوان ردیاب
              </option>
              {this.state.data.map((key) => (
                <option value={key.id}>{key.title}</option>
              ))}
            </select>
          </div>
          <div className="input-group">
            <p style={{ marginBottom: "0", width: "35%" }}>حداقل سرعت</p>
            <input
              id="speed"
              className="input num"
              style={{ width: "60%" }}
              type="number"
            />
          </div>
          <DatetimePickerTrigger
            shortcuts={shortcuts}
            moment={this.state.from}
            onChange={this.handleFromDate}
            position="buttom"
            lang="fa"
            isSolar={true}
          >
            <div className="input-group">
              <p style={{ marginBottom: "0", width: "30%" }}>تاریخ شروع</p>
              <input
                className="input"
                style={{ width: "70%", fontSize: "12px" }}
                type="text"
                value={this.state.from.format("jYYYY/jMM/jDD HH:mm")}
                readOnly
              />
            </div>
          </DatetimePickerTrigger>
          <DatetimePickerTrigger
            shortcuts={shortcuts}
            moment={this.state.to}
            onChange={this.handleToDate}
            position="buttom"
            lang="fa"
            isSolar={true}
          >
            <div className="input-group">
              <p style={{ marginBottom: "0", width: "30%" }}>تاریخ پایان</p>
              <input
                className="input"
                style={{ width: "70%", fontSize: "12px" }}
                type="text"
                value={this.state.to.format("jYYYY/jMM/jDD HH:mm")}
                readOnly
              />
            </div>
          </DatetimePickerTrigger>
          <button className="blueBtn" onClick={this.getSpeedReports}>
            نمایش گزارش
          </button>

          <ReactToPrint
            trigger={() => (
              <button
                title="پرینت"
                className="blueBtn print-btn"
                style={{ float: "left" }}
              >
                <i className="icon-print" />
              </button>
            )}
            content={() => this.printTbl}
          />

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="blueBtn excelBtn excel-btn"
            table="exelTable"
            filename={this.state.selectedTracker}
            sheet="tablexls"
            buttonText={<i className="icon-excel" />}
          />
        </div>
        <div className="content-body">
          <table className="tbl speed">
            <thead>
              <tr>
                <th>
                  <img src={require("../../image/tick.png")} />
                </th>
                <th>سرعت(کیلومتر بر ساعت)</th>
                <th>تاریخ</th>
                <th>گزینه ها</th>
              </tr>
            </thead>
            <tbody>
              {this.state.speedRepData.map((key, index) => (
                <tr>
                  <td>
                    <button
                      onClick={() => {
                        this.handleSelect(index);
                      }}
                    >
                      <span className="checkbox">
                        <img
                          src={require(`../../image/${this.state.selectedDataId.includes(index)
                              ? "checked"
                              : "uncheck"
                            }.svg`)}
                        />
                      </span>
                    </button>
                  </td>
                  <td>
                    <p>{key.speed}</p>
                  </td>
                  <td>
                    <p style={{ direction: "ltr" }}>
                      {moment(key.sent_at).format("jYYYY/jMM/jDD HH:mm")}
                    </p>
                  </td>
                  <td>
                    <a
                      href={`https://maps.google.com/?q=${key.lat},${key.lng}`}
                      target="blank"
                    >
                      <button className="lightBlueBtn">نمایش روی نقشه</button>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {this.state.speedRepData.map((key, index) => (
            <div className="mobile-info-card row">
              <div className="col-xs-12 text-center">
                <label>سرعت(کیلومتر بر ساعت): </label>
                <p>{key.speed}</p>
              </div>
              <div className="col-xs-12 text-center">
                <label>تاریخ: </label>
                <p style={{ direction: "ltr" }}>
                  {moment(key.sent_at).format("jYYYY/jMM/jDD HH:mm")}
                </p>{" "}
              </div>

              <div
                className="col-xs-12 card-btn-div"
                style={{ padding: "0 10px" }}
              >
                <div className="col-xs-12">
                  <a
                    href={`https://maps.google.com/?q=${key.lat},${key.lng}`}
                    target="blank"
                  >
                    <button className="lightBlueBtn" style={{ width: "100%" }}>
                      نمایش روی نقشه
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}

          <Pagination
            activePage={this.state.currPage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.totalItem}
            pageRangeDisplayed={5}
            onChange={this.checkSpeedReports}
          />
        </div>
        <div className="hide">
          <table
            ref={(el) => (this.printTbl = el)}
            id={"exelTable"}
            className="brintTable"
          >
            <thead>
              <tr>
                <th>سرعت(کیلومتر بر ساعت)</th>
                <th>تاریخ</th>
              </tr>
            </thead>
            <tbody>
              {this.state.speedExportData.map((key, index) => (
                <tr
                  style={{
                    display: `${this.state.selectedDataId.includes(index)
                        ? "table-row"
                        : "table-row"
                      }`,
                  }}
                >
                  <td>
                    <p>{key.speed}</p>
                  </td>
                  <td>
                    <p style={{ direction: "ltr" }}>
                      {moment(key.sent_at).format("jYYYY/jMM/jDD HH:mm")}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SpeedReports;
