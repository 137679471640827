import React, {Component} from 'react';
import {Redirect , Link} from 'react-router-dom';
import {withAlert} from 'react-alert'
import Modal from 'react-modal';
import axios from 'axios';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import Pagination from "react-js-pagination";
import {config} from '../../config';
import Loading from "../Loading";
const nav = require('./nav.json');
const dateFormat = "YYYY-MM-DD HH:mm:00" ;


Modal.setAppElement(document.body);


class Sidebar extends Component {

    constructor(props) {
        super(props);

        const defaultMoment = moment();

        this.state = {
            sub: 0,
            activeItem: 1,
            isAddTrackerOpen: false,
            isIMEIRegisterOpen: false,
            isManualRegisterOpen:false,
            isAddNewUserOpen: false,
            isAdminAddTrackerOpen: false,
            isAddNewServiceGroupOpen: false,
            singleCreatedAt: defaultMoment,
            tracerTypes:[],
            roles:[],
            selectedRoles:[],
            selectedType:0,
            groupTypes:[],
            usageTypes:[],
            selectedUsage:0,
            title:'',
            serial:'',
            fuel:'',
            simNumber:'',
            starterCode:'',
            driverName:'',
            loading:false
        };

        this.openSub = this.openSub.bind(this);
        this.closeAddTracker = this.closeAddTracker.bind(this);
        this.openAddIMEI = this.openAddIMEI.bind(this);
        this.openAddManual = this.openAddManual.bind(this);
        this.goToAddTracker = this.goToAddTracker.bind(this);
        this.closeAddNewUser = this.closeAddNewUser.bind(this);
        this.openAddNewUser = this.openAddNewUser.bind(this);
        this.openAdminAddTracker = this.openAdminAddTracker.bind(this);
        this.closeAdminAddTracker = this.closeAdminAddTracker.bind(this);
        this.afterAdminAddTrackerOpen = this.afterAdminAddTrackerOpen.bind(this);
        this.selectRole = this.selectRole.bind(this);
        this.addNewUser = this.addNewUser.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.AddSingleTracer = this.AddSingleTracer.bind(this);
        this.AddServiceGroup = this.AddServiceGroup.bind(this);
        this.afterAddServiceGroupOpen = this.afterAddServiceGroupOpen.bind(this);
        this.afterAddManualTrackerOpen = this.afterAddManualTrackerOpen.bind(this);
        this.saveNewTracer = this.saveNewTracer.bind(this);
        this.getStarterCode = this.getStarterCode.bind(this);
    }

    openSub(subId) {
        if (this.state.sub === subId) {
            this.setState({sub: 0});
        } else {
            this.setState({sub: subId});
        }
    }

    closeAddTracker(){
        this.setState({
            fuel:'' ,
            title: '',
            starterCode: '',
            serial: '' ,
            simNumber:'',
            driverName:'',
            selectedUsage:0,
            isAddTrackerOpen:false,
            isIMEIRegisterOpen:false,
            isManualRegisterOpen:false
        })
    }
    openAddIMEI(){
        this.setState({isIMEIRegisterOpen: true , isAddTrackerOpen: false})
    }
    openAddManual(){
        this.setState({isManualRegisterOpen: true , isAddTrackerOpen: false})
    }
    goToAddTracker(){
        this.setState({isIMEIRegisterOpen: false , isManualRegisterOpen:false, isAddTrackerOpen: true})
    }
    openAddNewUser(){
        this.setState({isAddNewUserOpen: true});
    }
    closeAddNewUser(){
        this.setState({isAddNewUserOpen: false});
    }
    openAdminAddTracker(){
        this.setState({isAdminAddTrackerOpen: true});

    }
    afterAdminAddTrackerOpen(){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/tracers/types',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    tracerTypes: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    closeAdminAddTracker(){
        this.setState({isAdminAddTrackerOpen: false});
    }
    selectRole(id){
        let array = this.state.selectedRoles;
        if(array.includes(id)){
            let index=array.indexOf(id);
            array.splice(index,1);
        }
        else{
            array.push(id);
        }
        this.setState({selectedRoles: array});
    }

    addNewUser(){
        let data = {
            'mobile' : document.getElementById('user-phone').value.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9') ,
            'password': document.getElementById('user-pass').value ,
            'mcode': document.getElementById('user-mcode').value ,
            'name' : document.getElementById('user-name').value,
            'email':'',
            'role_ids':this.state.selectedRoles
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/admins/users',
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        console.log(data);
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({isAddNewUserOpen: false});
                this.props.alert.success('ثبت با موفقیت انجام شد.')
            }

        }).catch((err) => {
            console.log(err);
        });
    }
    handleTypeChange(e){
        this.setState({selectedType: e.target.value});
    }

    AddSingleTracer(){
        let data = {
            'imei' : document.getElementById('Add_imei').value.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9') ,
            'manufacturer' : document.getElementById('Add_Manufacturer').value ,
            'manufactured_at': this.state.singleCreatedAt.locale('en').format("YYYY/MM/DD") ,
            'type_id': this.state.selectedType
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/admins/tracers/',
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({isAdminAddTrackerOpen: false });
                this.props.alert.success('ثبت با موفقیت انجام شد.');
            }

        }).catch((err) => {
            console.log(err);
            this.props.alert.error({err}.err.response.data.errors[0].field + ' ' +{err}.err.response.data.errors[0].message);
        });
        this.state.manufacturedAt.locale('fa');
    }

    AddServiceGroup(){
        let data = {
            'name' : document.getElementById('service-title').value ,
            'type_id': this.state.selectedType
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/admins/services/groups',
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        console.log(data);

        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({selectedType:0 , isAddNewServiceGroupOpen: false});
                this.props.alert.success('گروه سرویس جدید با موفقیت ثبت شد.');
            }

        }).catch((err) => {
            console.log(err);
        });
    }

    afterAddServiceGroupOpen(){
        let typsReq = {
            method: 'get',
            url: config.baseUrl + 'api/v1/admins/services/groups/types',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(typsReq).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    groupTypes: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    afterAddManualTrackerOpen(){
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers/usages',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    usageTypes: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    saveNewTracer(){
        this.setState({loading:true});
        let data = {
            'starter' : this.state.starterCode.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9') ,
            'serial': this.state.serial.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9'),
            'title': this.state.title,
            'driver_name': this.state.driverName,
            'sim_number': this.state.simNumber.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9'),
            'usage_id': this.state.selectedUsage,
            'fuel_usage': this.state.fuel.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9'),
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/clients/tracers',
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };

        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                this.setState({
                    selectedUsage:0,
                    isManualRegisterOpen: false ,
                    loading:false ,
                    fuel:'' ,
                    title: '',
                    starterCode: '',
                    serial: '' ,
                    simNumber:'',
                    driverName:''
                });
                this.props.alert.success('اطلاعات ردیاب با موفقیت ثبت شد.');
            }

        }).catch((err) => {
            if(err.response.data.status === 0){
                this.setState({loading:false});
                this.props.alert.error('تمامی فیلد ها را به دقت پر کنید.');
            }
        });
    }

    getStarterCode(){
        this.setState({loading:true});
        let imei = document.getElementById('addImei').value.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9');
        if(imei != null){
            let req = {
                method: 'get',
                url: config.baseUrl + 'api/v1/clients/tracers/imei/'+imei,
                headers: {
                    Authorization: "Bearer " + config.token
                }
            };

            axios(req
            ).then((resp) => {
                if(resp.data.status === 1) {
                    this.setState({starterCode:resp.data.data.starter , serial:resp.data.data.serial , isManualRegisterOpen: true , loading:false , isIMEIRegisterOpen:false});

                    document.getElementById('serial').readOnly = true;
                    document.getElementById('starterCode').readOnly = true;
                }

            }).catch((err) => {
                this.setState({loading:false});
                this.props.alert.error(err.response.data.errors[0].message);
            });
        }else {
            this.setState({loading:false});
            this.props.alert.error('این فیلد اجباری است.');
        }
    }

    componentWillMount(){

            let req = {
                method: 'get',
                url: config.baseUrl + 'api/v1/admins/roles',
                headers: {
                    Authorization: "Bearer " + config.token
                }
            };
            axios(req).then((resp) => {
                if (resp.data.status === 1) {
                    this.setState({
                        roles: resp.data.data
                    });
                }
            }).catch(err => {
                console.log(err);
            });

        for(let i of nav){
            if( i.role === window.sessionStorage.getItem("role") || i.role === window.localStorage.getItem('role')) {
                if (i.submenus.length > 0) {
                    for (let item of i.submenus) {
                        if (item.title === this.props.path) {
                            this.setState({activeItem: item.id});
                            console.log(item.id);
                        }
                    }
                } else {
                    if (i.title === this.props.path) {
                        this.setState({activeItem: i.id});
                    }
                }
            }
        }

        if(!window.localStorage.getItem('prmsn')){
            window.localStorage.setItem('isLogedin' , false);

        }
    }

    render() {

        const shortcuts = {
            'امروز': moment(),
            'دیروز': moment().subtract(1, 'days')
        };

        return (
            <div className='sideBar col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <Loading loading={this.state.loading}/>
                {!window.localStorage.getItem('prmsn') ? <Redirect to='/login'/> : ''}
                <div className='sideBarHeader'>
                    <div className='right'>
                        <img src={require('../../image/logo.png')} alt='Go Tracker'/>
                    </div>
                    <div className='left'>
                        <h6>سامانه مدیریت ردیاب ها</h6>
                        <p>Go Tracker</p>
                    </div>
                </div>
                <div className='sidebar-content'>
                    <ul>
                        {nav.map((key) => {
                            if (key.role === (window.sessionStorage.getItem("role") || window.localStorage.getItem('role')) || key.role === "") {
                                if (window.localStorage.getItem('prmsn')) {
                                    if (window.localStorage.getItem('prmsn').split(',').includes(key.permissionId)) {
                                        return (<li
                                            className={`${this.state.activeItem === key.id && key.submenus.length === 0 ? 'selected ' : key.submenus.length > 0 ? 'hasSubmenu ' : ''}`}
                                            onClick={() => {
                                                if (key.submenus.length === 0) {
                                                    this.setState({activeItem: key.id});
                                                }
                                            }}
                                        >
                                            {key.submenus.length > 0 ?
                                                <button onClick={() => {
                                                    this.openSub(key.id)
                                                }}>
                                                    <i className={key.icon}/>
                                                    <p>{key.title}</p>
                                                    <i className={`arw icon-dropdown ${this.state.sub === key.id ? 'up' : ''}`}/>
                                                </button>
                                                :
                                                <Link to={`/${key.path}`} onClick={() => {
                                                    this.props.changeTitle(key.title);
                                                    this.props.closeSidebar();
                                                }}>
                                                    <i className={key.icon}/>
                                                    <p>{key.title}</p>
                                                </Link>
                                            }
                                            {key.submenus.length > 0 ?
                                                <ul className={this.state.sub === key.id ? '' : 'invisible'}>
                                                    {key.submenus.map((subkey) => {
                                                            if (key.role === (window.sessionStorage.getItem("role") || window.localStorage.getItem('role')) || key.role === "") {
                                                                if (window.localStorage.getItem('prmsn').split(',').includes(subkey.permissionId)) {

                                                                    return (<li
                                                                        className={this.state.activeItem === subkey.id ? 'selected ' : ''}
                                                                        onClick={() => {
                                                                            this.setState({activeItem: subkey.id});
                                                                        }}
                                                                    >
                                                                        {subkey.path === "modal" ?
                                                                            <button
                                                                                onClick={() => this.setState({[subkey.modal]: true})}>
                                                                                <i className={subkey.icon}/>
                                                                                <p>{subkey.title}</p>
                                                                            </button> :
                                                                            <Link to={`/${subkey.path}`} onClick={() => {
                                                                                this.props.changeTitle(subkey.title);
                                                                                this.props.closeSidebar()

                                                                            }}>
                                                                                <i className={subkey.icon}/>
                                                                                <p>{subkey.title}</p>
                                                                            </Link>
                                                                        }
                                                                    </li>)
                                                                }
                                                            }
                                                        }
                                                    )}
                                                </ul> : ''
                                            }

                                        </li>)
                                    }
                                }
                            }

                        }

                        )}
                    </ul>
                </div>
                <Modal
                    isOpen={this.state.isAddTrackerOpen}
                    onRequestClose={this.closeAddTracker}
                    style={{content : {top: 'calc(100vh / 5)' ,left: '40%' , right: '40%'}}}
                >
                    <div className='title'>
                        <p>افزودن ردیاب</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center'}}>یکی از روش های افزودن ردیاب را انتخاب کنید</p>
                    <div className='btn-div'>
                        <button className='btn lightBlue' style={{padding: '40px'}} onClick={this.openAddIMEI}>
                            <i className='icon-imei'/>
                        </button>
                        <p>از طریق IMEI</p>
                    </div>
                    <div className='btn-div'>
                        <button className='btn lightBlue' style={{padding: '40px 37px'}} onClick={this.openAddManual}>
                            <i className='icon-register'/>
                        </button>
                        <p>به صورت دستی</p>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.isIMEIRegisterOpen}
                    onRequestClose={this.closeAddTracker}
                    style={{content : {top: 'calc(100vh / 5)' ,left: '40%' , right: '40%'}}}
                >
                    <div className='title'>
                        <p>وارد کردن IMEI</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>در قسمت پایین کد IMEI ردیاب خود را وارد کنید.</p>
                    <div className='input-div '>
                        <h5>کد IMEI</h5>
                        <div className='input-group'>
                            <input id='addImei' className='input num' placeholder='12...'/>
                        </div>
                    </div>
                    <button onClick={this.getStarterCode} className='blueBtn btn' style={{padding: '15px 50px'}}>مرحله بعد</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 25px'}} onClick={this.goToAddTracker}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isManualRegisterOpen}
                    onAfterOpen={this.afterAddManualTrackerOpen}
                    onRequestClose={this.closeAddTracker}
                    style={{content : {top: 'calc(100vh / 8)' , left: '30%' , right: '30%'}}}
                >
                    <div className='title'>
                        <p>افزودن اطلاعات ردیاب</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات ردیاب را کامل کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان</h5>
                            <div className='input-group'>
                                <input name='title' className='input num' onChange={(e)=>{this.setState({[e.target.name]: e.target.value});}} defaultValue={this.state.title}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>سریال</h5>
                            <div className='input-group'>
                                <input id='serial' name='serial' className='input num' onChange={(e)=>{this.setState({[e.target.name]: e.target.value});}} defaultValue={this.state.serial}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>شماره سیم کارت</h5>
                            <div className='input-group'>
                                <input name='simNumber' className='input num' onChange={(e)=>{this.setState({[e.target.name]: e.target.value});}} defaultValue={this.state.simNumber}/>
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>کد راه انداز</h5>
                            <div className='input-group'>
                                <input id='starterCode' name='starterCode' className='input num' onChange={(e)=>{this.setState({[e.target.name]: e.target.value});}} defaultValue={this.state.starterCode}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>نام راننده</h5>
                            <div className='input-group'>
                                <input name='driverName' className='input' onChange={(e)=>{this.setState({[e.target.name]: e.target.value});}} defaultValue={this.state.driverName}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>میزان مصرف سوخت (لیتر)</h5>
                            <div className='input-group'>
                                <input name='fuel' className='input num' onChange={(e)=>{this.setState({[e.target.name]: e.target.value});}} defaultValue={this.state.fuel}/>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>استفاده در</h5>
                        <div className='input-group'>
                            <select onChange={(e)=>{this.setState({selectedUsage: e.target.value});}} defaultValue={this.state.selectedUsage}>
                                <option disabled value={0}>نوع استفاده</option>
                                {this.state.usageTypes.map((key)=>
                                    <option value={key.id}>{key.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <button onClick={this.saveNewTracer} className='blueBtn btn' style={{padding: '15px 145px'}}>ذخیره</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 70px'}} onClick={this.goToAddTracker}>انصراف</button>

                </Modal>
                <Modal
                    isOpen={this.state.isAddNewUserOpen}
                    onRequestClose={this.closeAddNewUser}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن کاربر جدید</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات کاربر جدید را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>نام و نام خانوادگی</h5>
                            <div className='input-group'>
                                <input id='user-name' className='input' />
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>کد ملی</h5>
                            <div className='input-group'>
                                <input id='user-mcode' className='input num' placeholder='12...'/>
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>شماره موبایل</h5>
                            <div className='input-group'>
                                <input id='user-phone'  className='input num' placeholder='12...'/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>رمز عبور</h5>
                            <div className='input-group'>
                                <input id='user-pass'  className='input' type={'password'} placeholder='*****'/>
                            </div>
                        </div>
                    </div>
                    <div className='input-div ' style={{padding: '0 10px'}}>
                        <h5>وضعیت</h5>
                        <div className='input-group'>
                            <select onChange={this.handleChange}>
                                <option disabled default selected>وضعیت</option>
                                <option >فعال</option>
                                <option >غیر فعال </option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <p style={{display:'inline-block' , fontSize:'13px'}}>گروه های دسترسی:</p>
                        <div style={{display:'inline-block' , padding:'0 10px'}}>
                            {this.state.roles.map((key)=>
                                <button onClick={()=>this.selectRole(key.id)}>
                                    <p style={{display:'inline-block' , fontSize:'11px' , marginLeft:'5px'}}>{key.name}</p>
                                    <span className='checkbox'>
                                     <img src={require(`../../image/${this.state.selectedRoles.includes(key.id) ? 'checked' : 'uncheck'}.svg`)}/>
                                    </span>
                                </button>
                            )}
                        </div>
                    </div>
                    <button onClick={this.addNewUser} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت نام</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={this.closeAddNewUser}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isAdminAddTrackerOpen}
                    onAfterOpen={this.afterAdminAddTrackerOpen}
                    onRequestClose={this.closeAdminAddTracker}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن ردیاب جدید</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات ردیاب جدید را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>کد IMEI</h5>
                            <div className='input-group'>
                                <input id='Add_imei' className='input' />
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>نوع ردیاب</h5>
                            <div className='input-group'>
                                <select onChange={this.handleTypeChange}>
                                    <option disabled selected default>نوع ردیاب</option>
                                    {this.state.tracerTypes.map((key)=>
                                        <option value={key.id}>{key.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>سازنده</h5>
                            <div className='input-group'>
                                <input id='Add_Manufacturer' className='input'/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>تاریخ ساخت</h5>
                            <DatetimePickerTrigger
                                shortcuts={shortcuts}
                                moment={this.state.singleCreatedAt}
                                onChange={(moment)=>{this.setState({singleCreatedAt: moment})}}
                                showTimePicker={false}
                                position="buttom"
                                lang='fa'
                                isSolar={true}
                            >
                                <div className='input-group'>
                                    <input className='input' type="text"
                                           value={this.state.singleCreatedAt.format("jYYYY/jMM/jDD")}
                                           readOnly/>
                                </div>
                            </DatetimePickerTrigger>
                        </div>
                    </div>
                    <button onClick={this.AddSingleTracer} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت نام</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={this.closeAdminAddTracker}>انصراف</button>
                </Modal>
                <Modal
                    isOpen={this.state.isAddNewServiceGroupOpen}
                    onAfterOpen={this.afterAddServiceGroupOpen}
                    onRequestClose={()=>{this.setState({isAddNewServiceGroupOpen: false , selectedType:0})}}
                    style={{content : {top: 'calc(100vh / 8)' , left: '35%' , right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن گروه سرویس</p>
                    </div>
                    <p style={{fontSize: '12px' , textAlign: 'center', marginBottom: '40px' , color:'#B5B5B5'}}>اطلاعات گروه سرویس جدید را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان گروه سرویس</h5>
                            <div className='input-group'>
                                <input id='service-title' className='input' />
                            </div>
                        </div>
                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>نوع گروه سرویس</h5>
                            <div className='input-group'>
                                <select onChange={this.handleTypeChange}>
                                    <option selected disabled>نوع</option>
                                    {this.state.groupTypes.map((key)=>
                                        <option value={key.id}>{key.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <button onClick={this.AddServiceGroup} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت</button>
                    <button className='pinkBtn btn' style={{marginRight: '15px' , padding: '15px 50px'}} onClick={()=>{
                        this.setState({isAddNewServiceGroupOpen: false , selectedType:0})
                    }}>انصراف</button>
                </Modal>
            </div>
        )
    }
}

export default withAlert(Sidebar);