import React, {Component} from 'react';
import { Link , Redirect} from 'react-router-dom';
import './login.css';
import {withAlert} from 'react-alert'
import axios from 'axios';
import * as Cookies from 'js-cookie';
import {config} from '../../config';
import Loading from "../Loading";
import Modal from 'react-modal';
import * as io from 'socket.io-client';

class Login extends Component{

    constructor(props){
        super(props);
        this.state={
            checkbox: true,
            loading:false,
            isLogedIn: false,
            role: ''
        };

        this.socket = io('http://socket.irgogps.com');

        this.alert = false;
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    handleCheckbox(){
        this.setState({checkbox : !this.state.checkbox});
    }

    signIn(){
        this.setState({loading: true});
        let phone = document.getElementById('phone').value.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9');
        let pass = document.getElementById('pass').value;
        console.log(phone);
        let data = {
            'mobile' : phone ,
            'password': pass
        };
        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/auth/login',
            data: data
        };
        if((phone.length < 1) || (pass.length <1)) {
            this.props.alert.info('فیلد ها را پر کنید.');
            this.setState({loading: false});
        }else{
            axios(req
            ).then((resp) => {
                if(resp.data.status === 1) {
                    let permisions = [];
                    for(let item of resp.data.data.roles[0].permissions){
                        permisions.push(item.id);
                    }
                    permisions.push(0);
                    window.localStorage.setItem('prmsn', permisions);
                    window.localStorage.setItem('token', resp.data.token);
                    window.localStorage.setItem('isLogedin' , true);
                    if(resp.data.data.roles[0].slug === "client"){
                        window.localStorage.setItem('role' , resp.data.data.roles[0].slug);
                    }else{
                        window.localStorage.setItem('role' , "administrator");
                    }
                    config.token = resp.data.token;
                    this.setState({isLogedIn: true , role: resp.data.data.roles[0].slug , loading:false});
                    this.props.alert.success('ورود با موفقیت انجام شد.');
                }
            }).catch(err => {
                this.setState({loading:false});
                this.props.alert.error({err}.err.response.data.errors[0].message);
            });
        }

        localStorage.setItem('remember', this.state.checkbox);

        if(this.state.checkbox) {
            localStorage.setItem('mobile', document.getElementById('phone').value);
        }
    }

    componentDidMount(){

        this.setState({isLogedIn: localStorage.getItem('isLogedin')});
        let remember = localStorage.getItem('remember');
        if(remember === 'true') {
            document.getElementById('phone').value = localStorage.getItem('mobile');
        }

        this.socket.emit('disconnect');
    }

    render(){
        return(
            <div className='App form-group'>
                <Loading loading={this.state.loading}/>
                <div className='img-div col-lg-9 col-md-8 col-sm-6 hidden-xs'>
                        <span>
                        </span>
                </div>
                <div className='left-div col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                <form>
                <h5>ورود به حساب کاربری</h5>
                <p className='blue'>برای ورود به حساب کاربری فیلد های زیر را پر کنید</p>
                <span className='line'/>
                <div className='input-div '>
                    <h5>شماره موبایل</h5>
                    <div className='input-group'>
                        <i className='icon-phone inputIcon'/>
                        <input id='phone' className='input num' placeholder='09...'/>
                    </div>
                </div>
                <div className='input-div' style={{marginBottom: '10px'}}>
                    <h5>رمز</h5>
                    <div className='input-group'>
                        <i className='icon-password inputIcon'/>
                        <input id='pass' className='input' autoComplete type='password' placeholder='****'/>
                    </div>
                </div>
                <div className='remindDiv' onClick={this.handleCheckbox}>
                    <span className='checkbox'>
                    <img src={require(`../../image/${this.state.checkbox === true ? 'checked' : 'uncheck'}.svg`)}/>
                    </span>
                    <p className='blue'>مرا به خاطر بسپار</p>
                </div>
                <div className='forgetDiv'>
                    <Link to={'/forgetpass'}><p className='blue'>فراموشی رمز عبور</p></Link>
                </div>
                <div className='button-div blueBtn'>
                    {localStorage.getItem('isLogedin') === 'true' ? <Redirect to='/'/> :''}
                    <Link to={localStorage.getItem('isLogedin') === 'true' ? '/' :'/login'}>
                        <button onClick={this.signIn}>
                        <h5>ورود</h5>
                    </button>
                    </Link>
                </div>
                <div className='button-div'>
                    <Link to={'/Register'}>
                    <h5>ثبت نام</h5>

                    </Link>
                </div>
                </form>
                </div>


            </div>
        );
    }
}
export default withAlert(Login);