import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withAlert} from 'react-alert';
import Modal from 'react-modal';
import axios from 'axios';
import moment from 'moment-jalaali';
import {config} from '../../config';
import Loading from "../Loading";

class GeoAreasList extends Component {

    constructor() {
        super();

        this.state = {
            selected: 0,
            data: [],
            isDeleteAreaOpen: false,
            selectedTracer: 0,
            selectedArea: 0,
            loading: true
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.getGeoAreas = this.getGeoAreas.bind(this);
        this.deleteArea = this.deleteArea.bind(this);
    }

    handleSelect(selected) {
        if (this.state.selected === selected) {
            this.setState({selected: 0});
        } else {
            this.setState({selected: selected});
        }
    }

    getGeoAreas() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers/geo-areas',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    data: resp.data.data,
                    loading: false
                });
                if (resp.data.data.length === 0) {
                    this.props.alert.info('محدوده ای برای نمایش وجود ندارد.');
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    deleteArea() {
        this.setState({loading: true});
        let req = {
            method: 'delete',
            url: config.baseUrl + 'api/v1/clients/tracers/' + this.state.selectedTracer + '/geo-areas/' + this.state.selectedArea,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    isDeleteAreaOpen: false,
                    selectedTracer: 0,
                    selectedService: 0,
                    loading: false
                });
                this.props.alert.success('محدوده مورد نظر با موفقیت حذف شد.');
                this.getGeoAreas();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    componentDidMount() {
        this.getGeoAreas();
    }

    render() {
        return (
            <div className='content col-lg-12 col-md-12'>
                <Loading loading={this.state.loading}/>
                <div className='content-head'>
                    <Link to={'/AddArea'}>
                        <button className='blueBtn'>افزودن محدوده</button>
                    </Link>
                </div>
                <div className='content-body'>
                    <table className='geo tbl'>
                        <thead>
                        <tr>
                            <th>عنوان</th>
                            <th>ردیاب</th>
                            <th>وضعیت پیامک</th>
                            <th>تاریخ ایجاد</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key) =>
                            <tr>

                                <td>
                                    <p>{key.name}</p>
                                </td>
                                <td>
                                    <p>{key.tracer != null ? key.tracer.title : ""}</p>
                                </td>
                                <td>
                                    <p>{key.send_sms}</p>
                                </td>
                                <td>
                                    <p>{key.created_at != null ? moment(key.created_at).format("jYYYY/jMM/jDD") : ""}</p>
                                </td>
                                <td>
                                    {key.tracer != null ?
                                        <Link to={`/updatearea/${key.tracer.id }/${key.id}`}>
                                            <button className='edit-btn' title='ویرایش'>
                                                <i className='icon-edit'/>
                                            </button>
                                        </Link>:""
                                    }
                                    {key.tracer != null ?
                                        <button onClick={() => {
                                            this.setState({
                                                isDeleteAreaOpen: true,
                                                selectedTracer: key.tracer != null ? key.tracer.id : "",
                                                selectedArea: key.id
                                            })
                                        }} className='delete-btn' title='حذف'>
                                            <i className='icon-delete'/>
                                        </button> : ""
                                    }
                                </td>
                            </tr>
                        )}

                        </tbody>
                    </table>

                    {this.state.data.map((key) =>
                        <div className="mobile-info-card row">
                            <div className="col-xs-6">
                                <label>عنوان محدوده: </label>
                                <span>{key.name}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>عنوان ردیاب: </label>
                                <span>{key.tracer != null ? key.tracer.title : ""}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>وضعیت پیامک: </label>
                                <span>{key.send_sms}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>تاریخ ایجاد: </label>
                                <p>{key.created_at != null ? moment(key.created_at).format("jYYYY/jMM/jDD") : ""}</p>
                            </div>
                            <div className="col-xs-12 card-btn-div" style={{padding: '0 10px'}}>
                                <div className="col-xs-6">
                                    {key.tracer != null ?
                                        <Link to={`/updatearea/${key.tracer.id }/${key.id}`}>
                                            <button className='edit-btn' title='ویرایش'>
                                                ویرایش
                                            </button>
                                        </Link>:""
                                    }
                                </div>
                                <div className="col-xs-6">
                                {key.tracer != null ?
                                    <button onClick={() => {
                                        this.setState({
                                            isDeleteAreaOpen: true,
                                            selectedTracer: key.tracer != null ? key.tracer.id : "",
                                            selectedArea: key.id
                                        })
                                    }} className='delete-btn' title='حذف'>
                                        حذف
                                    </button> : ""
                                }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    isOpen={this.state.isDeleteAreaOpen}
                    onRequestClose={() => {
                        this.setState({isDeleteAreaOpen: false, selectedTracer: 0})
                    }}
                    style={{content: {top: 'calc(100vh / 8)', left: '35%', right: '35%'}}}
                >
                    <div className='title'>
                        <p>حذف محدوده</p>
                    </div>
                    <p style={{fontSize: '14px', textAlign: 'center', marginBottom: '40px'}}>آیا از حذف این محدوده
                        اطمینان دارید؟</p>
                    <button onClick={this.deleteArea} className='blueBtn btn' style={{padding: '15px 85px'}}>بله
                    </button>
                    <button className='pinkBtn btn' style={{marginRight: '15px', padding: '15px 75px'}} onClick={() => {
                        this.setState({isDeleteAreaOpen: false, selectedTracer: 0})
                    }}>انصراف
                    </button>
                </Modal>
            </div>
        );
    }
}

export default withAlert(GeoAreasList);