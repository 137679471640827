import React, {Component} from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import * as Cookies from 'js-cookie';
import {withAlert} from 'react-alert';
import moment from 'moment-jalaali';
import "imrc-datetime-picker/dist/imrc-datetime-picker.css";
import {DatetimePickerTrigger} from 'imrc-datetime-picker';
import Pagination from "react-js-pagination";
import {config} from '../../config';
import Loading from "../Loading";

moment.loadPersian({
    dialect: "persian-modern",
    usePersianDigits: true
});

class Services extends Component {

    constructor() {
        super();
        const defaultMoment = moment();

        this.state = {
            selected: 0,
            data: [],
            tracers: [],
            groups: [],
            isAddServiceOpen: false,
            isDeleteServiceOpen: false,
            createDate: defaultMoment,
            selectedTracer: 0,
            selectedGroup: 0,
            selectedService: 0,
            durationType: 0,
            durationDate: defaultMoment,
            duration: '',
            title: '',
            loading: true,
            isEditingServiceOpen: false
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.getTracerData = this.getTracerData.bind(this);
        this.getGroupsData = this.getGroupsData.bind(this);
        this.saveNewService = this.saveNewService.bind(this);
        this.deleteService = this.deleteService.bind(this);
        this.getServicesData = this.getServicesData.bind(this);
        this.editService = this.editService.bind(this);
        this.updateEditingService = this.updateEditingService.bind(this);
    }

    handleSelect(selected) {
        if (this.state.selected === selected) {
            this.setState({selected: 0});
        } else {
            this.setState({selected: selected});
        }
    }

    getServicesData() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers/services',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                console.log(resp.data);
                this.setState({
                    data: resp.data.data,
                    loading: false
                });
                if (resp.data.data.length === 0) {
                    this.props.alert.info('سرویسی برای نمایش وجود ندارد.');
                }
            }
        }).catch(err => {
            console.log(err);
        });
    }

    getTracerData() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    tracers: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    getGroupsData() {
        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers/services/groups',
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    groups: resp.data.data
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    saveNewService() {
        const {durationType, duration, durationDate, createDate} = this.state;
        let dateDiff = durationDate.diff(createDate, 'days');

        if (this.state.title === '' || this.state.selectedGroup === 0 || this.state.selectedTracer === 0) {
            this.props.alert.error('فیلد ها را به دقت پر کنید.');
        } else {
            this.setState({loading: true});
            let data = {
                'title': this.state.title,
                'group_id': this.state.selectedGroup,
                'started_at': this.state.createDate.locale("en").format("YYYY-MM-DD"),
                'duration': durationType === '1' ? duration : durationType === '2' ? dateDiff : ''
            };
            console.log(data);
            let req = {
                method: 'post',
                url: config.baseUrl + 'api/v1/clients/tracers/' + this.state.selectedTracer + '/services',
                headers: {
                    Authorization: "Bearer " + config.token
                },
                data: data,
            };
            axios(req
            ).then((resp) => {
                if (resp.data.status === 1) {
                    this.setState({
                        isAddServiceOpen: false,
                        loading: false,
                        selectedTracer: 0,
                        selectedGroup: 0,
                        durationType: 0
                    });
                    this.props.alert.success('ثبت با موفقیت انجام شد.');
                    this.getServicesData();
                }

            }).catch((err) => {
                this.setState({isAddServiceOpen: false, loading: false});
                this.props.alert.error('مشکلی پیش آمده، مجددا تلاش کنید.');
            });
        }

        this.state.createDate.locale("fa");
        this.state.durationDate.locale("fa");
    }

    deleteService() {
        this.setState({loading: true});
        let req = {
            method: 'delete',
            url: config.baseUrl + 'api/v1/clients/tracers/' + this.state.selectedTracer + '/services/' + this.state.selectedService,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    isDeleteServiceOpen: false,
                    selectedTracer: 0,
                    selectedService: 0,
                    loading: false
                });
                this.props.alert.success('سرویس مورد نظر با موفقیت حذف شد.');
                this.getServicesData();
            }
        }).catch(err => {
            console.log(err);
        });
    }

    editService(tracerId, serviceId) {
        this.setState({loading: true});
        let durationDate;

        let req = {
            method: 'get',
            url: config.baseUrl + 'api/v1/clients/tracers/' + tracerId + '/services/' + serviceId,
            headers: {
                Authorization: "Bearer " + config.token
            }
        };
        axios(req).then((resp) => {
            if (resp.data.status === 1) {
                if (resp.data.data.group.type.id === 2) {
                    durationDate = moment(resp.data.data.started_at).add(resp.data.data.duration, 'days');
                }
                this.setState({
                    title: resp.data.data.title,
                    selectedTracer: resp.data.data.tracer.id,
                    selectedGroup: resp.data.data.group.id,
                    durationType: resp.data.data.group.type.id,
                    createDate: moment(resp.data.data.started_at),
                    durationDate: durationDate,
                    duration: resp.data.data.duration,
                    isEditingServiceOpen: true,
                    loading: false,
                    selectedService: serviceId

                });
            }
        }).catch(err => {
            this.setState({loading: false});

        });
    }

    updateEditingService() {
        this.setState({loading: true});
        const {durationType, duration, durationDate, createDate} = this.state;
        let dateDiff;
        if (durationType === 2) {
            dateDiff = durationDate.diff(createDate, 'days');
        }


        let data = {
            'title': this.state.title,
            'duration': durationType === 1 ? duration : durationType === 2 ? dateDiff : ''
        };

        console.log(data);

        let req = {
            method: 'put',
            url: config.baseUrl + 'api/v1/clients/tracers/' + this.state.selectedTracer + '/services/' + this.state.selectedService,
            headers: {
                Authorization: "Bearer " + config.token
            },
            data: data,
        };
        axios(req
        ).then((resp) => {
            if (resp.data.status === 1) {
                this.setState({
                    isEditingServiceOpen: false,
                    loading: false,
                    selectedTracer: 0,
                    selectedGroup: 0,
                    durationType: 0
                });
                this.props.alert.success('تغییرات با موفقیت ثبت شد.');
                this.getServicesData();
            }

        }).catch((err) => {
            this.setState({isAddServiceOpen: false, loading: false});
            this.props.alert.error('مشکلی پیش آمده، مجددا تلاش کنید.');
        });
    }

    componentDidMount() {
        this.getServicesData()
        this.getTracerData();
        this.getGroupsData();
    }

    render() {
        const shortcuts = {
            'امروز': moment(),
            'دیروز': moment().subtract(1, 'days')
        };

        return (
            <div className='content col-lg-12 col-md-12'>
                <Loading loading={this.state.loading}/>
                <div className='content-head'>
                    <button onClick={() => {
                        this.setState({isAddServiceOpen: true})
                    }} className='blueBtn'>افزودن سرویس
                    </button>
                </div>
                <div className='content-body'>
                    <table className='tbl service'>
                        <thead>
                        <tr>
                            <th><img src={require('../../image/tick.png')}/></th>
                            <th>عنوان</th>
                            <th>ردیاب</th>
                            <th>تاریخ شروع</th>
                            <th>نوع سرویس</th>
                            <th>وضعیت</th>
                            <th>گزینه ها</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map((key) =>
                            <tr>
                                <td>
                                    <button onClick={() => {
                                        this.handleSelect(1)
                                    }}>
                                     <span className='checkbox'>
                                         <img
                                             src={require(`../../image/${this.state.selected === 1 ? 'checked' : 'uncheck'}.svg`)}/>
                                     </span>
                                    </button>
                                </td>
                                <td>
                                    <p>{key.title}</p>
                                </td>
                                <td>
                                    <p>{key.tracer.title}</p>
                                </td>
                                <td>
                                    <p>{moment(key.started_at).format("jYYYY/jMM/jDD")}</p>
                                </td>
                                <td>
                                    <p>{key.group.name}</p>
                                </td>
                                <td>
                                    <p>{key.group.type.id === 1 ? `${parseFloat(key.left).toFixed(2)} کیلومتر باقیمانده` : `${key.left} روز باقیمانده`}</p>
                                </td>
                                <td>
                                    <button onClick={() => {
                                        this.editService(key.tracer.id, key.id)
                                    }} className='edit-btn' title='ویرایش'>
                                        <i className='icon-edit'/>
                                    </button>
                                    <button onClick={() => {
                                        this.setState({
                                            isDeleteServiceOpen: true,
                                            selectedTracer: key.tracer.id,
                                            selectedService: key.id
                                        })
                                    }} className='delete-btn' title='حذف'>
                                        <i className='icon-delete'/>
                                    </button>
                                </td>
                            </tr>
                        )}

                        </tbody>
                    </table>

                    {this.state.data.map((key) =>
                        <div className="mobile-info-card row">
                            <div className="col-xs-6">
                                <label>عنوان سرویس: </label>
                                <span>{key.title}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>نوع: </label>
                                <span>{key.group.name}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>عنوان ردیاب: </label>
                                <span>{key.tracer.title}</span>
                            </div>
                            <div className="col-xs-6">
                                <label>تاریخ شروع: </label>
                                <span>{moment(key.started_at).format("jYYYY/jMM/jDD")}</span>
                            </div>
                            <div className="col-xs-12">
                                <div className={`${key.left > 5 ? 'conf-btn' : 'delete-btn'} duration-span`}>
                                    <i className='icon-time'/>
                                    <span>{key.group.type.id === 1 ? `${parseFloat(key.left).toFixed(2)} کیلومتر باقیمانده` : `${key.left} روز باقیمانده`}</span>
                                </div>
                            </div>
                            <div className="col-xs-12 card-btn-div" style={{padding: '0 10px'}}>
                                <div className="col-xs-6">
                                    <button onClick={() => {
                                        this.editService(key.tracer.id, key.id)
                                    }} className='edit-btn' title='ویرایش'>ویرایش
                                    </button>
                                </div>

                                <div className="col-xs-6">
                                    <button onClick={() => {
                                        this.setState({
                                            isDeleteServiceOpen: true,
                                            selectedTracer: key.tracer.id,
                                            selectedService: key.id
                                        })
                                    }} className='delete-btn' title='حذف'>حذف
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <Modal
                    isOpen={this.state.isAddServiceOpen}
                    onRequestClose={() => {
                        this.setState({isAddServiceOpen: false, selectedGroup: 0, selectedTracer: 0, durationType: 0})
                    }}
                    style={{content: {top: 'calc(100vh / 8)', left: '35%', right: '35%'}}}
                >
                    <div className='title'>
                        <p>افزودن سرویس دوره ای</p>
                    </div>
                    <p style={{fontSize: '12px', textAlign: 'center', marginBottom: '40px', color: '#B5B5B5'}}>اطلاعات
                        سرویس دوره ای جدید را وارد کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان</h5>
                            <div className='input-group'>
                                <input name='title' className='input' onChange={(e) => {
                                    this.setState({title: e.target.value})
                                }}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5> ردیاب</h5>
                            <div className='input-group'>
                                <select onChange={(e) => {
                                    this.setState({selectedTracer: e.target.value})
                                }}>
                                    <option disabled selected default> ردیاب</option>
                                    {this.state.tracers.map((key) =>
                                        <option value={key.id}>{key.title}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>گروه</h5>
                            <div className='input-group'>
                                <select onChange={(e) => {
                                    this.setState({selectedGroup: e.target.value[0], durationType: e.target.value[2]})
                                }}>
                                    <option disabled selected value={0}>گروه</option>
                                    {this.state.groups.map((key) =>
                                        <option value={[key.id, key.type.id]}>{key.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>تاریخ ساخت</h5>
                            <DatetimePickerTrigger
                                shortcuts={shortcuts}
                                moment={this.state.createDate}
                                onChange={(moment) => {
                                    this.setState({createDate: moment})
                                }}
                                showTimePicker={false}
                                position="buttom"
                                lang='fa'
                                isSolar={true}
                            >
                                <div className='input-group'>
                                    <input className='input' type="text"
                                           value={this.state.createDate.format("jYYYY/jMM/jDD")}
                                           readOnly/>
                                </div>
                            </DatetimePickerTrigger>
                        </div>
                    </div>
                    {this.state.durationType === '1' ?
                        <div className='input-div '>
                            <h5>کارکرد سرویس بر حسب مسافت(کیلومتر):</h5>
                            <div className='input-group'>
                                <input name='title' className='input num' type='number' onChange={(e) => {
                                    this.setState({duration: e.target.value})
                                }}/>
                            </div>
                        </div> :
                        this.state.durationType === '2' ?
                            <div className='input-div '>
                                <h5>کارکرد سرویس بر حسب زمان:</h5>
                                <DatetimePickerTrigger
                                    shortcuts={shortcuts}
                                    moment={this.state.durationDate}
                                    onChange={(moment) => {
                                        this.setState({durationDate: moment})
                                    }}
                                    showTimePicker={false}
                                    position="top"
                                    lang='fa'
                                    isSolar={true}
                                >
                                    <div className='input-group'>
                                        <input className='input' type="text"
                                               value={this.state.durationDate.format("jYYYY/jMM/jDD")}
                                               readOnly/>
                                    </div>
                                </DatetimePickerTrigger>
                            </div> : ''
                    }

                    <button onClick={this.saveNewService} className='blueBtn btn' style={{padding: '15px 100px'}}>ثبت
                    </button>
                    <button className='pinkBtn btn' style={{marginRight: '15px', padding: '15px 50px'}} onClick={() => {
                        this.setState({isAddServiceOpen: false, selectedGroup: 0, selectedTracer: 0, durationType: 0})
                    }}>انصراف
                    </button>
                </Modal>
                <Modal
                    isOpen={this.state.isEditingServiceOpen}
                    onRequestClose={() => {
                        this.setState({
                            isEditingServiceOpen: false,
                            selectedGroup: 0,
                            selectedTracer: 0,
                            durationType: 0
                        })
                    }}
                    style={{content: {top: 'calc(100vh / 8)', left: '35%', right: '35%'}}}
                >
                    <div className='title'>
                        <p>ویرایش سرویس دوره ای</p>
                    </div>
                    <p style={{fontSize: '12px', textAlign: 'center', marginBottom: '40px', color: '#B5B5B5'}}>اطلاعات
                        سرویس دوره ای را ویرایش کنید.</p>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>عنوان</h5>
                            <div className='input-group'>
                                <input defaultValue={this.state.title} name='title' className='input' onChange={(e) => {
                                    this.setState({title: e.target.value})
                                }}/>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5> ردیاب</h5>
                            <div className='input-group'>
                                <select disabled={true} defaultValue={this.state.selectedTracer} onChange={(e) => {
                                    this.setState({selectedTracer: e.target.value})
                                }}>
                                    <option disabled selected default> ردیاب</option>
                                    {this.state.tracers.map((key) =>
                                        <option value={key.id}>{key.title}</option>
                                    )}
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className='half'>
                        <div className='input-div '>
                            <h5>گروه</h5>
                            <div className='input-group'>
                                <select disabled={true} defaultValue={this.state.selectedGroup} onChange={(e) => {
                                    this.setState({selectedGroup: e.target.value[0], durationType: e.target.value[2]})
                                }}>
                                    <option disabled selected value={0}>گروه</option>
                                    {this.state.groups.map((key) =>
                                        <option value={[key.id, key.type.id]}>{key.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className='input-div '>
                            <h5>تاریخ ساخت</h5>
                            <div className='input-group'>
                                <input className='input' type="text"
                                       value={this.state.createDate.format("jYYYY/jMM/jDD")}
                                       readOnly/>
                            </div>
                        </div>
                    </div>
                    {parseInt(this.state.durationType) === 1 ?
                        <div className='input-div '>
                            <h5>کارکرد سرویس بر حسب مسافت(کیلومتر):</h5>
                            <div className='input-group'>
                                <input defaultValue={this.state.duration} name='title' className='input num'
                                       type='number' onChange={(e) => {
                                    this.setState({duration: e.target.value})
                                }}/>
                            </div>
                        </div> :
                        parseInt(this.state.durationType) === 2 ?
                            <div className='input-div '>
                                <h5>کارکرد سرویس بر حسب زمان:</h5>
                                <DatetimePickerTrigger
                                    shortcuts={shortcuts}
                                    moment={this.state.durationDate}
                                    onChange={(moment) => {
                                        this.setState({durationDate: moment})
                                    }}
                                    showTimePicker={false}
                                    position="top"
                                    lang='fa'
                                    isSolar={true}
                                >
                                    <div className='input-group'>
                                        <input className='input' type="text"
                                               value={this.state.durationDate.format("jYYYY/jMM/jDD")}
                                               readOnly/>
                                    </div>
                                </DatetimePickerTrigger>
                            </div> : ''
                    }

                    <button onClick={this.updateEditingService} className='blueBtn btn' style={{padding: '15px 90px'}}>
                        ثبت تغییرات
                    </button>
                    <button className='pinkBtn btn' style={{marginRight: '15px', padding: '15px 50px'}} onClick={() => {
                        this.setState({
                            isEditingServiceOpen: false,
                            selectedGroup: 0,
                            selectedTracer: 0,
                            durationType: 0
                        })
                    }}>انصراف
                    </button>
                </Modal>
                <Modal
                    isOpen={this.state.isDeleteServiceOpen}
                    onRequestClose={() => {
                        this.setState({isDeleteServiceOpen: false, selectedTracer: 0, selectedService: 0})
                    }}
                    style={{content: {top: 'calc(100vh / 8)', left: '35%', right: '35%'}}}
                >
                    <div className='title'>
                        <p>حذف سرویس</p>
                    </div>
                    <p style={{fontSize: '14px', textAlign: 'center', marginBottom: '40px'}}>آیا از حذف این سرویس
                        اطمینان دارید؟</p>
                    <button onClick={this.deleteService} className='blueBtn btn' style={{padding: '15px 85px'}}>بله
                    </button>
                    <button className='pinkBtn btn' style={{marginRight: '15px', padding: '15px 75px'}} onClick={() => {
                        this.setState({isDeleteServiceOpen: false, selectedTracer: 0, selectedService: 0})
                    }}>انصراف
                    </button>
                </Modal>
            </div>
        );
    }
}

export default withAlert(Services);