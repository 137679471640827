import React , {Component} from 'react';

export default class Loading extends Component{
    render(){
        return(
            <div className='loading-div' style={{display: this.props.loading ? 'block' : 'none'}}>
                <img src={require('../image/loadin.png')} />
            </div>
        )
    }
}