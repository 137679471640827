import React, { Component } from "react";
import Modal from "react-modal";
import axios from "axios";
import { withAlert } from "react-alert";
import moment from "moment-jalaali";
import { config } from "../../config";
import Loading from "../Loading";
import TracerSetting from "./TracerSetting";
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

let timeOut;

class TrackersList extends Component {
  constructor() {
    super();

    this.state = {
      selected: 0,
      isAddTrackerOpen: false,
      isIMEIRegisterOpen: false,
      isManualRegisterOpen: false,
      isUpdateTracerOpen: false,
      isDeleteTracerOpen: false,
      isTracerSettingOpen: false,
      data: [],
      selectedDataId: [],
      usageTypes: [],
      selectedUsage: 0,
      title: "",
      serial: "",
      fuel: "",
      simNumber: "",
      starterCode: "",
      driverName: "",
      loading: true,
      editingTracerId: 0,
      tracerSettingTitle: "",
      tracerSettingType: "",
      tracerSettingId: 0,
      selectedSetting: 1,
      settingChecked: [0],
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.getTracerData = this.getTracerData.bind(this);
    this.closeAddTracker = this.closeAddTracker.bind(this);
    this.openAddIMEI = this.openAddIMEI.bind(this);
    this.openAddManual = this.openAddManual.bind(this);
    this.goToAddTracker = this.goToAddTracker.bind(this);
    this.saveNewTracer = this.saveNewTracer.bind(this);
    this.getStarterCode = this.getStarterCode.bind(this);
    this.editTracer = this.editTracer.bind(this);
    this.updateEditingTracer = this.updateEditingTracer.bind(this);
    this.deleteTracer = this.deleteTracer.bind(this);
    this.handleSettingCheckbox = this.handleSettingCheckbox.bind(this);
  }

  handleSelect(id) {
    let array = this.state.selectedDataId;
    if (array.includes(id)) {
      let index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    this.setState({ selectedDataId: array });
  }

  getTracerData() {
    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/clients/tracers",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    let array = [];
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          console.log(resp.data.data);
          for (let i in resp.data.data) {
            array.push(resp.data.data[i].id);
          }
          this.setState({
            selectedDataId: array,
            data: resp.data.data,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSettingCheckbox(id) {
    let array = this.state.settingChecked;
    if (array.includes(id)) {
      let index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    this.setState({ settingChecked: array });
  }

  closeAddTracker() {
    this.setState({
      fuel: "",
      title: "",
      starterCode: "",
      serial: "",
      simNumber: "",
      driverName: "",
      selectedUsage: 0,
      isAddTrackerOpen: false,
      isIMEIRegisterOpen: false,
      isManualRegisterOpen: false,
    });
  }

  openAddIMEI() {
    this.setState({ isIMEIRegisterOpen: true, isAddTrackerOpen: false });
  }

  openAddManual() {
    this.setState({ isManualRegisterOpen: true, isAddTrackerOpen: false });
  }

  goToAddTracker() {
    this.setState({
      isIMEIRegisterOpen: false,
      isManualRegisterOpen: false,
      isAddTrackerOpen: true,
      fuel: "",
      title: "",
      starterCode: "",
      serial: "",
      simNumber: "",
      driverName: "",
      selectedUsage: 0,
    });
  }

  saveNewTracer() {
    this.setState({ loading: true });
    let data = {
      starter: this.state.starterCode
        .replace(/۰/g, "0")
        .replace(/۱/g, "1")
        .replace(/۲/g, "2")
        .replace(/۳/g, "3")
        .replace(/۴/g, "4")
        .replace(/۵/g, "5")
        .replace(/۶/g, "6")
        .replace(/۷/g, "7")
        .replace(/۸/g, "8")
        .replace(/۹/g, "9"),
      serial: this.state.serial
        .replace(/۰/g, "0")
        .replace(/۱/g, "1")
        .replace(/۲/g, "2")
        .replace(/۳/g, "3")
        .replace(/۴/g, "4")
        .replace(/۵/g, "5")
        .replace(/۶/g, "6")
        .replace(/۷/g, "7")
        .replace(/۸/g, "8")
        .replace(/۹/g, "9"),
      title: this.state.title,
      driver_name: this.state.driverName,
      sim_number: this.state.simNumber
        .replace(/۰/g, "0")
        .replace(/۱/g, "1")
        .replace(/۲/g, "2")
        .replace(/۳/g, "3")
        .replace(/۴/g, "4")
        .replace(/۵/g, "5")
        .replace(/۶/g, "6")
        .replace(/۷/g, "7")
        .replace(/۸/g, "8")
        .replace(/۹/g, "9"),
      usage_id: this.state.selectedUsage,
      fuel_usage: this.state.fuel
        .replace(/۰/g, "0")
        .replace(/۱/g, "1")
        .replace(/۲/g, "2")
        .replace(/۳/g, "3")
        .replace(/۴/g, "4")
        .replace(/۵/g, "5")
        .replace(/۶/g, "6")
        .replace(/۷/g, "7")
        .replace(/۸/g, "8")
        .replace(/۹/g, "9"),
    };
    let req = {
      method: "post",
      url: config.baseUrl + "api/v1/clients/tracers",
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            selectedUsage: 0,
            isManualRegisterOpen: false,
            loading: false,
            fuel: "",
            title: "",
            starterCode: "",
            serial: "",
            simNumber: "",
            driverName: "",
          });
          this.props.alert.success("اطلاعات ردیاب با موفقیت ثبت شد.");
          this.getTracerData();
        }
      })
      .catch((err) => {
        if (err.response.data.status === 0) {
          this.setState({ loading: false });
          this.props.alert.error("تمامی فیلد ها را به دقت پر کنید.");
        }
      });
  }

  getStarterCode() {
    this.setState({ loading: true });

    let imei = document
      .getElementById("addImei")
      .value.replace(/۰/g, "0")
      .replace(/۱/g, "1")
      .replace(/۲/g, "2")
      .replace(/۳/g, "3")
      .replace(/۴/g, "4")
      .replace(/۵/g, "5")
      .replace(/۶/g, "6")
      .replace(/۷/g, "7")
      .replace(/۸/g, "8")
      .replace(/۹/g, "9");

    if (imei !== "") {
      let req = {
        method: "get",
        url: config.baseUrl + "api/v1/clients/tracers/imei/" + imei,
        headers: {
          Authorization: "Bearer " + config.token,
        },
      };

      axios(req)
        .then((resp) => {
          if (resp.data.status === 1) {
            this.setState({
              starterCode: resp.data.data.starter,
              serial: resp.data.data.serial,
              isManualRegisterOpen: true,
              loading: false,
              isIMEIRegisterOpen: false,
            });

            document.getElementById("serial").readOnly = true;
            document.getElementById("starterCode").readOnly = true;
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.alert.error(err.response.data.errors[0].message);
        });
    } else {
      this.setState({ loading: false });
      this.props.alert.error("این فیلد اجباری است.");
    }
  }

  editTracer(id) {
    this.setState({
      loading: true,
      isUpdateTracerOpen: true,
      editingTracerId: id,
    });

    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/clients/tracers/" + id,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            fuel: resp.data.data.fuel_usage,
            title: resp.data.data.title,
            starterCode: resp.data.data.starter,
            serial: resp.data.data.serial,
            simNumber: resp.data.data.sim_number,
            driverName: resp.data.data.driver_name,
            selectedUsage: resp.data.data.usage.id,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateEditingTracer() {
    console.log(this.state.fuel);
    this.setState({ loading: true });
    let data = {
      title: this.state.title,
      driver_name: this.state.driverName,
      sim_number: this.state.simNumber
        .replace(/۰/g, "0")
        .replace(/۱/g, "1")
        .replace(/۲/g, "2")
        .replace(/۳/g, "3")
        .replace(/۴/g, "4")
        .replace(/۵/g, "5")
        .replace(/۶/g, "6")
        .replace(/۷/g, "7")
        .replace(/۸/g, "8")
        .replace(/۹/g, "9"),
      usage_id: this.state.selectedUsage,
      fuel_usage: this.state.fuel,
    };
    let req = {
      method: "put",
      url:
        config.baseUrl + "api/v1/clients/tracers/" + this.state.editingTracerId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
      data: data,
    };

    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            selectedUsage: 0,
            isUpdateTracerOpen: false,
            loading: false,
            fuel: "",
            title: "",
            starterCode: "",
            serial: "",
            simNumber: "",
            driverName: "",
            editingTracerId: 0,
          });
          this.props.alert.success("اطلاعات ردیاب با موفقیت تغییر کرد.");
          this.getTracerData();
        }
      })
      .catch((err) => {
        if (err.response.data.status === 0) {
          this.setState({ loading: false });
          this.props.alert.error("تمامی فیلد ها را به دقت پر کنید.");
        }
      });
  }

  deleteTracer() {
    this.setState({ loading: true });
    let req = {
      method: "delete",
      url:
        config.baseUrl + "api/v1/clients/tracers/" + this.state.editingTracerId,
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            isDeleteTracerOpen: false,
            editingTracerId: 0,
            loading: false,
          });
          this.props.alert.success("ردیاب مورد نظر با موفقیت حذف شد.");
          this.getTracerData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getTracerData();
    let req = {
      method: "get",
      url: config.baseUrl + "api/v1/clients/tracers/usages",
      headers: {
        Authorization: "Bearer " + config.token,
      },
    };
    axios(req)
      .then((resp) => {
        if (resp.data.status === 1) {
          this.setState({
            usageTypes: resp.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="content col-lg-12 col-md-12">
        <Loading loading={this.state.loading} />
        <div className="content-head">
          <ReactToPrint
            trigger={() => (
              <button
                title="پرینت"
                className="blueBtn print-btn"
                style={{ float: "left" }}
              >
                <i className="icon-print" />
              </button>
            )}
            content={() => this.printTbl}
          />

          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="blueBtn excel-btn"
            table="exelTable"
            filename="tracerList"
            sheet="tablexls"
            buttonText={<i className="icon-excel" />}
          />
          <button
            className="blueBtn"
            onClick={() => {
              this.setState({ isAddTrackerOpen: true });
            }}
          >
            افزودن ردیاب
          </button>
        </div>
        <div id={"tbl"} className="content-body">
          <table className="tbl tracers-tbl">
            <thead>
              <tr>
                <th>
                  <img src={require("../../image/tick.png")} />
                </th>
                <th>وضعیت</th>
                <th>عنوان و شماره سریال</th>
                <th>IMEI</th>
                <th>کد راه انداز</th>
                <th>سیم کارت</th>
                <th>راننده</th>
                <th>نمایندگی</th>
                <th>تاریخ انقضا</th>
                <th>آخرین ارسال</th>
                <th>گزینه ها</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((key) => (
                <tr>
                  <td>
                    <button
                      onClick={() => {
                        this.handleSelect(key.id);
                      }}
                    >
                      <span className="checkbox">
                        <img
                          src={require(`../../image/${
                            this.state.selectedDataId.includes(key.id)
                              ? "checked"
                              : "uncheck"
                          }.svg`)}
                        />
                      </span>
                    </button>
                  </td>
                  <td>
                    <img
                      src={require(`../../image/Ellipse-${key.status}@2x.png`)}
                    />
                  </td>
                  <td>
                    <p>{key.title}</p>
                    <p style={{ color: "#B5B5B5" }}>{key.serial}</p>
                  </td>
                  <td>
                    <p style={{ color: key.data_color }}>{key.imei}</p>
                    {!key.last_lat || !key.last_lng ? (
                      <p style={{ color: "red" }}>
                        این ردیاب تا کنون داده ارسال نکرده است!
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <p>{key.starter}</p>
                  </td>
                  <td>
                    <p>{key.sim_number}</p>
                  </td>
                  <td>
                    <p>{key.driver_name}</p>
                  </td>
                  <td>
                    <p>{key.manufacturer}</p>
                  </td>
                  <td>
                    <p>
                      {key.expires_at != null
                        ? moment(key.expires_at).format("jYYYY/jMM/jDD")
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p>
                      {key.last_sent != null
                        ? moment(key.last_sent).format("jYYYY/jMM/jDD")
                        : ""}
                    </p>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        this.editTracer(key.id);
                      }}
                      className="edit-btn"
                      title="ویرایش"
                    >
                      <i className="icon-edit" />
                    </button>
                    <button
                      className="conf-btn"
                      title="تنظیمات ردیاب"
                      onClick={() => {
                        this.setState({
                          tracerSettingId: key.id,
                          tracerSettingTitle: key.title,
                          tracerSettingType: key.type.code,
                          isTracerSettingOpen: true,
                        });
                      }}
                    >
                      <i className="icon-setting" />
                    </button>
                    <button
                      onClick={() => {
                        this.setState({
                          isDeleteTracerOpen: true,
                          editingTracerId: key.id,
                        });
                      }}
                      className="delete-btn"
                      title="حذف"
                    >
                      <i className="icon-delete" title="حذف" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {this.state.data.map((key) => (
            <div className="mobile-info-card row">
              <div className="col-xs-5">
                <label>عنوان: </label>
                <span>{key.title}</span>
              </div>
              <div className="col-xs-7">
                <label>IMEI: </label>
                <span style={{ color: key.data_color }}>{key.imei}</span>
              </div>
              <div className="col-xs-5">
                <label>راننده: </label>
                <span>{key.driver_name}</span>
              </div>
              <div className="col-xs-7">
                <label>نمایندگی: </label>
                <span>{key.manufacturer}</span>
              </div>
              <div className="col-xs-7">
                <label>سیم کارت: </label>
                <span>{key.sim_number}</span>
              </div>
              <div className="col-xs-5">
                <label>سریال: </label>
                <span>{key.serial}</span>
              </div>
              <div className="col-xs-7">
                <label>تاریخ انقضا: </label>
                <span style={{ color: `${key.is_expired ? "red" : "green"}` }}>
                  {key.expires_at != null
                    ? moment(key.expires_at).format("jYYYY/jMM/jDD")
                    : ""}
                </span>
              </div>
              <div className="col-xs-12 text-center">
                {!key.last_lat || !key.last_lng ? (
                  <p style={{ color: "red" }}>
                    این ردیاب تا کنون داده ارسال نکرده است!
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-xs-12 card-btn-div">
                <div className="col-xs-4">
                  <button
                    onClick={() => {
                      this.editTracer(key.id);
                    }}
                    className="edit-btn"
                    title="ویرایش"
                  >
                    ویرایش
                  </button>
                </div>
                <div className="col-xs-4">
                  <button
                    className="conf-btn"
                    title="تنظیمات ردیاب"
                    onClick={() => {
                      this.setState({
                        tracerSettingId: key.id,
                        tracerSettingTitle: key.title,
                        tracerSettingType: key.type.code,
                        isTracerSettingOpen: true,
                      });
                    }}
                  >
                    تنظیمات
                  </button>
                </div>
                <div className="col-xs-4">
                  <button
                    onClick={() => {
                      this.setState({
                        isDeleteTracerOpen: true,
                        editingTracerId: key.id,
                      });
                    }}
                    className="delete-btn"
                    title="حذف"
                  >
                    حذف
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal
          isOpen={this.state.isAddTrackerOpen}
          onRequestClose={this.closeAddTracker}
          style={{
            content: { top: "calc(100vh / 5)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>افزودن ردیاب</p>
          </div>
          <p style={{ fontSize: "12px", textAlign: "center" }}>
            یکی از روش های افزودن ردیاب را انتخاب کنید
          </p>
          <div className="btn-div">
            <button
              className="btn lightBlue"
              style={{ padding: "40px" }}
              onClick={this.openAddIMEI}
            >
              <i className="icon-imei" />
            </button>
            <p>از طریق IMEI</p>
          </div>
          <div className="btn-div">
            <button
              className="btn lightBlue"
              style={{ padding: "40px 37px" }}
              onClick={this.openAddManual}
            >
              <i className="icon-register" />
            </button>
            <p>به صورت دستی</p>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.isIMEIRegisterOpen}
          onRequestClose={this.closeAddTracker}
          style={{
            content: { top: "calc(100vh / 5)", left: "40%", right: "40%" },
          }}
        >
          <div className="title">
            <p>وارد کردن IMEI</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            در قسمت پایین کد IMEI ردیاب خود را وارد کنید.
          </p>
          <div className="input-div ">
            <h5>کد IMEI</h5>
            <div className="input-group">
              <input id="addImei" className="input num" placeholder="12..." />
            </div>
          </div>
          <button
            onClick={this.getStarterCode}
            className="blueBtn btn"
            style={{ padding: "15px 50px" }}
          >
            مرحله بعد
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 25px" }}
            onClick={this.goToAddTracker}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isManualRegisterOpen}
          onRequestClose={() => {
            this.setState({
              fuel: "",
              title: "",
              starterCode: "",
              serial: "",
              simNumber: "",
              driverName: "",
              selectedUsage: 0,
              isManualRegisterOpen: false,
            });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "30%", right: "30%" },
          }}
        >
          <div className="title">
            <p>افزودن اطلاعات ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            اطلاعات ردیاب را کامل کنید.
          </p>
          <div className="half">
            <div className="input-div ">
              <h5>عنوان</h5>
              <div className="input-group">
                <input
                  style={{ textAlign: "right", direction: "rtl" }}
                  name="title"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.title}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>سریال</h5>
              <div className="input-group">
                <input
                  id="serial"
                  name="serial"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.serial}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>شماره سیم کارت</h5>
              <div className="input-group">
                <input
                  name="simNumber"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.simNumber}
                />
              </div>
            </div>
          </div>
          <div className="half">
            <div className="input-div ">
              <h5>کد راه انداز</h5>
              <div className="input-group">
                <input
                  id="starterCode"
                  name="starterCode"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.starterCode}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>نام راننده</h5>
              <div className="input-group">
                <input
                  style={{ textAlign: "right", direction: "rtl" }}
                  name="driverName"
                  className="input"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.driverName}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>میزان مصرف سوخت (لیتر)</h5>
              <div className="input-group">
                <input
                  name="fuel"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.fuel}
                />
              </div>
            </div>
          </div>
          <div className="input-div " style={{ padding: "0 10px" }}>
            <h5>استفاده در</h5>
            <div className="input-group">
              <select
                onChange={(e) => {
                  this.setState({ selectedUsage: e.target.value });
                }}
                defaultValue={this.state.selectedUsage}
              >
                <option disabled value={0}>
                  نوع استفاده
                </option>
                {this.state.usageTypes.map((key) => (
                  <option value={key.id}>{key.name}</option>
                ))}
              </select>
            </div>
          </div>
          <button
            onClick={this.saveNewTracer}
            className="blueBtn btn"
            style={{ padding: "15px 145px" }}
          >
            ذخیره
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 70px" }}
            onClick={this.goToAddTracker}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isUpdateTracerOpen}
          onRequestClose={() => {
            this.setState({
              fuel: "",
              title: "",
              starterCode: "",
              serial: "",
              simNumber: "",
              driverName: "",
              selectedUsage: 0,
              isUpdateTracerOpen: false,
            });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "30%", right: "30%" },
          }}
        >
          <div className="title">
            <p>ویرایش اطلاعات ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "12px",
              textAlign: "center",
              marginBottom: "40px",
              color: "#B5B5B5",
            }}
          >
            اطلاعات ردیاب را ویرایش کنید.
          </p>
          <div className="half">
            <div className="input-div ">
              <h5>عنوان</h5>
              <div className="input-group">
                <input
                  style={{ textAlign: "right", direction: "rtl" }}
                  name="title"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.title}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>سریال</h5>
              <div className="input-group">
                <input
                  readOnly
                  name="serial"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.serial}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>شماره سیم کارت</h5>
              <div className="input-group">
                <input
                  name="simNumber"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.simNumber}
                />
              </div>
            </div>
          </div>
          <div className="half">
            <div className="input-div ">
              <h5>کد راه انداز</h5>
              <div className="input-group">
                <input
                  readOnly
                  name="starterCode"
                  className="input num"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.starterCode}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>نام راننده</h5>
              <div className="input-group">
                <input
                  style={{ textAlign: "right", direction: "rtl" }}
                  name="driverName"
                  className="input"
                  onChange={(e) => {
                    this.setState({ [e.target.name]: e.target.value });
                  }}
                  defaultValue={this.state.driverName}
                />
              </div>
            </div>
            <div className="input-div ">
              <h5>میزان مصرف سوخت (لیتر)</h5>
              <div className="input-group">
                <input
                  name="fuel"
                  className="input num"
                  onChange={(e) => {
                    this.setState({
                      [e.target.name]: e.target.value
                        .replace(/۰/g, "0")
                        .replace(/۱/g, "1")
                        .replace(/۲/g, "2")
                        .replace(/۳/g, "3")
                        .replace(/۴/g, "4")
                        .replace(/۵/g, "5")
                        .replace(/۶/g, "6")
                        .replace(/۷/g, "7")
                        .replace(/۸/g, "8")
                        .replace(/۹/g, "9"),
                    });
                  }}
                  defaultValue={this.state.fuel}
                />
              </div>
            </div>
          </div>
          <div className="input-div " style={{ padding: "0 10px" }}>
            <h5>استفاده در</h5>
            <div className="input-group">
              <select
                onChange={(e) => {
                  this.setState({ selectedUsage: e.target.value });
                }}
                defaultValue={this.state.selectedUsage}
              >
                <option disabled value={0}>
                  نوع استفاده
                </option>
                {this.state.usageTypes.map((key) => (
                  <option
                    selected={
                      this.state.selectedUsage === key.id ? true : false
                    }
                    value={key.id}
                  >
                    {key.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button
            onClick={this.updateEditingTracer}
            className="blueBtn btn"
            style={{ padding: "15px 130px" }}
          >
            ذخیره تغییرات
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 70px" }}
            onClick={() => {
              this.setState({
                fuel: "",
                title: "",
                starterCode: "",
                serial: "",
                simNumber: "",
                driverName: "",
                selectedUsage: 0,
                isUpdateTracerOpen: false,
              });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isDeleteTracerOpen}
          onRequestClose={() => {
            this.setState({ isDeleteTracerOpen: false, editingTracerId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <div className="title">
            <p>حذف ردیاب</p>
          </div>
          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            آیا از حذف این ردیاب اطمینان دارید؟
          </p>
          <button
            onClick={this.deleteTracer}
            className="blueBtn btn"
            style={{ padding: "15px 85px" }}
          >
            بله
          </button>
          <button
            className="pinkBtn btn"
            style={{ marginRight: "15px", padding: "15px 75px" }}
            onClick={() => {
              this.setState({ isDeleteTracerOpen: false, editingTracerId: 0 });
            }}
          >
            انصراف
          </button>
        </Modal>
        <Modal
          isOpen={this.state.isTracerSettingOpen}
          onRequestClose={() => {
            this.setState({ isTracerSettingOpen: false, tracerSettingId: 0 });
          }}
          style={{
            content: { top: "calc(100vh / 8)", left: "35%", right: "35%" },
          }}
        >
          <TracerSetting
            id={this.state.tracerSettingId}
            type={this.state.tracerSettingType}
            title={this.state.tracerSettingTitle}
          />
        </Modal>
        <div className="hide">
          <table
            ref={(el) => (this.printTbl = el)}
            id={"exelTable"}
            className="brintTable"
          >
            <thead>
              <tr>
                <th>عنوان و شماره سریال</th>
                <th>IMEI</th>
                <th>کد راه انداز</th>
                <th>سیم کارت</th>
                <th>راننده</th>
                <th>تاریخ انقضا</th>
                <th>آخرین ارسال</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((key) => (
                <tr
                  style={{
                    display: `${
                      this.state.selectedDataId.includes(key.id)
                        ? "table-row"
                        : "none"
                    }`,
                  }}
                >
                  <td>
                    <p>{key.title}</p>
                    <p style={{ color: "#B5B5B5" }}>{key.serial}</p>
                  </td>
                  <td>
                    <p style={{ color: key.data_color }}>{key.imei}</p>
                  </td>
                  <td>
                    <p>{key.starter}</p>
                  </td>
                  <td>
                    <p>{key.sim_number}</p>
                  </td>
                  <td>
                    <p>{key.driver_name}</p>
                  </td>
                  <td>
                    <p>
                      {key.expires_at != null
                        ? moment(key.expires_at).format("jYYYY/jMM/jDD")
                        : ""}
                    </p>
                  </td>
                  <td>
                    <p>
                      {key.last_sent != null
                        ? moment(key.last_sent).format("jYYYY/jMM/jDD")
                        : ""}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withAlert(TrackersList);
