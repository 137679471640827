import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import {withAlert} from 'react-alert'
import axios from 'axios';
import * as Cookies from 'js-cookie';
import Loading from "./Loading";
import {config} from '../config';

class Register extends Component {

    constructor(props){
        super(props);
        this.state={
            checkbox: true,
            isRegistered: false,
            loading: false
        };
        this.alert = false;
        this.register = this.register.bind(this);
    }

    register(){

        this.setState({loading: true});

        let name = document.getElementById('name').value;
        let phone = document.getElementById('phone').value.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9');
        let code = document.getElementById('code').value;
        let pass = document.getElementById('pass').value;

        if(phone.length < 1 || name.length < 1 ||  code.length < 1 ||  pass.length < 1 ) {
            this.alert = true;
        }else {
            this.alert = false;
        }

        let dict = {
          'mcode': 'کد ملی',
          'mobile': 'شماره موبایل',
          'name': 'نام و نام خانوادگی',
          'password': 'رمز عبور',

        };


        let data = {
            'mobile' : phone.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9') ,
            'password': document.getElementById('pass').value ,
            'mcode': document.getElementById('code').value.replace(/۰/g,'0').replace(/۱/g,'1').replace(/۲/g,'2').replace(/۳/g,'3').replace(/۴/g,'4').replace(/۵/g,'5').replace(/۶/g,'6').replace(/۷/g,'7').replace(/۸/g,'8').replace(/۹/g,'9') ,
            'name' : document.getElementById('name').value
        };

        let req = {
            method: 'post',
            url: config.baseUrl + 'api/v1/auth/register',
            data: data,

        };
        axios(req
        ).then((resp) => {
            if(resp.data.status === 1) {
                window.localStorage.setItem('token', resp.data.token);
                this.setState({isRegistered: true , loading: false});
                this.props.alert.success('ثبت نام با موفقیت انجام شد.')
            }

        }).catch((err) => {
            this.props.alert.error(dict[err.response.data.errors[0].field] + ' ' + err.response.data.errors[0].message.substr(-11));
            this.setState({loading: false});
        });
    }

    render() {
        return (
            <div className='App form-group'>
                <Loading loading={this.state.loading}/>
                <div className='img-div col-lg-9 col-md-8 col-sm-6 hidden-xs'>
                        <span>
                        </span>
                </div>

                <div className='left-div col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                    <h5>ثبت نام</h5>
                    <p className='blue'>جهت ثبت نام فیلد های زیر را پر کنید</p>
                    <span className='line'></span>
                    <form>
                    <div className='input-div '>
                        <h5>نام و نام خانوادگی</h5>
                        <div className='input-group'>
                            <i className='icon-avatar inputIcon'></i>
                            <input id='name' className='input' placeholder='نام و نام خانوادگی'
                                   style={{textAlign: 'right', direction: 'rtl'}}/>
                        </div>
                    </div>
                    <div className='input-div '>
                        <h5>کد ملی</h5>
                        <div className='input-group'>
                            <i className='icon-code inputIcon'></i>
                            <input id='code' className='input num' placeholder='12...'/>
                        </div>
                    </div>
                    <div className='input-div '>
                        <h5>شماره موبایل</h5>
                        <div className='input-group'>
                            <i className='icon-phone inputIcon'></i>
                            <input id='phone' className='input num' placeholder='09...'/>
                        </div>
                    </div>
                    <div className='input-div' style={{marginBottom: '30px'}}>
                        <h5>رمز عبور</h5>
                        <div className='input-group'>
                            <i className='icon-password inputIcon'></i>
                            <input id='pass' className='input' type='password' placeholder='****'/>
                        </div>
                    </div>
                    </form>
                    <div className='button-div blueBtn'>
                        {this.state.isRegistered ? <Redirect to='/login' /> :''}
                        <button onClick={this.register}>
                            <h5>ثبت نام</h5>
                        </button>
                    </div>
                    <div className='button-div'>
                        <Link to={'/login'}>
                            <h5>ورود</h5>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAlert(Register);